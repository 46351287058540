.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.light-theme-mode .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.light-theme-mode .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-option:hover:not(.mat-option-disabled), .light-theme-mode .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0054f2;
}
.light-theme-mode .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0054f2;
}
.light-theme-mode .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.light-theme-mode .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.light-theme-mode .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.light-theme-mode .mat-primary .mat-pseudo-checkbox-checked,
.light-theme-mode .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0054f2;
}
.light-theme-mode .mat-pseudo-checkbox-checked,
.light-theme-mode .mat-pseudo-checkbox-indeterminate,
.light-theme-mode .mat-accent .mat-pseudo-checkbox-checked,
.light-theme-mode .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0054f2;
}
.light-theme-mode .mat-warn .mat-pseudo-checkbox-checked,
.light-theme-mode .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.light-theme-mode .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.light-theme-mode .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.light-theme-mode .mat-app-background, .light-theme-mode.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.light-theme-mode .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.light-theme-mode .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-badge {
  position: relative;
}
.light-theme-mode .mat-badge.mat-badge {
  overflow: visible;
}
.light-theme-mode .mat-badge-hidden .mat-badge-content {
  display: none;
}
.light-theme-mode .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.light-theme-mode .ng-animate-disabled .mat-badge-content,
.light-theme-mode .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.light-theme-mode .mat-badge-content.mat-badge-active {
  transform: none;
}
.light-theme-mode .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.light-theme-mode .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.light-theme-mode .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.light-theme-mode .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .light-theme-mode .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.light-theme-mode .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .light-theme-mode .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.light-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .light-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.light-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .light-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.light-theme-mode .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.light-theme-mode .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.light-theme-mode .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.light-theme-mode .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .light-theme-mode .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.light-theme-mode .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .light-theme-mode .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.light-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .light-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.light-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .light-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.light-theme-mode .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.light-theme-mode .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.light-theme-mode .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.light-theme-mode .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .light-theme-mode .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.light-theme-mode .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .light-theme-mode .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.light-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .light-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.light-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .light-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.light-theme-mode .mat-badge-content {
  color: white;
  background: #0054f2;
}
.cdk-high-contrast-active .light-theme-mode .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.light-theme-mode .mat-badge-accent .mat-badge-content {
  background: #0054f2;
  color: white;
}
.light-theme-mode .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.light-theme-mode .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-button, .light-theme-mode .mat-icon-button, .light-theme-mode .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.light-theme-mode .mat-button.mat-primary, .light-theme-mode .mat-icon-button.mat-primary, .light-theme-mode .mat-stroked-button.mat-primary {
  color: #0054f2;
}
.light-theme-mode .mat-button.mat-accent, .light-theme-mode .mat-icon-button.mat-accent, .light-theme-mode .mat-stroked-button.mat-accent {
  color: #0054f2;
}
.light-theme-mode .mat-button.mat-warn, .light-theme-mode .mat-icon-button.mat-warn, .light-theme-mode .mat-stroked-button.mat-warn {
  color: #f44336;
}
.light-theme-mode .mat-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-icon-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-icon-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-icon-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-icon-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-stroked-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-stroked-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-stroked-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-button.mat-primary .mat-button-focus-overlay, .light-theme-mode .mat-icon-button.mat-primary .mat-button-focus-overlay, .light-theme-mode .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0054f2;
}
.light-theme-mode .mat-button.mat-accent .mat-button-focus-overlay, .light-theme-mode .mat-icon-button.mat-accent .mat-button-focus-overlay, .light-theme-mode .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0054f2;
}
.light-theme-mode .mat-button.mat-warn .mat-button-focus-overlay, .light-theme-mode .mat-icon-button.mat-warn .mat-button-focus-overlay, .light-theme-mode .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.light-theme-mode .mat-button.mat-button-disabled .mat-button-focus-overlay, .light-theme-mode .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .light-theme-mode .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.light-theme-mode .mat-button .mat-ripple-element, .light-theme-mode .mat-icon-button .mat-ripple-element, .light-theme-mode .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.light-theme-mode .mat-button-focus-overlay {
  background: black;
}
.light-theme-mode .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-flat-button, .light-theme-mode .mat-raised-button, .light-theme-mode .mat-fab, .light-theme-mode .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.light-theme-mode .mat-flat-button.mat-primary, .light-theme-mode .mat-raised-button.mat-primary, .light-theme-mode .mat-fab.mat-primary, .light-theme-mode .mat-mini-fab.mat-primary {
  color: white;
}
.light-theme-mode .mat-flat-button.mat-accent, .light-theme-mode .mat-raised-button.mat-accent, .light-theme-mode .mat-fab.mat-accent, .light-theme-mode .mat-mini-fab.mat-accent {
  color: white;
}
.light-theme-mode .mat-flat-button.mat-warn, .light-theme-mode .mat-raised-button.mat-warn, .light-theme-mode .mat-fab.mat-warn, .light-theme-mode .mat-mini-fab.mat-warn {
  color: white;
}
.light-theme-mode .mat-flat-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-fab.mat-primary.mat-button-disabled, .light-theme-mode .mat-fab.mat-accent.mat-button-disabled, .light-theme-mode .mat-fab.mat-warn.mat-button-disabled, .light-theme-mode .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-flat-button.mat-primary, .light-theme-mode .mat-raised-button.mat-primary, .light-theme-mode .mat-fab.mat-primary, .light-theme-mode .mat-mini-fab.mat-primary {
  background-color: #0054f2;
}
.light-theme-mode .mat-flat-button.mat-accent, .light-theme-mode .mat-raised-button.mat-accent, .light-theme-mode .mat-fab.mat-accent, .light-theme-mode .mat-mini-fab.mat-accent {
  background-color: #0054f2;
}
.light-theme-mode .mat-flat-button.mat-warn, .light-theme-mode .mat-raised-button.mat-warn, .light-theme-mode .mat-fab.mat-warn, .light-theme-mode .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.light-theme-mode .mat-flat-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-flat-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-primary.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-accent.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-warn.mat-button-disabled, .light-theme-mode .mat-raised-button.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-fab.mat-primary.mat-button-disabled, .light-theme-mode .mat-fab.mat-accent.mat-button-disabled, .light-theme-mode .mat-fab.mat-warn.mat-button-disabled, .light-theme-mode .mat-fab.mat-button-disabled.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-primary.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-accent.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-warn.mat-button-disabled, .light-theme-mode .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-flat-button.mat-primary .mat-ripple-element, .light-theme-mode .mat-raised-button.mat-primary .mat-ripple-element, .light-theme-mode .mat-fab.mat-primary .mat-ripple-element, .light-theme-mode .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-flat-button.mat-accent .mat-ripple-element, .light-theme-mode .mat-raised-button.mat-accent .mat-ripple-element, .light-theme-mode .mat-fab.mat-accent .mat-ripple-element, .light-theme-mode .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-flat-button.mat-warn .mat-ripple-element, .light-theme-mode .mat-raised-button.mat-warn .mat-ripple-element, .light-theme-mode .mat-fab.mat-warn .mat-ripple-element, .light-theme-mode .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-stroked-button:not([class*=mat-elevation-z]), .light-theme-mode .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-fab:not([class*=mat-elevation-z]), .light-theme-mode .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .light-theme-mode .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .light-theme-mode .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.light-theme-mode .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.light-theme-mode .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.light-theme-mode .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.light-theme-mode .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.light-theme-mode .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}
.light-theme-mode [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}
.light-theme-mode .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}
.light-theme-mode .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.light-theme-mode .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.light-theme-mode .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.light-theme-mode .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.light-theme-mode .mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.light-theme-mode .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-checkbox-checkmark {
  fill: #fafafa;
}
.light-theme-mode .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.light-theme-mode .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.light-theme-mode .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .light-theme-mode .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0054f2;
}
.light-theme-mode .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .light-theme-mode .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0054f2;
}
.light-theme-mode .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .light-theme-mode .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.light-theme-mode .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .light-theme-mode .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.light-theme-mode .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.light-theme-mode .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.light-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.light-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0054f2;
}
.light-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.light-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0054f2;
}
.light-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.light-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.light-theme-mode .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.light-theme-mode .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.light-theme-mode .mat-chip.mat-standard-chip::after {
  background: black;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.light-theme-mode .mat-table {
  background: white;
}
.light-theme-mode .mat-table thead, .light-theme-mode .mat-table tbody, .light-theme-mode .mat-table tfoot,
.light-theme-mode mat-header-row, .light-theme-mode mat-row, .light-theme-mode mat-footer-row,
.light-theme-mode [mat-header-row], .light-theme-mode [mat-row], .light-theme-mode [mat-footer-row],
.light-theme-mode .mat-table-sticky {
  background: inherit;
}
.light-theme-mode mat-row, .light-theme-mode mat-header-row, .light-theme-mode mat-footer-row,
.light-theme-mode th.mat-header-cell, .light-theme-mode td.mat-cell, .light-theme-mode td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-cell, .light-theme-mode .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-datepicker-toggle,
.light-theme-mode .mat-datepicker-content .mat-calendar-next-button,
.light-theme-mode .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-calendar-table-header,
.light-theme-mode .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-calendar-body-cell-content,
.light-theme-mode .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.light-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.light-theme-mode .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.light-theme-mode .mat-calendar-body-in-range::before {
  background: rgba(0, 84, 242, 0.2);
}
.light-theme-mode .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme-mode .mat-calendar-body-comparison-bridge-start::before,
.light-theme-mode [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-calendar-body-comparison-bridge-end::before,
.light-theme-mode [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme-mode .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme-mode .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme-mode .mat-calendar-body-selected {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 84, 242, 0.4);
}
.light-theme-mode .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.light-theme-mode .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme-mode .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 84, 242, 0.3);
}
@media (hover: hover) {
  .light-theme-mode .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 84, 242, 0.3);
  }
}
.light-theme-mode .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 84, 242, 0.2);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.light-theme-mode .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.light-theme-mode .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 84, 242, 0.4);
}
.light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.light-theme-mode .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme-mode .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 84, 242, 0.3);
}
@media (hover: hover) {
  .light-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 84, 242, 0.3);
  }
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.light-theme-mode .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.light-theme-mode .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.light-theme-mode .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.light-theme-mode .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .light-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.light-theme-mode .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-datepicker-toggle-active {
  color: #0054f2;
}
.light-theme-mode .mat-datepicker-toggle-active.mat-accent {
  color: #0054f2;
}
.light-theme-mode .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.light-theme-mode .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .light-theme-mode .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .light-theme-mode .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .light-theme-mode .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.light-theme-mode .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-expansion-panel-header-description,
.light-theme-mode .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.light-theme-mode .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.light-theme-mode .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme-mode .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-label {
  color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.light-theme-mode .mat-focused .mat-form-field-required-marker {
  color: #0054f2;
}
.light-theme-mode .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.light-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0054f2;
}
.light-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0054f2;
}
.light-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.light-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.light-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.light-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.light-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.light-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.light-theme-mode .mat-error {
  color: #f44336;
}
.light-theme-mode .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme-mode .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.light-theme-mode .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.light-theme-mode .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.light-theme-mode .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0054f2;
}
.light-theme-mode .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0054f2;
}
.light-theme-mode .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.light-theme-mode .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.light-theme-mode .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.light-theme-mode .mat-icon.mat-primary {
  color: #0054f2;
}
.light-theme-mode .mat-icon.mat-accent {
  color: #0054f2;
}
.light-theme-mode .mat-icon.mat-warn {
  color: #f44336;
}
.light-theme-mode .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-input-element:disabled,
.light-theme-mode .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-input-element {
  caret-color: #0054f2;
}
.light-theme-mode .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-form-field.mat-accent .mat-input-element {
  caret-color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-warn .mat-input-element,
.light-theme-mode .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.light-theme-mode .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.light-theme-mode .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-list-option:hover, .light-theme-mode .mat-list-option:focus,
.light-theme-mode .mat-nav-list .mat-list-item:hover,
.light-theme-mode .mat-nav-list .mat-list-item:focus,
.light-theme-mode .mat-action-list .mat-list-item:hover,
.light-theme-mode .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-list-single-selected-option, .light-theme-mode .mat-list-single-selected-option:hover, .light-theme-mode .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-menu-panel {
  background: white;
}
.light-theme-mode .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-menu-item[disabled],
.light-theme-mode .mat-menu-item[disabled] .mat-menu-submenu-icon,
.light-theme-mode .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-menu-item .mat-icon-no-color,
.light-theme-mode .mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-menu-item:hover:not([disabled]),
.light-theme-mode .mat-menu-item.cdk-program-focused:not([disabled]),
.light-theme-mode .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.light-theme-mode .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-paginator {
  background: white;
}
.light-theme-mode .mat-paginator,
.light-theme-mode .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-paginator-decrement,
.light-theme-mode .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-paginator-first,
.light-theme-mode .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-icon-button[disabled] .mat-paginator-decrement,
.light-theme-mode .mat-icon-button[disabled] .mat-paginator-increment,
.light-theme-mode .mat-icon-button[disabled] .mat-paginator-first,
.light-theme-mode .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.light-theme-mode .mat-progress-bar-background {
  fill: #bcd1f8;
}
.light-theme-mode .mat-progress-bar-buffer {
  background-color: #bcd1f8;
}
.light-theme-mode .mat-progress-bar-fill::after {
  background-color: #0054f2;
}
.light-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #bcd1f8;
}
.light-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #bcd1f8;
}
.light-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0054f2;
}
.light-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.light-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.light-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.light-theme-mode .mat-progress-spinner circle, .light-theme-mode .mat-spinner circle {
  stroke: #0054f2;
}
.light-theme-mode .mat-progress-spinner.mat-accent circle, .light-theme-mode .mat-spinner.mat-accent circle {
  stroke: #0054f2;
}
.light-theme-mode .mat-progress-spinner.mat-warn circle, .light-theme-mode .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.light-theme-mode .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0054f2;
}
.light-theme-mode .mat-radio-button.mat-primary .mat-radio-inner-circle,
.light-theme-mode .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme-mode .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .light-theme-mode .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0054f2;
}
.light-theme-mode .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0054f2;
}
.light-theme-mode .mat-radio-button.mat-accent .mat-radio-inner-circle,
.light-theme-mode .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme-mode .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .light-theme-mode .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0054f2;
}
.light-theme-mode .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.light-theme-mode .mat-radio-button.mat-warn .mat-radio-inner-circle,
.light-theme-mode .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .light-theme-mode .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .light-theme-mode .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.light-theme-mode .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.light-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.light-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.light-theme-mode .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.light-theme-mode .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-select-panel {
  background: white;
}
.light-theme-mode .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0054f2;
}
.light-theme-mode .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.light-theme-mode .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.light-theme-mode .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-drawer.mat-drawer-push {
  background-color: white;
}
.light-theme-mode .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme-mode [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.light-theme-mode [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.light-theme-mode .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0054f2;
}
.light-theme-mode .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 84, 242, 0.54);
}
.light-theme-mode .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0054f2;
}
.light-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0054f2;
}
.light-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 84, 242, 0.54);
}
.light-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0054f2;
}
.light-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.light-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.light-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.light-theme-mode .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.light-theme-mode .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.light-theme-mode .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-slider.mat-primary .mat-slider-track-fill,
.light-theme-mode .mat-slider.mat-primary .mat-slider-thumb,
.light-theme-mode .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0054f2;
}
.light-theme-mode .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.light-theme-mode .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 84, 242, 0.2);
}
.light-theme-mode .mat-slider.mat-accent .mat-slider-track-fill,
.light-theme-mode .mat-slider.mat-accent .mat-slider-thumb,
.light-theme-mode .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0054f2;
}
.light-theme-mode .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.light-theme-mode .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 84, 242, 0.2);
}
.light-theme-mode .mat-slider.mat-warn .mat-slider-track-fill,
.light-theme-mode .mat-slider.mat-warn .mat-slider-thumb,
.light-theme-mode .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.light-theme-mode .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.light-theme-mode .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.light-theme-mode .mat-slider:hover .mat-slider-track-background,
.light-theme-mode .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-slider.mat-slider-disabled .mat-slider-track-background,
.light-theme-mode .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.light-theme-mode .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.light-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.light-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.light-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .light-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .light-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.light-theme-mode .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.light-theme-mode .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme-mode .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.light-theme-mode .mat-step-header.cdk-keyboard-focused, .light-theme-mode .mat-step-header.cdk-program-focused, .light-theme-mode .mat-step-header:hover:not([aria-disabled]), .light-theme-mode .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.light-theme-mode .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .light-theme-mode .mat-step-header:hover {
    background: none;
  }
}
.light-theme-mode .mat-step-header .mat-step-label,
.light-theme-mode .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.light-theme-mode .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.light-theme-mode .mat-step-header .mat-step-icon-selected,
.light-theme-mode .mat-step-header .mat-step-icon-state-done,
.light-theme-mode .mat-step-header .mat-step-icon-state-edit {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.light-theme-mode .mat-step-header.mat-accent .mat-step-icon-selected,
.light-theme-mode .mat-step-header.mat-accent .mat-step-icon-state-done,
.light-theme-mode .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0054f2;
  color: white;
}
.light-theme-mode .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.light-theme-mode .mat-step-header.mat-warn .mat-step-icon-selected,
.light-theme-mode .mat-step-header.mat-warn .mat-step-icon-state-done,
.light-theme-mode .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.light-theme-mode .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.light-theme-mode .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.light-theme-mode .mat-stepper-horizontal, .light-theme-mode .mat-stepper-vertical {
  background-color: white;
}
.light-theme-mode .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-horizontal-stepper-header::before,
.light-theme-mode .mat-horizontal-stepper-header::after,
.light-theme-mode .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.light-theme-mode .mat-sort-header-arrow {
  color: #757575;
}
.light-theme-mode .mat-tab-nav-bar,
.light-theme-mode .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-tab-group-inverted-header .mat-tab-nav-bar,
.light-theme-mode .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.light-theme-mode .mat-tab-label, .light-theme-mode .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.light-theme-mode .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.light-theme-mode .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.light-theme-mode .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.light-theme-mode .mat-tab-group.mat-primary .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0054f2;
}
.light-theme-mode .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.light-theme-mode .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.light-theme-mode .mat-tab-group.mat-accent .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0054f2;
}
.light-theme-mode .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.light-theme-mode .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.light-theme-mode .mat-tab-group.mat-warn .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.light-theme-mode .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .light-theme-mode .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.light-theme-mode .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header, .light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container, .light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0054f2;
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.light-theme-mode .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header, .light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container, .light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0054f2;
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.light-theme-mode .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .light-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.light-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header, .light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container, .light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.light-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.light-theme-mode .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.light-theme-mode .mat-toolbar.mat-primary {
  background: #0054f2;
  color: white;
}
.light-theme-mode .mat-toolbar.mat-accent {
  background: #0054f2;
  color: white;
}
.light-theme-mode .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.light-theme-mode .mat-toolbar .mat-form-field-underline,
.light-theme-mode .mat-toolbar .mat-form-field-ripple,
.light-theme-mode .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.light-theme-mode .mat-toolbar .mat-form-field-label,
.light-theme-mode .mat-toolbar .mat-focused .mat-form-field-label,
.light-theme-mode .mat-toolbar .mat-select-value,
.light-theme-mode .mat-toolbar .mat-select-arrow,
.light-theme-mode .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.light-theme-mode .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.light-theme-mode .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.light-theme-mode .mat-tree {
  background: white;
}
.light-theme-mode .mat-tree-node,
.light-theme-mode .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.light-theme-mode .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.light-theme-mode .mat-simple-snackbar-action {
  color: #0054f2;
}

.dark-theme-mode .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-option {
  color: white;
}
.dark-theme-mode .mat-option:hover:not(.mat-option-disabled), .dark-theme-mode .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme-mode .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme-mode .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.dark-theme-mode .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0054f2;
}
.dark-theme-mode .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0054f2;
}
.dark-theme-mode .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.dark-theme-mode .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-pseudo-checkbox::after {
  color: #303030;
}
.dark-theme-mode .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.dark-theme-mode .mat-primary .mat-pseudo-checkbox-checked,
.dark-theme-mode .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #0054f2;
}
.dark-theme-mode .mat-pseudo-checkbox-checked,
.dark-theme-mode .mat-pseudo-checkbox-indeterminate,
.dark-theme-mode .mat-accent .mat-pseudo-checkbox-checked,
.dark-theme-mode .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #0054f2;
}
.dark-theme-mode .mat-warn .mat-pseudo-checkbox-checked,
.dark-theme-mode .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.dark-theme-mode .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.dark-theme-mode .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.dark-theme-mode .mat-app-background, .dark-theme-mode.mat-app-background {
  background-color: #303030;
  color: white;
}
.dark-theme-mode .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.dark-theme-mode .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.dark-theme-mode .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.dark-theme-mode .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.dark-theme-mode .mat-badge {
  position: relative;
}
.dark-theme-mode .mat-badge.mat-badge {
  overflow: visible;
}
.dark-theme-mode .mat-badge-hidden .mat-badge-content {
  display: none;
}
.dark-theme-mode .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.dark-theme-mode .ng-animate-disabled .mat-badge-content,
.dark-theme-mode .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.dark-theme-mode .mat-badge-content.mat-badge-active {
  transform: none;
}
.dark-theme-mode .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.dark-theme-mode .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.dark-theme-mode .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.dark-theme-mode .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .dark-theme-mode .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.dark-theme-mode .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .dark-theme-mode .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.dark-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .dark-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.dark-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .dark-theme-mode .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.dark-theme-mode .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .dark-theme-mode .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .dark-theme-mode .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .dark-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.dark-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .dark-theme-mode .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.dark-theme-mode .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.dark-theme-mode .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.dark-theme-mode .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.dark-theme-mode .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .dark-theme-mode .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.dark-theme-mode .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .dark-theme-mode .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.dark-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .dark-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.dark-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .dark-theme-mode .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.dark-theme-mode .mat-badge-content {
  color: white;
  background: #0054f2;
}
.cdk-high-contrast-active .dark-theme-mode .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.dark-theme-mode .mat-badge-accent .mat-badge-content {
  background: #0054f2;
  color: white;
}
.dark-theme-mode .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.dark-theme-mode .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme-mode .mat-button, .dark-theme-mode .mat-icon-button, .dark-theme-mode .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.dark-theme-mode .mat-button.mat-primary, .dark-theme-mode .mat-icon-button.mat-primary, .dark-theme-mode .mat-stroked-button.mat-primary {
  color: #0054f2;
}
.dark-theme-mode .mat-button.mat-accent, .dark-theme-mode .mat-icon-button.mat-accent, .dark-theme-mode .mat-stroked-button.mat-accent {
  color: #0054f2;
}
.dark-theme-mode .mat-button.mat-warn, .dark-theme-mode .mat-icon-button.mat-warn, .dark-theme-mode .mat-stroked-button.mat-warn {
  color: #f44336;
}
.dark-theme-mode .mat-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-icon-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-icon-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-icon-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-icon-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-stroked-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-stroked-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-stroked-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-button.mat-primary .mat-button-focus-overlay, .dark-theme-mode .mat-icon-button.mat-primary .mat-button-focus-overlay, .dark-theme-mode .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #0054f2;
}
.dark-theme-mode .mat-button.mat-accent .mat-button-focus-overlay, .dark-theme-mode .mat-icon-button.mat-accent .mat-button-focus-overlay, .dark-theme-mode .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #0054f2;
}
.dark-theme-mode .mat-button.mat-warn .mat-button-focus-overlay, .dark-theme-mode .mat-icon-button.mat-warn .mat-button-focus-overlay, .dark-theme-mode .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.dark-theme-mode .mat-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme-mode .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .dark-theme-mode .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.dark-theme-mode .mat-button .mat-ripple-element, .dark-theme-mode .mat-icon-button .mat-ripple-element, .dark-theme-mode .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.dark-theme-mode .mat-button-focus-overlay {
  background: white;
}
.dark-theme-mode .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-flat-button, .dark-theme-mode .mat-raised-button, .dark-theme-mode .mat-fab, .dark-theme-mode .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.dark-theme-mode .mat-flat-button.mat-primary, .dark-theme-mode .mat-raised-button.mat-primary, .dark-theme-mode .mat-fab.mat-primary, .dark-theme-mode .mat-mini-fab.mat-primary {
  color: white;
}
.dark-theme-mode .mat-flat-button.mat-accent, .dark-theme-mode .mat-raised-button.mat-accent, .dark-theme-mode .mat-fab.mat-accent, .dark-theme-mode .mat-mini-fab.mat-accent {
  color: white;
}
.dark-theme-mode .mat-flat-button.mat-warn, .dark-theme-mode .mat-raised-button.mat-warn, .dark-theme-mode .mat-fab.mat-warn, .dark-theme-mode .mat-mini-fab.mat-warn {
  color: white;
}
.dark-theme-mode .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-fab.mat-primary.mat-button-disabled, .dark-theme-mode .mat-fab.mat-accent.mat-button-disabled, .dark-theme-mode .mat-fab.mat-warn.mat-button-disabled, .dark-theme-mode .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-flat-button.mat-primary, .dark-theme-mode .mat-raised-button.mat-primary, .dark-theme-mode .mat-fab.mat-primary, .dark-theme-mode .mat-mini-fab.mat-primary {
  background-color: #0054f2;
}
.dark-theme-mode .mat-flat-button.mat-accent, .dark-theme-mode .mat-raised-button.mat-accent, .dark-theme-mode .mat-fab.mat-accent, .dark-theme-mode .mat-mini-fab.mat-accent {
  background-color: #0054f2;
}
.dark-theme-mode .mat-flat-button.mat-warn, .dark-theme-mode .mat-raised-button.mat-warn, .dark-theme-mode .mat-fab.mat-warn, .dark-theme-mode .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.dark-theme-mode .mat-flat-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-flat-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-primary.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-accent.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-warn.mat-button-disabled, .dark-theme-mode .mat-raised-button.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-fab.mat-primary.mat-button-disabled, .dark-theme-mode .mat-fab.mat-accent.mat-button-disabled, .dark-theme-mode .mat-fab.mat-warn.mat-button-disabled, .dark-theme-mode .mat-fab.mat-button-disabled.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-primary.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-accent.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-warn.mat-button-disabled, .dark-theme-mode .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-flat-button.mat-primary .mat-ripple-element, .dark-theme-mode .mat-raised-button.mat-primary .mat-ripple-element, .dark-theme-mode .mat-fab.mat-primary .mat-ripple-element, .dark-theme-mode .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-flat-button.mat-accent .mat-ripple-element, .dark-theme-mode .mat-raised-button.mat-accent .mat-ripple-element, .dark-theme-mode .mat-fab.mat-accent .mat-ripple-element, .dark-theme-mode .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-flat-button.mat-warn .mat-ripple-element, .dark-theme-mode .mat-raised-button.mat-warn .mat-ripple-element, .dark-theme-mode .mat-fab.mat-warn .mat-ripple-element, .dark-theme-mode .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-stroked-button:not([class*=mat-elevation-z]), .dark-theme-mode .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-fab:not([class*=mat-elevation-z]), .dark-theme-mode .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .dark-theme-mode .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .dark-theme-mode .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.dark-theme-mode .mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.dark-theme-mode .mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}
.dark-theme-mode .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.dark-theme-mode .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.dark-theme-mode .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #595959;
}
.dark-theme-mode [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #595959;
}
.dark-theme-mode .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #595959;
}
.dark-theme-mode .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.dark-theme-mode .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.dark-theme-mode .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.dark-theme-mode .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.dark-theme-mode .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.dark-theme-mode .mat-button-toggle-group-appearance-standard {
  border: solid 1px #595959;
}
.dark-theme-mode .mat-card {
  background: #424242;
  color: white;
}
.dark-theme-mode .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-checkbox-checkmark {
  fill: #303030;
}
.dark-theme-mode .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.dark-theme-mode .mat-checkbox-mixedmark {
  background-color: #303030;
}
.dark-theme-mode .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .dark-theme-mode .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #0054f2;
}
.dark-theme-mode .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .dark-theme-mode .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #0054f2;
}
.dark-theme-mode .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .dark-theme-mode .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.dark-theme-mode .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .dark-theme-mode .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.dark-theme-mode .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.dark-theme-mode .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.dark-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.dark-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #0054f2;
}
.dark-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.dark-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #0054f2;
}
.dark-theme-mode .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.dark-theme-mode .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.dark-theme-mode .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.dark-theme-mode .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.dark-theme-mode .mat-chip.mat-standard-chip::after {
  background: white;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-table {
  background: #424242;
}
.dark-theme-mode .mat-table thead, .dark-theme-mode .mat-table tbody, .dark-theme-mode .mat-table tfoot,
.dark-theme-mode mat-header-row, .dark-theme-mode mat-row, .dark-theme-mode mat-footer-row,
.dark-theme-mode [mat-header-row], .dark-theme-mode [mat-row], .dark-theme-mode [mat-footer-row],
.dark-theme-mode .mat-table-sticky {
  background: inherit;
}
.dark-theme-mode mat-row, .dark-theme-mode mat-header-row, .dark-theme-mode mat-footer-row,
.dark-theme-mode th.mat-header-cell, .dark-theme-mode td.mat-cell, .dark-theme-mode td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-cell, .dark-theme-mode .mat-footer-cell {
  color: white;
}
.dark-theme-mode .mat-calendar-arrow {
  fill: white;
}
.dark-theme-mode .mat-datepicker-toggle,
.dark-theme-mode .mat-datepicker-content .mat-calendar-next-button,
.dark-theme-mode .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.dark-theme-mode .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-calendar-table-header,
.dark-theme-mode .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-calendar-body-cell-content,
.dark-theme-mode .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.dark-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.dark-theme-mode .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-calendar-body-in-range::before {
  background: rgba(0, 84, 242, 0.2);
}
.dark-theme-mode .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-mode .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-mode [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-mode [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-mode .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-mode .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-mode .mat-calendar-body-selected {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 84, 242, 0.4);
}
.dark-theme-mode .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-mode .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-mode .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 84, 242, 0.3);
}
@media (hover: hover) {
  .dark-theme-mode .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 84, 242, 0.3);
  }
}
.dark-theme-mode .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(0, 84, 242, 0.2);
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-mode .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-mode .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(0, 84, 242, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(0, 84, 242, 0.4);
}
.dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-mode .mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-mode .mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 84, 242, 0.3);
}
@media (hover: hover) {
  .dark-theme-mode .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(0, 84, 242, 0.3);
  }
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.dark-theme-mode .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.dark-theme-mode .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.dark-theme-mode .mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.dark-theme-mode .mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .dark-theme-mode .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.dark-theme-mode .mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-datepicker-toggle-active {
  color: #0054f2;
}
.dark-theme-mode .mat-datepicker-toggle-active.mat-accent {
  color: #0054f2;
}
.dark-theme-mode .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.dark-theme-mode .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.dark-theme-mode .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-expansion-panel {
  background: #424242;
  color: white;
}
.dark-theme-mode .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .dark-theme-mode .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .dark-theme-mode .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .dark-theme-mode .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.dark-theme-mode .mat-expansion-panel-header-title {
  color: white;
}
.dark-theme-mode .mat-expansion-panel-header-description,
.dark-theme-mode .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.dark-theme-mode .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.dark-theme-mode .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-label {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.dark-theme-mode .mat-focused .mat-form-field-required-marker {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field-ripple {
  background-color: white;
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.dark-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.dark-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.dark-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.dark-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.dark-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.dark-theme-mode .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.dark-theme-mode .mat-error {
  color: #f44336;
}
.dark-theme-mode .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme-mode .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.dark-theme-mode .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.dark-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.dark-theme-mode .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.dark-theme-mode .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.dark-theme-mode .mat-icon.mat-primary {
  color: #0054f2;
}
.dark-theme-mode .mat-icon.mat-accent {
  color: #0054f2;
}
.dark-theme-mode .mat-icon.mat-warn {
  color: #f44336;
}
.dark-theme-mode .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-input-element:disabled,
.dark-theme-mode .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-input-element {
  caret-color: #0054f2;
}
.dark-theme-mode .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-input-element:not(.mat-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme-mode .mat-input-element:not(.mat-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.dark-theme-mode .mat-form-field.mat-accent .mat-input-element {
  caret-color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-warn .mat-input-element,
.dark-theme-mode .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.dark-theme-mode .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.dark-theme-mode .mat-list-base .mat-list-item {
  color: white;
}
.dark-theme-mode .mat-list-base .mat-list-option {
  color: white;
}
.dark-theme-mode .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-list-base .mat-list-item-disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-list-option:hover, .dark-theme-mode .mat-list-option:focus,
.dark-theme-mode .mat-nav-list .mat-list-item:hover,
.dark-theme-mode .mat-nav-list .mat-list-item:focus,
.dark-theme-mode .mat-action-list .mat-list-item:hover,
.dark-theme-mode .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme-mode .mat-list-single-selected-option, .dark-theme-mode .mat-list-single-selected-option:hover, .dark-theme-mode .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-menu-panel {
  background: #424242;
}
.dark-theme-mode .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-menu-item {
  background: transparent;
  color: white;
}
.dark-theme-mode .mat-menu-item[disabled],
.dark-theme-mode .mat-menu-item[disabled] .mat-menu-submenu-icon,
.dark-theme-mode .mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-menu-item .mat-icon-no-color,
.dark-theme-mode .mat-menu-submenu-icon {
  color: white;
}
.dark-theme-mode .mat-menu-item:hover:not([disabled]),
.dark-theme-mode .mat-menu-item.cdk-program-focused:not([disabled]),
.dark-theme-mode .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.dark-theme-mode .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.dark-theme-mode .mat-paginator {
  background: #424242;
}
.dark-theme-mode .mat-paginator,
.dark-theme-mode .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-paginator-decrement,
.dark-theme-mode .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.dark-theme-mode .mat-paginator-first,
.dark-theme-mode .mat-paginator-last {
  border-top: 2px solid white;
}
.dark-theme-mode .mat-icon-button[disabled] .mat-paginator-decrement,
.dark-theme-mode .mat-icon-button[disabled] .mat-paginator-increment,
.dark-theme-mode .mat-icon-button[disabled] .mat-paginator-first,
.dark-theme-mode .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-progress-bar-background {
  fill: #243961;
}
.dark-theme-mode .mat-progress-bar-buffer {
  background-color: #243961;
}
.dark-theme-mode .mat-progress-bar-fill::after {
  background-color: #0054f2;
}
.dark-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #243961;
}
.dark-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #243961;
}
.dark-theme-mode .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #0054f2;
}
.dark-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #613532;
}
.dark-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #613532;
}
.dark-theme-mode .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.dark-theme-mode .mat-progress-spinner circle, .dark-theme-mode .mat-spinner circle {
  stroke: #0054f2;
}
.dark-theme-mode .mat-progress-spinner.mat-accent circle, .dark-theme-mode .mat-spinner.mat-accent circle {
  stroke: #0054f2;
}
.dark-theme-mode .mat-progress-spinner.mat-warn circle, .dark-theme-mode .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.dark-theme-mode .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0054f2;
}
.dark-theme-mode .mat-radio-button.mat-primary .mat-radio-inner-circle,
.dark-theme-mode .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme-mode .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme-mode .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #0054f2;
}
.dark-theme-mode .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0054f2;
}
.dark-theme-mode .mat-radio-button.mat-accent .mat-radio-inner-circle,
.dark-theme-mode .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme-mode .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme-mode .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #0054f2;
}
.dark-theme-mode .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.dark-theme-mode .mat-radio-button.mat-warn .mat-radio-inner-circle,
.dark-theme-mode .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .dark-theme-mode .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .dark-theme-mode .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.dark-theme-mode .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.dark-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.dark-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.dark-theme-mode .mat-select-value {
  color: white;
}
.dark-theme-mode .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-select-panel {
  background: #424242;
}
.dark-theme-mode .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #0054f2;
}
.dark-theme-mode .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.dark-theme-mode .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.dark-theme-mode .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.dark-theme-mode .mat-drawer {
  background-color: #424242;
  color: white;
}
.dark-theme-mode .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.dark-theme-mode .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme-mode [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.dark-theme-mode [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.dark-theme-mode .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 84, 242, 0.54);
}
.dark-theme-mode .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 84, 242, 0.54);
}
.dark-theme-mode .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.dark-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.dark-theme-mode .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.dark-theme-mode .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.dark-theme-mode .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.dark-theme-mode .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-primary .mat-slider-track-fill,
.dark-theme-mode .mat-slider.mat-primary .mat-slider-thumb,
.dark-theme-mode .mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme-mode .mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(0, 84, 242, 0.2);
}
.dark-theme-mode .mat-slider.mat-accent .mat-slider-track-fill,
.dark-theme-mode .mat-slider.mat-accent .mat-slider-thumb,
.dark-theme-mode .mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #0054f2;
}
.dark-theme-mode .mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme-mode .mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(0, 84, 242, 0.2);
}
.dark-theme-mode .mat-slider.mat-warn .mat-slider-track-fill,
.dark-theme-mode .mat-slider.mat-warn .mat-slider-thumb,
.dark-theme-mode .mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.dark-theme-mode .mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.dark-theme-mode .mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.dark-theme-mode .mat-slider:hover .mat-slider-track-background,
.dark-theme-mode .mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-slider-disabled .mat-slider-track-background,
.dark-theme-mode .mat-slider.mat-slider-disabled .mat-slider-track-fill,
.dark-theme-mode .mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.dark-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.dark-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.dark-theme-mode .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.dark-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .dark-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .dark-theme-mode .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.dark-theme-mode .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme-mode .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.dark-theme-mode .mat-step-header.cdk-keyboard-focused, .dark-theme-mode .mat-step-header.cdk-program-focused, .dark-theme-mode .mat-step-header:hover:not([aria-disabled]), .dark-theme-mode .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(255, 255, 255, 0.04);
}
.dark-theme-mode .mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .dark-theme-mode .mat-step-header:hover {
    background: none;
  }
}
.dark-theme-mode .mat-step-header .mat-step-label,
.dark-theme-mode .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.dark-theme-mode .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.dark-theme-mode .mat-step-header .mat-step-icon-selected,
.dark-theme-mode .mat-step-header .mat-step-icon-state-done,
.dark-theme-mode .mat-step-header .mat-step-icon-state-edit {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-step-header.mat-accent .mat-step-icon {
  color: white;
}
.dark-theme-mode .mat-step-header.mat-accent .mat-step-icon-selected,
.dark-theme-mode .mat-step-header.mat-accent .mat-step-icon-state-done,
.dark-theme-mode .mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: #0054f2;
  color: white;
}
.dark-theme-mode .mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.dark-theme-mode .mat-step-header.mat-warn .mat-step-icon-selected,
.dark-theme-mode .mat-step-header.mat-warn .mat-step-icon-state-done,
.dark-theme-mode .mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.dark-theme-mode .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.dark-theme-mode .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.dark-theme-mode .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.dark-theme-mode .mat-stepper-horizontal, .dark-theme-mode .mat-stepper-vertical {
  background-color: #424242;
}
.dark-theme-mode .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-horizontal-stepper-header::before,
.dark-theme-mode .mat-horizontal-stepper-header::after,
.dark-theme-mode .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-sort-header-arrow {
  color: #c6c6c6;
}
.dark-theme-mode .mat-tab-nav-bar,
.dark-theme-mode .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.dark-theme-mode .mat-tab-group-inverted-header .mat-tab-nav-bar,
.dark-theme-mode .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.dark-theme-mode .mat-tab-label, .dark-theme-mode .mat-tab-link {
  color: white;
}
.dark-theme-mode .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-tab-header-pagination-chevron {
  border-color: white;
}
.dark-theme-mode .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.dark-theme-mode .mat-tab-group[class*=mat-background-] > .mat-tab-header,
.dark-theme-mode .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.dark-theme-mode .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-primary .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0054f2;
}
.dark-theme-mode .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme-mode .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-accent .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #0054f2;
}
.dark-theme-mode .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme-mode .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-warn .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.dark-theme-mode .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .dark-theme-mode .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header, .dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container, .dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #0054f2;
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme-mode .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(179, 204, 251, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header, .dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container, .dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: #0054f2;
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme-mode .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .dark-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header, .dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container, .dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.dark-theme-mode .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.dark-theme-mode .mat-toolbar {
  background: #212121;
  color: white;
}
.dark-theme-mode .mat-toolbar.mat-primary {
  background: #0054f2;
  color: white;
}
.dark-theme-mode .mat-toolbar.mat-accent {
  background: #0054f2;
  color: white;
}
.dark-theme-mode .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.dark-theme-mode .mat-toolbar .mat-form-field-underline,
.dark-theme-mode .mat-toolbar .mat-form-field-ripple,
.dark-theme-mode .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.dark-theme-mode .mat-toolbar .mat-form-field-label,
.dark-theme-mode .mat-toolbar .mat-focused .mat-form-field-label,
.dark-theme-mode .mat-toolbar .mat-select-value,
.dark-theme-mode .mat-toolbar .mat-select-arrow,
.dark-theme-mode .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.dark-theme-mode .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.dark-theme-mode .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.dark-theme-mode .mat-tree {
  background: #424242;
}
.dark-theme-mode .mat-tree-node,
.dark-theme-mode .mat-nested-tree-node {
  color: white;
}
.dark-theme-mode .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.dark-theme-mode .mat-simple-snackbar-action {
  color: inherit;
}

:root {
  --app-white: #ffffff;
  --app-black: #000000;
  --app-gray-six: rgba(0, 62, 179, 0.5);
  --app-gray-twenty: rgba(0, 0, 0, 0.5);
  --app-gray-twentyfive: rgba(0, 0, 0, 0.6);
  --app-gray-twentynine: #9ea0a5;
  --app-gray-one: #161616;
  --app-red-one: #fc4747;
  --app-red-two: #f10b28;
  --app-red-three: #dc4949;
  --app-red-four: #ff2800;
  --app-green-one: #03d1bd;
  --app-green-three: #0ec164;
  --app-green-five: #429321;
  --app-green-six: #b4ec51;
  --app-yellow-one: #eeb735;
  --app-yellow-two: rgba(255, 248, 210, 0.4);
  --app-yellow-three: #ffd135;
  --app-purple-one: #be4bdb;
  --app-purple-three: #f0e3fd;
  --app-blue-one: #2196f3;
  --app-blue-four: rgba(0, 84, 242, 0.18);
  --app-blue-five: rgba(0, 84, 242, 0.5);
  --app-blue-six: rgba(0, 84, 242, 0.12);
  --app-blue-seven: #0054f2;
  --app-orange-one: #f74e13;
  --app-orange-two: #eeb635;
  --app-orange-three: #ffa03b;
  --app-gold: #dfc271;
  --app-color-free: #14c8b1;
  --app-color-premium: #0054f2;
  --app-color-premiumplus: #000000;
  --app-color-facebook: #3e6ad6;
  --app-free-gradient-1: #49dc50;
  --app-free-gradient-2: #0cc6bf;
  --app-premiumplus-gradient-1: #fad961;
  --app-premiumplus-gradient-2: #f76b1c;
  --app-elite-gradient-1: #000000;
  --app-elite-gradient-2: #313131;
  --transparent: rgba(255, 255, 255, 0.0000001);
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-intercom: 0px;
}
@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}
@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}
@screen sm {
  :root {
    --safe-intercom: 100px;
  }
}

.light-theme-mode {
  --theme-black: #000000;
  --theme-white: #ffffff;
  --theme-gray-one: #161616;
  --theme-gray-two: #666666;
  --theme-gray-three: #e6e6e6;
  --theme-gray-four: #7a838f;
  --theme-gray-five: #969696;
  --theme-gray-seven: #e4e8ea;
  --theme-gray-eight: #dadfe4;
  --theme-gray-nine: #505050;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #c6cbd4;
  --theme-gray-twelve: #434b50;
  --theme-gray-thirteen: #0e0e0e;
  --theme-gray-fourteen: #bdbdbd;
  --theme-gray-fifteen: #dbe8ff;
  --theme-gray-sixteen: #c8c8c8;
  --theme-gray-seventeen: #fafafa;
  --theme-gray-eighteen: #efefef;
  --theme-gray-nineteen: rgba(0, 0, 0, 0.1);
  --theme-gray-twentyone: rgba(255, 255, 255, 0.9);
  --theme-gray-twentytwo: rgba(255, 255, 255, 0.33);
  --theme-gray-twentythree: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyfour: rgba(0, 0, 0, 0.7);
  --theme-gray-twentysix: rgba(158, 160, 165, 0.7);
  --theme-gray-twentyseven: #cedffc;
  --theme-gray-twentyeight: rgba(0, 0, 0, 0.24);
  --theme-gray-twentynine: rgba(255, 255, 255, 0.8);
  --theme-purple-two: #3a2080;
  --theme-blue-two: rgba(46, 48, 64, 0.3);
  --theme-blue-three: rgba(46, 48, 64, 0.2);
}

.dark-theme-mode {
  --theme-black: #ffffff;
  --theme-white: #303030;
  --theme-gray-one: #e9e9e9;
  --theme-gray-two: #999999;
  --theme-gray-three: #191919;
  --theme-gray-four: #707985;
  --theme-gray-five: #969696;
  --theme-gray-seven: #1b1715;
  --theme-gray-eight: #1b1f25;
  --theme-gray-nine: #afafaf;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #d8d8d8;
  --theme-gray-twelve: #afb7bc;
  --theme-gray-thirteen: #f1f1f1;
  --theme-gray-fourteen: #424242;
  --theme-gray-fifteen: #000f24;
  --theme-gray-sixteen: #373737;
  --theme-gray-seventeen: #050505;
  --theme-gray-eighteen: #101010;
  --theme-gray-nineteen: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyone: rgba(48, 48, 48, 0.9);
  --theme-gray-twentytwo: rgba(48, 48, 48, 0.33);
  --theme-gray-twentythree: rgba(48, 48, 48, 0.1);
  --theme-gray-twentyfour: rgba(255, 255, 255, 0.7);
  --theme-gray-twentysix: rgba(90, 91, 97, 0.7);
  --theme-gray-twentyseven: #032031;
  --theme-gray-twentyeight: rgba(255, 255, 255, 0.24);
  --theme-gray-twentynine: rgba(48, 48, 48, 0.8);
  --theme-purple-two: #8d6ce6;
  --theme-blue-two: rgba(191, 195, 209, 0.3);
  --theme-blue-three: rgba(191, 195, 209, 0.2);
}

.ecni-theme-site {
  --site-main-one: #0054f2;
  --site-main-two: rgb(236, 247, 255);
  --site-main-five: #f6f9fe;
  --site-main-six: rgba(43, 126, 251, 0.08);
  --site-light-card: #267bfb;
  --site-dark-card: #2013b5;
  --site-card-gradient-1: #297af6;
  --site-card-gradient-2: #4fc8fd;
  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;
  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;
  --site-section-3-gradient-1: #008dfa;
  --site-section-3-gradient-2: #0054f2;
  --site-section-4-gradient-1: #297af6;
  --site-section-4-gradient-2: #4fc8fd;
  --site-public-gradient-1: rgba(0, 144, 255, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);
  --site-oval-challenge-list: #ac91f6;
  --site-logo-1: rgba(0, 84, 242, 0.9);
  --site-logo-2: rgba(255, 91, 27, 0.9);
  --site-logo-3: rgba(153, 206, 37, 0.9);
  --site-logo-4: rgba(238, 183, 53, 0.9);
}
.ecni-theme-site.light-theme-mode {
  --site-theme-main-two: #dbe8ff;
  --site-theme-main-three: #f7f9ff;
  --site-theme-main-four: #ebf3fe;
  --site-theme-main-five: #f6f9fe;
  --site-theme-accent: var(--site-main-one);
}
.ecni-theme-site.dark-theme-mode {
  --site-theme-main-two: #000a24;
  --site-theme-main-three: #000308;
  --site-theme-main-four: #010b14;
  --site-theme-main-five: #010409;
  --site-theme-accent: var(--app-white);
}

.prepssm-theme-site {
  --site-main-one: #0ec164;
  --site-main-two: rgb(239, 251, 245);
  --site-main-five: #f2fffd;
  --site-main-six: rgba(14, 193, 100, 0.08);
  --site-light-card: #17d071;
  --site-dark-card: #006139;
  --site-card-gradient-1: #0cc6bf;
  --site-card-gradient-2: #49dc50;
  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;
  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;
  --site-section-3-gradient-1: #0ec164;
  --site-section-3-gradient-2: #03d1bd;
  --site-section-4-gradient-1: #0cc6bf;
  --site-section-4-gradient-2: #49dc50;
  --site-public-gradient-1: rgba(38, 199, 115, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);
  --site-oval-challenge-list: #ac91f6;
  --site-logo-1: rgba(14, 193, 100, 0.9);
  --site-logo-2: rgba(74, 233, 150, 0.9);
  --site-logo-3: rgba(14, 193, 100, 0.9);
  --site-logo-4: rgba(255, 91, 27, 0.9);
}
.prepssm-theme-site.light-theme-mode {
  --site-theme-main-two: #d7f2e6;
  --site-theme-main-three: #f3fff3;
  --site-theme-main-four: #dcffeb;
  --site-theme-main-five: #f2fffd;
  --site-theme-accent: var(--site-main-one);
}
.prepssm-theme-site.dark-theme-mode {
  --site-theme-main-two: #22564b;
  --site-theme-main-three: #030c00;
  --site-theme-main-four: #032300;
  --site-theme-main-five: #000d0b;
  --site-theme-accent: var(--app-white);
}

.prepmir-theme-site {
  --site-main-one: #d32030;
  --site-main-two: rgb(255, 251, 238);
  --site-main-five: #fffcf2;
  --site-main-six: rgba(248, 251, 43, 0.08);
  --site-light-card: #ffd135;
  --site-dark-card: #d31d2d;
  --site-card-gradient-1: #ffac56;
  --site-card-gradient-2: #ffd135;
  --site-section-gradient-1: #ffd135;
  --site-section-gradient-2: #ffac56;
  --site-section-2-gradient-1: #ffac56;
  --site-section-2-gradient-2: #ffd135;
  --site-section-3-gradient-1: #cf7a22;
  --site-section-3-gradient-2: #ffd135;
  --site-section-4-gradient-1: #f6aa36;
  --site-section-4-gradient-2: #ed7f0b;
  --site-public-gradient-1: #fff7dc;
  --site-public-gradient-2: #ffffff;
  --site-oval-challenge-list: #ef9545;
  --site-logo-1: rgba(211, 32, 48, 0.9);
  --site-logo-2: rgba(255, 209, 53, 0.9);
  --site-logo-3: rgba(211, 32, 48, 0.9);
  --site-logo-4: rgba(233, 182, 7, 0.9);
}
.prepmir-theme-site.light-theme-mode {
  --site-theme-main-two: #ffe7ab;
  --site-theme-main-three: #fffef7;
  --site-theme-main-four: #fff4cf;
  --site-theme-main-five: #fffcf2;
  --site-theme-accent: var(--site-main-one);
}
.prepmir-theme-site.dark-theme-mode {
  --site-theme-main-two: #543c00;
  --site-theme-main-three: #080600;
  --site-theme-main-four: #301e00;
  --site-theme-main-five: #0d0a00;
  --site-theme-accent: var(--app-white);
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  8.5% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  16.5% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  22.5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  5% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  8.5% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  16.5% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  22.5% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
.nnki-no-select, .card--nnki-question.-time-lapsed .question-images {
  @apply select-none pointer-events-none;
}

.flex-center {
  @apply flex items-center justify-center;
}

@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirLTStdBook.woff2") format("woff2"), url("/assets/fonts/Avenir/AvenirLTStdBook.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirLTStdRoman.woff2") format("woff2"), url("/assets/fonts/Avenir/AvenirLTStdRoman.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirLTStdHeavy.woff2") format("woff2"), url("/assets/fonts/Avenir/AvenirLTStdHeavy.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "Avenir";
  src: url("/assets/fonts/Avenir/AvenirLTStdBlack.woff2") format("woff2"), url("/assets/fonts/Avenir/AvenirLTStdBlack.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-light.woff2") format("woff2"), url("/assets/fonts/Muli/muli-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-regular.woff2") format("woff2"), url("/assets/fonts/Muli/muli-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-semibold.woff2") format("woff2"), url("/assets/fonts/Muli/muli-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-bold.woff2") format("woff2"), url("/assets/fonts/Muli/muli-bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-extrabold.woff2") format("woff2"), url("/assets/fonts/Muli/muli-extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Muli";
  src: url("/assets/fonts/Muli/muli-black.woff2") format("woff2"), url("/assets/fonts/Muli/muli-black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/montserrat-semibold.woff2") format("woff2"), url("/assets/fonts/montserrat/montserrat-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/Montserrat-Bold.woff2") format("woff2"), url("/assets/fonts/montserrat/Montserrat-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("/assets/fonts/montserrat/montserrat-extrabold.woff2") format("woff2"), url("/assets/fonts/montserrat/montserrat-extrabold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}
html,
body {
  @apply h-full m-0 bg-theme-white text-theme-black;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: transparent;
}

body {
  @apply text-14 font-default pt-safeTop pb-safeBottom;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
label {
  @apply m-0 p-0;
}

p {
  @apply leading-snug;
}

ul {
  @apply list-none p-0 m-0;
}

li::marker {
  content: "";
}

a:focus, a:active,
div:focus,
div:active,
input:focus,
input:active,
textarea:focus,
textarea:active,
button:focus,
button:active {
  outline: none !important;
}

a {
  @apply cursor-pointer transition-colors;
  color: inherit;
  text-decoration: inherit;
}

img {
  @apply max-w-full align-middle;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

sup {
  font-size: 60%;
}

* {
  @apply box-border;
}

.innerHtml p {
  @apply mb-4;
}
.innerHtml p strong {
  @apply text-site-main-one;
}
.innerHtml ul {
  @apply list-disc ml-8 mb-8 space-y-4;
}

@tailwind base;
.mat-drawer-container {
  @apply bg-theme-white text-theme-black;
}

.admin-list.cdk-drop-list.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
.admin-list.cdk-drop-list .admin-list-element {
  @apply cursor-move;
}

.cdk-drag-preview {
  @apply box-border shadow border border-site-theme-main-two bg-theme-white;
}
.cdk-drag-preview > * {
  @apply invisible;
}

.cdk-drag-placeholder {
  @apply opacity-0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-overlay-pane {
  max-width: 100vw !important;
}

.cdk-overlay-dark-backdrop {
  @apply bg-gray-twentyfive;
}

.mat-dialog-container {
  text-align: center !important;
  background: transparent !important;
  box-shadow: none !important;
  max-height: 100vh !important;
  color: var(--theme-black);
  padding: 0 !important;
  border-radius: 0 !important;
}
@screen sm {
  .mat-dialog-container {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: calc(var(--safe-area-inset-top) + 20px) !important;
    padding-bottom: calc(var(--safe-area-inset-bottom) + 20px) !important;
    border-radius: 6px !important;
  }
}
.mat-dialog-container > * {
  display: block;
  max-height: 100vh;
  overflow: auto;
}
@screen sm {
  .mat-dialog-container > * {
    max-height: calc(100vh - 40px);
  }
}

.mat-accordion app-writer-area .mat-expansion-panel {
  @apply text-theme-black;
}
.mat-accordion app-writer-area .mat-expansion-panel:not([class*=mat-elevation-z]) {
  @apply shadow-none border border-site-theme-main-two;
}
.mat-accordion app-writer-area .mat-expansion-panel-header {
  @apply font-default;
}
.mat-accordion app-writer-area .mat-expansion-panel-header .mat-content {
  @apply font-bold text-site-main-one;
}
.mat-accordion app-writer-area .mat-expansion-panel-content {
  @apply font-default;
}
.mat-accordion app-writer-area .mat-content > * {
  @apply truncate;
}
.mat-accordion .nnki-accordion-header {
  @apply text-site-main-one bg-site-theme-main-five hover:bg-site-theme-main-five;
}
.mat-accordion .nnki-accordion-header .nnki-accordion-title {
  @apply font-bold flex justify-between items-center w-full;
}
.mat-accordion .nnki-accordion-header .mat-expansion-indicator::after {
  @apply text-site-main-one;
}
.mat-accordion .nnki-accordion-header + .mat-expansion-panel-content .mat-expansion-panel-body {
  @apply pt-8;
}
.mat-accordion .nnki-accordion-header.questions-accordions + .mat-expansion-panel-content .mat-expansion-panel-body {
  @apply p-0 bg-site-theme-main-four;
}
.mat-accordion .expansion-questions-list {
  @apply max-h-[200px] overflow-auto p-[24px] relative;
}
.mat-accordion .expansion-questions-list .questions {
  @apply relative;
}
.mat-accordion .expansion-questions-list .question {
  @apply flex items-center justify-between py-[6px] px-0 text-13 font-bold cursor-pointer;
}
.mat-accordion .expansion-questions-list .question > svg-icon {
  @apply text-blue-five;
}

mat-paginator {
  @apply bg-transparent !important;
}

app-writer-area .mat-tab-nav-bar {
  @apply border-b border-b-site-theme-main-two !important;
}
app-writer-area .mat-tab-links .mat-tab-link {
  @apply bg-theme-white border-t border-r border-site-theme-main-two text-site-main-one font-bold opacity-100;
}
app-writer-area .mat-tab-links .mat-tab-link:first-child {
  @apply border-l;
}
app-writer-area .mat-tab-links .mat-tab-link.mat-tab-label-active {
  @apply text-site-main-one;
}
app-writer-area .mat-tab-content {
  @apply pt-8;
}

xng-breadcrumb {
  @apply block mb-8 p-4 bg-theme-white border border-site-theme-main-two text-site-main-one;
}
xng-breadcrumb .xng-breadcrumb-trail {
  @apply font-bold text-site-main-one;
}

:root {
  --app-white: #ffffff;
  --app-black: #000000;
  --app-gray-six: rgba(0, 62, 179, 0.5);
  --app-gray-twenty: rgba(0, 0, 0, 0.5);
  --app-gray-twentyfive: rgba(0, 0, 0, 0.6);
  --app-gray-twentynine: #9ea0a5;
  --app-gray-one: #161616;
  --app-red-one: #fc4747;
  --app-red-two: #f10b28;
  --app-red-three: #dc4949;
  --app-red-four: #ff2800;
  --app-green-one: #03d1bd;
  --app-green-three: #0ec164;
  --app-green-five: #429321;
  --app-green-six: #b4ec51;
  --app-yellow-one: #eeb735;
  --app-yellow-two: rgba(255, 248, 210, 0.4);
  --app-yellow-three: #ffd135;
  --app-purple-one: #be4bdb;
  --app-purple-three: #f0e3fd;
  --app-blue-one: #2196f3;
  --app-blue-four: rgba(0, 84, 242, 0.18);
  --app-blue-five: rgba(0, 84, 242, 0.5);
  --app-blue-six: rgba(0, 84, 242, 0.12);
  --app-blue-seven: #0054f2;
  --app-orange-one: #f74e13;
  --app-orange-two: #eeb635;
  --app-orange-three: #ffa03b;
  --app-gold: #dfc271;
  --app-color-free: #14c8b1;
  --app-color-premium: #0054f2;
  --app-color-premiumplus: #000000;
  --app-color-facebook: #3e6ad6;
  --app-free-gradient-1: #49dc50;
  --app-free-gradient-2: #0cc6bf;
  --app-premiumplus-gradient-1: #fad961;
  --app-premiumplus-gradient-2: #f76b1c;
  --app-elite-gradient-1: #000000;
  --app-elite-gradient-2: #313131;
  --transparent: rgba(255, 255, 255, 0.0000001);
  --safe-area-inset-top: 0px;
  --safe-area-inset-bottom: 0px;
  --safe-intercom: 0px;
}
@supports (top: constant(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: constant(safe-area-inset-top);
    --safe-area-inset-bottom: constant(safe-area-inset-bottom);
  }
}
@supports (top: env(safe-area-inset-top)) {
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: env(safe-area-inset-bottom);
  }
}
@screen sm {
  :root {
    --safe-intercom: 100px;
  }
}

.light-theme-mode {
  --theme-black: #000000;
  --theme-white: #ffffff;
  --theme-gray-one: #161616;
  --theme-gray-two: #666666;
  --theme-gray-three: #e6e6e6;
  --theme-gray-four: #7a838f;
  --theme-gray-five: #969696;
  --theme-gray-seven: #e4e8ea;
  --theme-gray-eight: #dadfe4;
  --theme-gray-nine: #505050;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #c6cbd4;
  --theme-gray-twelve: #434b50;
  --theme-gray-thirteen: #0e0e0e;
  --theme-gray-fourteen: #bdbdbd;
  --theme-gray-fifteen: #dbe8ff;
  --theme-gray-sixteen: #c8c8c8;
  --theme-gray-seventeen: #fafafa;
  --theme-gray-eighteen: #efefef;
  --theme-gray-nineteen: rgba(0, 0, 0, 0.1);
  --theme-gray-twentyone: rgba(255, 255, 255, 0.9);
  --theme-gray-twentytwo: rgba(255, 255, 255, 0.33);
  --theme-gray-twentythree: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyfour: rgba(0, 0, 0, 0.7);
  --theme-gray-twentysix: rgba(158, 160, 165, 0.7);
  --theme-gray-twentyseven: #cedffc;
  --theme-gray-twentyeight: rgba(0, 0, 0, 0.24);
  --theme-gray-twentynine: rgba(255, 255, 255, 0.8);
  --theme-purple-two: #3a2080;
  --theme-blue-two: rgba(46, 48, 64, 0.3);
  --theme-blue-three: rgba(46, 48, 64, 0.2);
}

.dark-theme-mode {
  --theme-black: #ffffff;
  --theme-white: #303030;
  --theme-gray-one: #e9e9e9;
  --theme-gray-two: #999999;
  --theme-gray-three: #191919;
  --theme-gray-four: #707985;
  --theme-gray-five: #969696;
  --theme-gray-seven: #1b1715;
  --theme-gray-eight: #1b1f25;
  --theme-gray-nine: #afafaf;
  --theme-gray-ten: #272727;
  --theme-gray-eleven: #d8d8d8;
  --theme-gray-twelve: #afb7bc;
  --theme-gray-thirteen: #f1f1f1;
  --theme-gray-fourteen: #424242;
  --theme-gray-fifteen: #000f24;
  --theme-gray-sixteen: #373737;
  --theme-gray-seventeen: #050505;
  --theme-gray-eighteen: #101010;
  --theme-gray-nineteen: rgba(255, 255, 255, 0.1);
  --theme-gray-twentyone: rgba(48, 48, 48, 0.9);
  --theme-gray-twentytwo: rgba(48, 48, 48, 0.33);
  --theme-gray-twentythree: rgba(48, 48, 48, 0.1);
  --theme-gray-twentyfour: rgba(255, 255, 255, 0.7);
  --theme-gray-twentysix: rgba(90, 91, 97, 0.7);
  --theme-gray-twentyseven: #032031;
  --theme-gray-twentyeight: rgba(255, 255, 255, 0.24);
  --theme-gray-twentynine: rgba(48, 48, 48, 0.8);
  --theme-purple-two: #8d6ce6;
  --theme-blue-two: rgba(191, 195, 209, 0.3);
  --theme-blue-three: rgba(191, 195, 209, 0.2);
}

.ecni-theme-site {
  --site-main-one: #0054f2;
  --site-main-two: rgb(236, 247, 255);
  --site-main-five: #f6f9fe;
  --site-main-six: rgba(43, 126, 251, 0.08);
  --site-light-card: #267bfb;
  --site-dark-card: #2013b5;
  --site-card-gradient-1: #297af6;
  --site-card-gradient-2: #4fc8fd;
  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;
  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;
  --site-section-3-gradient-1: #008dfa;
  --site-section-3-gradient-2: #0054f2;
  --site-section-4-gradient-1: #297af6;
  --site-section-4-gradient-2: #4fc8fd;
  --site-public-gradient-1: rgba(0, 144, 255, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);
  --site-oval-challenge-list: #ac91f6;
  --site-logo-1: rgba(0, 84, 242, 0.9);
  --site-logo-2: rgba(255, 91, 27, 0.9);
  --site-logo-3: rgba(153, 206, 37, 0.9);
  --site-logo-4: rgba(238, 183, 53, 0.9);
}
.ecni-theme-site.light-theme-mode {
  --site-theme-main-two: #dbe8ff;
  --site-theme-main-three: #f7f9ff;
  --site-theme-main-four: #ebf3fe;
  --site-theme-main-five: #f6f9fe;
  --site-theme-accent: var(--site-main-one);
}
.ecni-theme-site.dark-theme-mode {
  --site-theme-main-two: #000a24;
  --site-theme-main-three: #000308;
  --site-theme-main-four: #010b14;
  --site-theme-main-five: #010409;
  --site-theme-accent: var(--app-white);
}

.prepssm-theme-site {
  --site-main-one: #0ec164;
  --site-main-two: rgb(239, 251, 245);
  --site-main-five: #f2fffd;
  --site-main-six: rgba(14, 193, 100, 0.08);
  --site-light-card: #17d071;
  --site-dark-card: #006139;
  --site-card-gradient-1: #0cc6bf;
  --site-card-gradient-2: #49dc50;
  --site-section-gradient-1: #49dc50;
  --site-section-gradient-2: #0cc6bf;
  --site-section-2-gradient-1: #847af2;
  --site-section-2-gradient-2: #dc97be;
  --site-section-3-gradient-1: #0ec164;
  --site-section-3-gradient-2: #03d1bd;
  --site-section-4-gradient-1: #0cc6bf;
  --site-section-4-gradient-2: #49dc50;
  --site-public-gradient-1: rgba(38, 199, 115, 0.09);
  --site-public-gradient-2: rgba(255, 255, 255, 0.24);
  --site-oval-challenge-list: #ac91f6;
  --site-logo-1: rgba(14, 193, 100, 0.9);
  --site-logo-2: rgba(74, 233, 150, 0.9);
  --site-logo-3: rgba(14, 193, 100, 0.9);
  --site-logo-4: rgba(255, 91, 27, 0.9);
}
.prepssm-theme-site.light-theme-mode {
  --site-theme-main-two: #d7f2e6;
  --site-theme-main-three: #f3fff3;
  --site-theme-main-four: #dcffeb;
  --site-theme-main-five: #f2fffd;
  --site-theme-accent: var(--site-main-one);
}
.prepssm-theme-site.dark-theme-mode {
  --site-theme-main-two: #22564b;
  --site-theme-main-three: #030c00;
  --site-theme-main-four: #032300;
  --site-theme-main-five: #000d0b;
  --site-theme-accent: var(--app-white);
}

.prepmir-theme-site {
  --site-main-one: #d32030;
  --site-main-two: rgb(255, 251, 238);
  --site-main-five: #fffcf2;
  --site-main-six: rgba(248, 251, 43, 0.08);
  --site-light-card: #ffd135;
  --site-dark-card: #d31d2d;
  --site-card-gradient-1: #ffac56;
  --site-card-gradient-2: #ffd135;
  --site-section-gradient-1: #ffd135;
  --site-section-gradient-2: #ffac56;
  --site-section-2-gradient-1: #ffac56;
  --site-section-2-gradient-2: #ffd135;
  --site-section-3-gradient-1: #cf7a22;
  --site-section-3-gradient-2: #ffd135;
  --site-section-4-gradient-1: #f6aa36;
  --site-section-4-gradient-2: #ed7f0b;
  --site-public-gradient-1: #fff7dc;
  --site-public-gradient-2: #ffffff;
  --site-oval-challenge-list: #ef9545;
  --site-logo-1: rgba(211, 32, 48, 0.9);
  --site-logo-2: rgba(255, 209, 53, 0.9);
  --site-logo-3: rgba(211, 32, 48, 0.9);
  --site-logo-4: rgba(233, 182, 7, 0.9);
}
.prepmir-theme-site.light-theme-mode {
  --site-theme-main-two: #ffe7ab;
  --site-theme-main-three: #fffef7;
  --site-theme-main-four: #fff4cf;
  --site-theme-main-five: #fffcf2;
  --site-theme-accent: var(--site-main-one);
}
.prepmir-theme-site.dark-theme-mode {
  --site-theme-main-two: #543c00;
  --site-theme-main-three: #080600;
  --site-theme-main-four: #301e00;
  --site-theme-main-five: #0d0a00;
  --site-theme-accent: var(--app-white);
}

.iti__search-input {
  height: 40px;
  padding: 5px;
}

.iti__selected-dial-code {
  font-weight: bold;
  color: var(--site-main-one);
}

@tailwind components;
@tailwind utilities;
@tailwind variants;
section {
  width: 100%;
  @apply z-0;
  position: relative;
}

.content-component.-inSession .logged-area-container {
  padding-top: 0px;
}

.fixed-inSession-logo {
  position: fixed;
  @apply z-widgets;
  top: calc(14px + var(--safe-area-inset-top));
  left: 20px;
}
@media (max-width: 991px) {
  .fixed-inSession-logo {
    display: none;
  }
}

.fixed-inSession-search {
  position: fixed;
  @apply z-widgets;
  top: calc(11px + var(--safe-area-inset-top));
  right: 20px;
}
@media (max-width: 991px) {
  .fixed-inSession-search {
    display: none;
  }
}

.content-with-fixed-widgets {
  padding-top: 69px;
}
@media (max-width: 767px) {
  .content-with-fixed-widgets {
    padding-top: 50px;
  }
}

.container--landing {
  @apply w-[1140px] max-w-full px-6 sm:px-8 mx-auto relative;
}

.top-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @apply z-topNav;
  padding-top: var(--safe-area-inset-top);
}

.navbar-scrolltop {
  flex-grow: 1;
  align-self: stretch;
  cursor: pointer;
}

.navbar-hamburger {
  cursor: pointer;
  color: var(--site-main-one);
}
@media (min-width: 992px) {
  .navbar-hamburger {
    display: none;
  }
}

.navbar-search {
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: 540px;
  border-radius: 16px;
  border: 1px solid var(--theme-gray-seven);
  box-shadow: 0px 4px 10px rgba(46, 48, 64, 0.2);
}
@media (max-width: 991px) {
  .navbar-search {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: calc(theme("zIndex.topNav") - 1);
    background: var(--theme-white);
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    @apply transition-spacing top-navbarSmallHeight;
    border-radius: 0;
    box-shadow: none;
    border: 0;
  }
  .navbar-search.-active {
    height: calc(var(--safe-area-inset-top) + 60px);
    padding-top: calc(var(--safe-area-inset-top) + 20px);
    padding-bottom: 20px;
  }
}
.navbar-search .form-search {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 10px 2px 10px 20px;
  border-radius: 16px;
}
@media (max-width: 991px) {
  .navbar-search .form-search {
    width: 100%;
    border-radius: 40px;
  }
}
.navbar-search .form-search.-searchEntered {
  background: var(--site-main-six);
}
.navbar-search .form-search.-searchEntered .search-submit {
  display: block;
}
.navbar-search .form-search .search-icon {
  border: 0;
  margin-right: 15px;
  color: var(--site-main-one);
}
.navbar-search .form-search .form-input {
  background: transparent;
  border: 0;
  font-family: Muli;
  font-size: 1.4rem;
  width: 100%;
  margin-right: 0;
}
.navbar-search .form-search .form-input::-webkit-input-placeholder, .navbar-search .form-search .form-input:-ms-input-placeholder, .navbar-search .form-search .form-input::-ms-input-placeholder {
  color: var(--theme-gray-twentysix);
}
.navbar-search .form-search .form-input:-moz-placeholder, .navbar-search .form-search .form-input::-moz-placeholder {
  color: 9ea0a5;
  opacity: 0.7;
}
@media (max-width: 991px) {
  .navbar-search .form-search .form-input {
    color: var(--theme-black);
    margin-right: 0;
    width: 100%;
  }
  .navbar-search .form-search .form-input::-webkit-input-placeholder, .navbar-search .form-search .form-input:-ms-input-placeholder, .navbar-search .form-search .form-input::-ms-input-placeholder {
    color: var(--theme-gray-twentyfour);
  }
  .navbar-search .form-search .form-input:-moz-placeholder, .navbar-search .form-search .form-input::-moz-placeholder {
    color: var(--theme-black);
    opacity: 0.7;
  }
}
.navbar-search .form-search .search-submit {
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
  display: none;
}

.module-badge {
  white-space: nowrap;
  overflow: hidden;
  color: white;
  font-size: 9px;
  line-height: 17px;
  text-align: center;
  width: 16px;
  height: 16px;
}
@media (max-width: 991px) {
  .module-badge {
    line-height: 20px;
    width: 19px;
    height: 19px;
    font-size: 10px;
  }
}
.module-badge .background {
  background: var(--site-main-one);
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: heartbeat 3s ease-in-out infinite both;
  animation: heartbeat 3s ease-in-out infinite both;
}
.module-badge .content {
  position: relative;
}
.module-badge .content.big-number {
  font-size: 7px;
  top: -1px;
}

.mat-sidenav-fixed {
  padding-top: var(--safe-area-inset-top) !important;
  padding-bottom: var(--safe-area-inset-bottom) !important;
  overflow: visible !important;
}
.mat-sidenav-fixed .mat-drawer-inner-container {
  overflow: visible !important;
}

.navbar-user {
  z-index: calc(theme("zIndex.topNav") - 1);
  position: relative;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .navbar-user {
    display: none;
  }
}
.navbar-user .user-menu-toggle {
  cursor: pointer;
  max-width: 180px;
  display: flex;
  align-items: center;
  @apply space-x-8;
}
.navbar-user .user-menu-left {
  @apply leading-snug overflow-hidden;
}
.navbar-user .user-username {
  font-size: 1.3rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.navbar-user .user-sub {
  @apply text-site-main-one text-12 font-extrabold;
}
.navbar-user .nnki-rounded-background-image {
  margin-left: 15px;
  width: 40px;
  flex-shrink: 0;
}
.navbar-user .user-menu {
  position: absolute;
  top: 48px;
  right: 0;
  border-radius: 16px;
  box-shadow: 0 4px 10px 0 var(--theme-blue-three);
  background-color: var(--theme-white);
}
.navbar-user .user-menu .user-menu-link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  padding: 15px;
  cursor: pointer;
}
.navbar-user .user-menu .user-menu-link svg-icon {
  margin-right: 15px;
  color: var(--app-gray-twentynine);
}
.navbar-user .user-menu .user-menu-link.-active, .navbar-user .user-menu .user-menu-link:hover {
  color: var(--site-main-one);
}
.navbar-user .user-menu .user-menu-link.-active svg-icon, .navbar-user .user-menu .user-menu-link:hover svg-icon {
  color: var(--site-main-one);
}

.router-container {
  @apply opacity-100 transition-opacity;
}
.router-container.-component-loading {
  @apply opacity-0 transition-none;
}

.logged-area-container {
  @apply pt-navbarHeight min-h-screen overflow-hidden;
}
@media (max-width: 991px) {
  .logged-area-container {
    @apply pt-navbarSmallHeight;
  }
}

.component-container {
  padding: 20px;
}
@media (max-width: 767px) {
  .component-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .component-container.-withMobileButton {
    padding-bottom: calc(theme("spacing.bottombarHeight") + 120px) !important;
  }
}

.inside-container {
  @apply px-4 sm:px-0;
}

.-mobile-device app-logged-bottom-nav {
  display: block;
}
.-mobile-device app-logged-bottom-nav.-sidebarOpened {
  transform: translateY(80px);
  opacity: 0;
  pointer-events: none;
}
.-mobile-device app-logged-top-nav {
  left: 0;
  height: calc(var(--safe-area-inset-top) + theme("spacing.navbarSmallHeight"));
  justify-content: start;
  padding-left: 10px;
  padding-right: 10px;
}
.-mobile-device app-logged-top-nav app-site-logo {
  display: block;
  margin-right: 20px;
}
.-mobile-device app-logged-top-nav .navbar-title {
  margin-right: 20px;
}
.-mobile-device app-logged-top-nav .navbar-search {
  margin-left: 0;
  margin-right: 0;
  max-width: none;
}
.-mobile-device app-logged-top-nav .search-mask {
  display: block;
}
.-mobile-device app-logged-top-nav .navbar-buttons {
  margin-left: auto;
}
.-mobile-device app-logged-top-nav .navbar-buttons .navbar-button {
  margin: 0 8px;
}
.-mobile-device app-logged-top-nav .navbar-buttons .navbar-button.-mobileOnly {
  display: block !important;
}
.-mobile-device app-logged-top-nav .navbar-user {
  display: none;
}
.-mobile-device app-logged-top-nav .navbar-hamburger {
  display: block;
}
@media (min-width: 768px) {
  .-mobile-device app-logged-side-nav nav.sidenav {
    width: 350px !important;
    max-width: 100vw !important;
  }
}
.-mobile-device app-logged-side-nav .sidenav-close {
  display: block !important;
}
.-mobile-device app-logged-side-nav .icon-container {
  justify-content: start !important;
  padding-left: 20px !important;
}
@media (min-width: 992px) {
  .-mobile-device app-logged-side-nav .sidenav_desktop {
    display: none;
  }
}
.-mobile-device app-logged-side-nav .sidenav_mobile {
  display: block !important;
}
.-mobile-device app-logged-side-nav .navbar-user {
  display: block !important;
}
@media (min-width: 992px) {
  .-mobile-device app-logged-side-nav .version-number {
    display: block !important;
  }
}
@media (min-width: 768px) {
  .-mobile-device app-subscription-card,
.-mobile-device .sidenav_premium_prolonger,
.-mobile-device .sidenav_premium_convert {
    display: none !important;
  }
}
@media (min-width: 768px) {
  .-mobile-device .sidenav_premium_mobile {
    display: flex !important;
  }
}
@media (min-width: 768px) {
  .-mobile-device .screen_height, .-mobile-device .card--splitted .card_right, .card--splitted .-mobile-device .card_right, .-mobile-device .card--splitted .card_left, .card--splitted .-mobile-device .card_left {
    height: calc(100vh - 215px);
  }
}
.-mobile-device .navbar-search {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: calc(theme("zIndex.topNav") - 1);
  background: var(--theme-white);
  height: 0;
  overflow: hidden;
  padding: 0 20px;
  @apply transition-spacing top-navbarSmallHeight;
  border-radius: 0;
  box-shadow: none;
  border: 0;
}
.-mobile-device .navbar-search.-active {
  height: calc(var(--safe-area-inset-top) + 60px);
  padding-top: calc(var(--safe-area-inset-top) + 20px);
  padding-bottom: 20px;
}
.-mobile-device .navbar-search .form-search {
  width: 100%;
  border-radius: 40px;
}
.-mobile-device .navbar-search .form-search .form-input {
  color: var(--theme-black);
  margin-right: 0;
  width: 100%;
}
.-mobile-device .navbar-search .form-search .form-input::-webkit-input-placeholder, .-mobile-device .navbar-search .form-search .form-input:-ms-input-placeholder, .-mobile-device .navbar-search .form-search .form-input::-ms-input-placeholder {
  color: var(--theme-gray-twentyfour);
}
.-mobile-device .navbar-search .form-search .form-input:-moz-placeholder, .-mobile-device .navbar-search .form-search .form-input::-moz-placeholder {
  color: var(--theme-black);
  opacity: 0.7;
}
.-mobile-device .module-badge {
  line-height: 20px;
  width: 19px;
  height: 19px;
  font-size: 10px;
}
.-mobile-device .logged-area-container {
  @apply pt-navbarSmallHeight;
}
.-mobile-device .component-container {
  @apply pb-bottombarHeight;
}
.-mobile-device .fixed-inSession-logo {
  display: none;
}
@screen sm {
  .-mobile-device .filters-panel {
    max-height: calc(100vh - 90px - var(--safe-area-inset-bottom) - var(--safe-area-inset-top) - 60px) !important;
  }
}
.-mobile-device .tabs-group-nnki.tabs-hidden-on-desktop,
.-mobile-device .tabs-nnki.tabs-hidden-on-desktop {
  visibility: visible !important;
}
.-mobile-device .tabs-group-nnki.tabs-hidden-on-desktop .mat-ink-bar,
.-mobile-device .tabs-nnki.tabs-hidden-on-desktop .mat-ink-bar {
  visibility: visible !important;
}

body.-mobile-browser .modal-inside:after {
  content: "";
  height: 100px;
}
body.-mobile-browser .content-question-challenge.-in-candidate {
  @apply pb-[130px];
}
body.-mobile-browser .end-challenge-modal-middle {
  @apply pb-36;
}

.admin-list {
  @apply max-w-full border border-site-theme-main-two overflow-hidden grid gap-4 items-center;
}
.admin-list .admin-list-element {
  @apply p-4 border-b border-site-theme-main-two grid grid-cols-subgrid items-center;
}
.admin-list .admin-list-element:last-child {
  @apply border-none;
}
.admin-list .admin-list-element > * {
  @apply truncate;
}

.admin-expandable-table tr.detail-row {
  height: 0;
}
.admin-expandable-table tr.expanding-row:not(.expanded-row):hover {
  background: whitesmoke;
}
.admin-expandable-table tr.expanding-row:not(.expanded-row):active {
  background: #efefef;
}
.admin-expandable-table .expanding-row td {
  border-bottom-width: 0;
}
.admin-expandable-table .expanded-detail {
  overflow: hidden;
}
.admin-expandable-table .expanded-detail app-writer-list {
  @apply block my-4;
}
.admin-expandable-table .expanded-detail app-writer-list .mat-cell {
  @apply bg-site-theme-main-five;
}

.card-v2 {
  @apply shadow rounded-card w-full h-full flex flex-col;
}

.card--settings {
  @apply py-8 px-4 sm:px-8;
}
.card--settings .card__header {
  @apply text-19 font-bold mb-8 sm:mb-12;
}

.card {
  @apply shadow;
  @apply rounded-card;
  margin-bottom: 20px;
  width: 100%;
}

.card-picto {
  border-radius: 50%;
  background: var(--theme-gray-three);
  width: 58px;
  height: 58px;
  margin-right: 16px;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .card-picto {
    margin-right: 13px;
  }
}
.card-picto > app-image-content img,
.card-picto > img,
.card-picto > .background-picto,
.card-picto > .dummy-rectangle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}
.card-picto .background-picto {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--app-white);
}
.card-picto .background-picto.-color-black {
  background-image: linear-gradient(-90deg, var(--app-gray-one) 0%, var(--app-black) 100%);
}
.card-picto .background-picto.-color-purple {
  background-image: linear-gradient(-90deg, var(--site-section-2-gradient-1) 0%, var(--site-section-2-gradient-2) 100%);
}
.card-picto .background-picto.-color-green {
  background-image: linear-gradient(-90deg, var(--site-section-gradient-2) 0%, var(--site-section-gradient-1) 100%);
}
.card-picto .background-picto.-color-yellow {
  background-color: var(--app-yellow-one);
}
.card-picto .background-picto.-color-blue {
  background-image: linear-gradient(241.62deg, var(--site-card-gradient-2) 0%, var(--site-card-gradient-1) 100%);
}

.card-footer {
  background: var(--theme-white);
  border-top: 1px solid var(--theme-gray-eleven);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @apply rounded-b-card;
}

.card-circular-buttons {
  display: flex;
  align-items: center;
}
.card-circular-buttons .btn-nnki-circular,
.card-circular-buttons app-loader-dots {
  margin-right: 10px;
}
.card-circular-buttons .btn-nnki-circular:last-child,
.card-circular-buttons app-loader-dots:last-child {
  margin-right: 0;
}

.card-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  height: 42px;
}

.card--progress {
  padding: 20px 30px 30px 30px;
  flex-grow: 1;
  background: var(--theme-white);
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .card--progress {
    padding: 15px;
  }
}
.card--progress .card-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  font-weight: 800;
}
@media (max-width: 1199px) {
  .card--progress .card-header {
    font-size: 1.8rem;
  }
}
.card--progress .card-header svg-icon {
  width: 40px;
  margin-right: 15px;
  color: var(--theme-gray-sixteen);
}
@media (max-width: 1199px) {
  .card--progress .card-header svg-icon {
    margin-right: 10px;
  }
  .card--progress .card-header svg-icon svg {
    width: 25px;
  }
}
.card--progress .card-header .nb_questions {
  margin-left: auto;
  padding-left: 10px;
  font-size: 1.6rem;
  font-weight: 600;
  white-space: nowrap;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .card--progress .card-header .nb_questions {
    font-size: 1.4rem;
  }
}
@media (max-width: 767px) {
  .card--progress .card-header .nb_questions {
    font-size: 1.4rem;
  }
}
.card--progress .card-header .nb_questions strong {
  font-size: 2rem;
  font-weight: 800;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .card--progress .card-header .nb_questions strong {
    font-size: 1.8rem;
  }
}
@media (max-width: 767px) {
  .card--progress .card-header .nb_questions strong {
    font-size: 1.6rem;
  }
}
.card--progress .card-content {
  margin-top: 20px;
  flex-grow: 1;
}
.card--progress .card-content.-grey {
  background: var(--site-theme-main-five);
  padding: 20px;
}
@media (max-width: 1199px) {
  .card--progress .card-content.-grey {
    padding: 20px 10px;
  }
}
.card--progress .card-content.-multiGraph {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .card--progress .card-content.-multiGraph {
    flex-wrap: wrap;
  }
}
.card--progress .card-content.-multiGraph .graph_legend {
  width: 30%;
  flex-shrink: 0;
  padding-left: 60px;
}
@media (max-width: 991px) {
  .card--progress .card-content.-multiGraph .graph_legend {
    padding-left: 30px;
  }
}
@media (max-width: 767px) {
  .card--progress .card-content.-multiGraph .graph_legend {
    width: 100%;
    order: 2;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
  }
}
.card--progress .card-content.-multiGraph .graph_legend .legend {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .card--progress .card-content.-multiGraph .graph_legend .legend {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.card--progress .card-content.-multiGraph .graph_legend .legend .color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 25px;
  flex-shrink: 0;
}
.card--progress .card-content.-multiGraph .graph_legend .legend .color.-blue {
  background: var(--app-blue-seven);
}
.card--progress .card-content.-multiGraph .graph_legend .legend .color.-green {
  background: var(--app-green-three);
}
.card--progress .card-content.-multiGraph .graph_legend .legend .color.-orange {
  background: var(--app-orange-two);
}
.card--progress .card-content.-multiGraph .graph_legend .legend .color_label {
  font-weight: 800;
  font-size: 1.6rem;
}
.card--progress .card-content.-multiGraph .graph-inner {
  width: 100%;
  min-width: 0;
}
.card--progress .with-progress {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.card--progress .with-progress.-small-margin {
  margin-bottom: 10px;
}
.card--progress .with-progress:last-child {
  margin-bottom: 0;
}
.card--progress .with-progress .progress-label {
  font-size: 1.6rem;
  font-weight: 600;
  width: 120px;
  flex-shrink: 0;
  text-align: right;
}
@media (max-width: 767px) {
  .card--progress .with-progress .progress-label {
    width: 105px;
    font-size: 1.4rem;
  }
}
.card--progress .with-progress .progress-label strong {
  font-weight: 800;
}
.card--progress .with-progress .progress-label svg-icon {
  color: var(--site-main-one);
  margin-left: 5px;
}
.card--progress .with-progress .progress-value {
  font-size: 1.6rem;
  font-weight: 800;
  flex-shrink: 0;
  width: 70px;
}
.card--progress .with-progress .progress-value.-small {
  font-size: 1.4rem;
  font-weight: 600;
}
.card--progress .with-progress .progress-value.-small strong {
  font-size: 1.6rem;
  font-weight: bold;
}
.card--progress .with-progress .ecni_progress_bar {
  flex-grow: 1;
  margin: 0 20px;
}
@media (max-width: 1199px) {
  .card--progress .with-progress .ecni_progress_bar {
    width: 35%;
    margin: 0 10px;
  }
}
.card--progress .graph_questions .questions_stats {
  flex-grow: 1;
  display: grid;
  grid-template-columns: fit-content(200px) 30px auto;
  grid-column-gap: 20px;
  grid-row-gap: 15px;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
}
@media (max-width: 767px) {
  .card--progress .graph_questions .questions_stats .stat_label {
    font-size: 1.2rem;
  }
}
.card--progress .graph_questions .questions_stats .stat_color {
  width: 30px;
  height: 6px;
  border-radius: 3px;
}
.card--progress .graph_questions .questions_stats .stat_color.-green {
  background: var(--app-green-three);
}
.card--progress .graph_questions .questions_stats .stat_color.-yellow {
  background: var(--app-orange-two);
}
.card--progress .graph_questions .questions_stats .stat_color.-red {
  background: var(--app-red-one);
}
.card--progress .graph_questions .questions_stats .stat_value {
  color: var(--site-main-one);
}
.card--progress .card-footer {
  border: 0;
  padding: 0;
  margin-top: 20px;
  background: transparent;
}

.card--picto-corner .card-header {
  display: flex;
  align-items: flex-start;
  height: 113px;
  position: relative;
  @apply z-1;
  cursor: pointer;
  background-color: var(--site-theme-main-five);
  padding: 20px;
  color: var(--theme-black);
  @apply rounded-t-card;
}
@media (max-width: 767px) {
  .card--picto-corner .card-header {
    padding: 15px 10px;
    height: 103px;
  }
}
.card--picto-corner .card-header.ecos {
  height: 130px;
}
@media (max-width: 767px) {
  .card--picto-corner .card-header.ecos {
    height: 130px;
  }
}
.card--picto-corner .card-header-content {
  flex-grow: 1;
  position: relative;
  flex-basis: 0;
  overflow: hidden;
}
.card--picto-corner .card-title {
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--site-main-one);
  margin-bottom: 5px;
}
.card--picto-corner .card-title.-with-status {
  padding-right: 120px;
}
.card--picto-corner .card-title p {
  @apply truncate;
}
.card--picto-corner .card-theme {
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--theme-black);
}
@media (max-width: 767px) {
  .card--picto-corner .card-theme {
    font-size: 1.5rem;
  }
}
.card--picto-corner .card-theme p {
  @apply line-clamp-2;
}
.card--picto-corner .card-ecos-theme {
  font-size: 1.4rem;
  color: var(--theme-black);
  font-weight: 300;
  margin-top: 5px;
}
.card--picto-corner .card-ecos-theme p {
  @apply line-clamp-2;
}
.card--picto-corner .card-number {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 5px;
}
.card--picto-corner .card-number > svg-icon {
  color: var(--site-main-one);
  margin-right: 5px;
}
.card--picto-corner .card-status {
  position: absolute;
  right: 20px;
  top: 16px;
  background: var(--theme-white);
  padding: 5px 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  @apply leading-none;
}
@media (max-width: 767px) {
  .card--picto-corner .card-status {
    top: 12px;
    right: 10px;
  }
}
.card--picto-corner .card-status .status-txt {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 800;
  color: var(--theme-black);
}
.card--picto-corner .card-status .status-badge {
  margin-left: 5px;
  flex-shrink: 0;
  width: 13px;
  height: 13px;
  border-radius: 50%;
}
.card--picto-corner .card-status .status-badge.badge-orange {
  background: var(--app-orange-three);
}
.card--picto-corner .card-status .status-badge.badge-red {
  background: var(--app-red-one);
}
.card--picto-corner .card-infos {
  position: absolute;
  bottom: -12px;
  left: 10px;
  display: flex;
}
.card--picto-corner .card-infos .card-info {
  display: flex;
  align-items: center;
  background: var(--theme-white);
  padding: 5px 8px;
  border-radius: 3px;
  margin-right: 7px;
  @apply whitespace-nowrap truncate;
}
.card--picto-corner .card-infos .card-info:last-child {
  margin-right: 0;
}
.card--picto-corner .card-infos .card-info .info-icon {
  margin-right: 4px;
  color: var(--site-main-one);
}
.card--picto-corner .card-infos .card-info .info-txt {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--theme-black);
}
.card--picto-corner .card-content {
  background: var(--theme-white);
  padding: 30px 20px 20px 20px;
  flex-grow: 1;
}
@media (max-width: 767px) {
  .card--picto-corner .card-content {
    padding: 10px 20px;
  }
}
@media (min-width: 768px) {
  .card--picto-corner .card-content.-inSwiper {
    height: 94px;
  }
}
.card--picto-corner .card-content.-training {
  @apply rounded-b-card;
}
@media (max-width: 767px) {
  .card--picto-corner .card-content.-training {
    padding-top: 20px;
  }
}
.card--picto-corner .card-details {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.card--picto-corner .card-details .card-detail {
  margin-right: 20px;
  display: flex;
  align-items: center;
  @apply truncate;
}
.card--picto-corner .card-details .card-detail > svg-icon {
  color: var(--site-main-one);
  margin-right: 4px;
}
.card--picto-corner .card-details .card-detail .detail-txt {
  color: var(--theme-black);
  font-size: 1.2rem;
  font-weight: bold;
  @apply truncate;
}
.card--picto-corner .card-details .card-detail:last-child {
  margin-right: 0;
}
.card--picto-corner .training-card-xs-label {
  margin-bottom: 10px;
}
.card--picto-corner .training-frame {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .card--picto-corner .training-frame {
    flex-grow: 1;
  }
}
.card--picto-corner .card-training-duration svg-icon {
  margin-right: 10px;
  color: var(--site-main-one);
}
.card--picto-corner .card-footer {
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (max-width: 767px) {
  .card--picto-corner .card-footer {
    border-top: 0;
  }
}

.card--nnki-question {
  border: 0;
}
.card--nnki-question > *:first-child {
  @apply rounded-t-card;
}
.card--nnki-question > *:last-child {
  @apply rounded-b-card;
}
@media (max-width: 767px) {
  .card--nnki-question.-footerFixed {
    margin-bottom: 80px;
  }
}
@media (max-width: 767px) {
  .card--nnki-question.-footerFixed .card-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    @apply z-buttonsFixed;
    padding: 10px 10px calc(var(--safe-area-inset-bottom) + 10px) 10px;
  }
}
.card--nnki-question.-footerFixed .card-footer .card-buttons {
  justify-content: space-between;
}
@media (max-width: 767px) {
  .card--nnki-question.-footerFixed .card-footer .card-buttons {
    flex-wrap: nowrap;
  }
  .card--nnki-question.-footerFixed .card-footer .card-buttons .btn-nnki,
.card--nnki-question.-footerFixed .card-footer .card-buttons .spinner_button_container {
    flex-grow: 1;
    margin-bottom: 0;
  }
}
@media (max-width: 400px) {
  .card--nnki-question.-footerFixed .card-footer .card-buttons svg-icon {
    display: none;
  }
}
.card--nnki-question.-time-lapsed .question-images {
  opacity: 0.5;
}
.card--nnki-question.-time-lapsed textarea {
  opacity: 0.5;
}
.card--nnki-question .card-header {
  padding-left: 20px;
  padding-right: 20px;
  background: var(--theme-white);
  border-bottom: 1px solid var(--app-blue-six);
  @apply rounded-t-card;
}
.card--nnki-question .card-header .card-header-row {
  min-height: 56px;
  @apply flex items-center justify-items-start;
}
.card--nnki-question .card-header .question-title {
  text-transform: capitalize;
  font-size: 1.5rem;
  font-weight: 800;
}
.card--nnki-question .card-header .question-type {
  color: var(--app-gray-six);
}
.card--nnki-question .card-header .question-users {
  display: flex;
}
.card--nnki-question .card-header .question-users svg-icon {
  color: var(--site-main-one);
  margin-right: 5px;
}
.card--nnki-question .card-header .question-users .question-users-element {
  white-space: nowrap;
  font-size: 1.4rem;
  font-weight: 800;
  margin-right: 10px;
  display: flex;
  align-items: center;
  background-color: var(--site-theme-main-five);
  padding: 7px 10px;
  border-radius: 3px;
}
.card--nnki-question .card-header .question-users .question-users-element.-big {
  font-size: 1.6rem;
}
.card--nnki-question .card-header .question-users .question-users-element:last-child {
  margin-right: 0;
}
.card--nnki-question .card-folder-correction {
  display: flex;
  align-items: flex-start;
  background: var(--theme-white);
  padding: 20px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction {
    flex-wrap: wrap;
    padding: 10px 0;
  }
}
.card--nnki-question .card-folder-correction .icon-folder {
  margin-right: 20px;
  color: var(--site-main-one);
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .icon-folder {
    padding-left: 10px;
  }
}
.card--nnki-question .card-folder-correction .folder-details {
  overflow: hidden;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .folder-details {
    width: calc(100% - 54px);
    margin-right: 0;
    overflow: visible;
  }
}
.card--nnki-question .card-folder-correction .folder-details .last-update {
  color: var(--site-main-one);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 5px;
}
.card--nnki-question .card-folder-correction .folder-details .folder-title {
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 10px;
  @apply truncate;
  padding-bottom: 1px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .folder-details .folder-title {
    white-space: normal;
    padding-right: 10px;
  }
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos {
  background: var(--site-theme-main-five);
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 5px 10px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .folder-details .folder-infos {
    margin-left: -54px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: calc(100% + 54px);
  }
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos .folder-info {
  display: flex;
  align-items: center;
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos .folder-info svg-icon {
  margin-right: 10px;
  color: var(--site-main-one);
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos .folder-info .info-txt {
  font-size: 1.6rem;
  font-weight: 800;
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos .folder-info .info-txt.-small {
  font-size: 1.4rem;
}
.card--nnki-question .card-folder-correction .folder-details .folder-infos .header-info-separator {
  background: var(--theme-black);
  opacity: 0.26;
  height: 17px;
  width: 1px;
  margin: 0 15px;
}
.card--nnki-question .card-folder-correction .folder-grade {
  flex-shrink: 0;
  margin-left: auto;
  background: var(--site-theme-main-five);
  padding: 10px 20px;
  border-radius: 3px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .folder-grade {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.card--nnki-question .card-folder-correction .folder-grade .rate-title {
  color: var(--site-main-one);
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-folder-correction .folder-grade .rate-title {
    margin-bottom: 0;
  }
}
.card--nnki-question .card-content {
  background-color: var(--site-theme-main-five);
  padding: 20px;
  position: relative;
}
.card--nnki-question .card-content.-white, .card--nnki-question .card-content.-correction {
  background: var(--theme-white);
}
.card--nnki-question .card-content.-correction .content-question .question-description {
  font-weight: 500;
}
.card--nnki-question .card-content .card-content-inside {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 767px) {
  .card--nnki-question .card-content .card-content-inside {
    flex-wrap: wrap;
  }
}
.card--nnki-question .card-content .card-content-title {
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 20px;
}
.card--nnki-question .card-content .card-content-line {
  @apply truncate;
  display: block;
  margin-bottom: 10px;
}
.card--nnki-question .card-content .card-content-line:last-child {
  margin-bottom: 0;
}
.card--nnki-question .card-content .card-content-line strong {
  color: var(--site-main-one);
}
.card--nnki-question .content-question {
  flex-grow: 1;
}
@media (max-width: 767px) {
  .card--nnki-question .content-question {
    order: 2;
  }
}
.card--nnki-question .content-question .question-description {
  font-family: Avenir;
  font-size: 1.5rem;
  font-weight: 600;
  word-break: break-word;
  @apply first-letter:uppercase;
}
.card--nnki-question .content-question .question-description p {
  margin-bottom: 10px;
}
.card--nnki-question .content-question .question-description a {
  color: var(--site-main-one);
  text-decoration: underline;
  pointer-events: all;
  word-break: break-all;
}
.card--nnki-question .content-question .question-description ul {
  margin-left: 30px;
}
.card--nnki-question .content-question .question-description ul li {
  margin: 5px 0;
  position: relative;
}
.card--nnki-question .content-question .question-description ul li:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--app-green-three);
  position: absolute;
  top: 5px;
  left: -12px;
}
.card--nnki-question .content-question .question-description table {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid var(--theme-gray-three);
  border-right: 0;
  border-left: 0;
}
.card--nnki-question .content-question .question-description table > thead > tr > th,
.card--nnki-question .content-question .question-description table > thead > tr > td,
.card--nnki-question .content-question .question-description table > tbody > tr > th,
.card--nnki-question .content-question .question-description table > tbody > tr > td,
.card--nnki-question .content-question .question-description table > tfoot > tr > th,
.card--nnki-question .content-question .question-description table > tfoot > tr > td {
  border: 1px solid var(--theme-gray-three);
  min-width: 40px;
}
.card--nnki-question .content-question .question-description table > thead > tr > th:first-child,
.card--nnki-question .content-question .question-description table > thead > tr > td:first-child,
.card--nnki-question .content-question .question-description table > tbody > tr > th:first-child,
.card--nnki-question .content-question .question-description table > tbody > tr > td:first-child,
.card--nnki-question .content-question .question-description table > tfoot > tr > th:first-child,
.card--nnki-question .content-question .question-description table > tfoot > tr > td:first-child {
  border-left: 0;
}
.card--nnki-question .content-question .question-description table > thead > tr > th:last-child,
.card--nnki-question .content-question .question-description table > thead > tr > td:last-child,
.card--nnki-question .content-question .question-description table > tbody > tr > th:last-child,
.card--nnki-question .content-question .question-description table > tbody > tr > td:last-child,
.card--nnki-question .content-question .question-description table > tfoot > tr > th:last-child,
.card--nnki-question .content-question .question-description table > tfoot > tr > td:last-child {
  border-right: 0;
}
.card--nnki-question .content-question .question-description table > thead > tr > th,
.card--nnki-question .content-question .question-description table > thead > tr > td {
  border-bottom-width: 2px;
}
.card--nnki-question .content-question .question-description table > tbody > tr:nth-of-type(even) {
  background-color: var(--site-theme-main-three);
}
.card--nnki-question .content-question iframe {
  width: 100%;
}
.card--nnki-question .card-buttons {
  justify-content: space-between;
}
.card--nnki-question .card-buttons .btn-nnki,
.card--nnki-question .card-buttons .spinner_button_container {
  margin: 0 20px 0 0;
  flex-grow: 1;
  max-width: 300px;
}
@media (max-width: 767px) {
  .card--nnki-question .card-buttons .btn-nnki,
.card--nnki-question .card-buttons .spinner_button_container {
    max-width: none;
    margin-right: 10px;
  }
}
.card--nnki-question .card-buttons .btn-nnki:last-child,
.card--nnki-question .card-buttons .spinner_button_container:last-child {
  margin-right: 0;
}

.card--info {
  @apply shadow rounded-card;
  background-color: var(--theme-white);
  overflow: hidden;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .card--info {
    margin-bottom: 0;
    box-shadow: none;
  }
}
.card--info .card-header {
  padding: 0 20px;
  height: 54px;
  background-color: var(--site-theme-main-five);
  display: flex;
  align-items: center;
  color: var(--site-main-one);
}
.card--info .card-header > svg-icon {
  margin-right: 10px;
}
.card--info .card-header .card-title {
  font-size: 1.6rem;
  font-weight: bold;
}
.card--info .card-content {
  padding: 20px;
}
.card--info .card-content .card-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1.4rem;
  white-space: nowrap;
}
.card--info .card-content .card-row .label {
  font-weight: bold;
  margin-right: 15px;
}
.card--info .card-content .card-row svg-icon {
  margin-right: 10px;
  color: var(--site-main-one);
}
.card--info .card-content .card-row .value {
  font-size: 1.5rem;
  font-weight: 600;
}
.card--info .card-content .scores {
  display: flex;
  flex-wrap: wrap;
}
.card--info .card-content .scores .score {
  width: 50%;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .card--info .card-content .scores .score {
    width: 25%;
  }
}
.card--info .card-content .scores .score strong {
  line-height: 110%;
}
.card--info .card-content .score {
  font-weight: 800;
  font-size: 1.4rem;
}
.card--info .card-content .score strong {
  font-size: 2.6rem;
}
.card--info .card-content .score small {
  font-size: 1.4rem;
  font-weight: 600;
}

.card--login {
  @apply sm:w-[450px] max-w-[450px] flex flex-col items-stretch;
}
.card--login .card--login__title {
  @apply font-landing text-41 sm:text-65 font-bold mb-16 sm:mb-12 sm:whitespace-nowrap sm:w-max text-site-main-one;
}
.card--login .card--login__title:first-line,
.card--login .card--login__title > div:first-line {
  @apply text-theme-black;
}
.card--login .card--login__footer {
  @apply mt-20 text-13 font-bold;
}
.card--login .card--login__link {
  @apply font-bold text-13 cursor-pointer text-site-main-one;
}
.card--login .card--login__buttons {
  @apply max-w-[210px] sm:max-w-none;
}
.card--login .card--login__stepper {
  @apply w-full mt-4 sm:mt-8 flex items-center text-theme-black;
}
.card--login .card--login__stepper .ecni_progress_bar {
  @apply w-auto grow;
}
.card--login .card--login__stepper .card--login__stepper-label {
  @apply whitespace-nowrap ml-6 text-20 font-extrabold;
}

.question-description {
  font-family: Avenir;
  font-size: 1.5rem;
  font-weight: 600;
}
.question-description p {
  margin-bottom: 10px;
}
.question-description a {
  color: var(--site-main-one);
  text-decoration: underline;
  pointer-events: all;
  word-break: break-all;
}
.question-description ul {
  margin-left: 30px;
}
.question-description ul li {
  margin: 5px 0;
  position: relative;
}
.question-description ul li:before {
  content: "";
  display: block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: var(--app-green-three);
  position: absolute;
  top: 5px;
  left: -12px;
}
.question-description table {
  border-radius: 0;
  box-shadow: none;
  border: 1px solid var(--theme-gray-three);
  border-right: 0;
  border-left: 0;
}
.question-description table > thead > tr > th,
.question-description table > thead > tr > td,
.question-description table > tbody > tr > th,
.question-description table > tbody > tr > td,
.question-description table > tfoot > tr > th,
.question-description table > tfoot > tr > td {
  border: 1px solid var(--theme-gray-three);
  min-width: 40px;
}
.question-description table > thead > tr > th:first-child,
.question-description table > thead > tr > td:first-child,
.question-description table > tbody > tr > th:first-child,
.question-description table > tbody > tr > td:first-child,
.question-description table > tfoot > tr > th:first-child,
.question-description table > tfoot > tr > td:first-child {
  border-left: 0;
}
.question-description table > thead > tr > th:last-child,
.question-description table > thead > tr > td:last-child,
.question-description table > tbody > tr > th:last-child,
.question-description table > tbody > tr > td:last-child,
.question-description table > tfoot > tr > th:last-child,
.question-description table > tfoot > tr > td:last-child {
  border-right: 0;
}
.question-description table > thead > tr > th,
.question-description table > thead > tr > td {
  border-bottom-width: 2px;
}
.question-description table > tbody > tr:nth-of-type(even) {
  background-color: var(--site-theme-main-three);
}

.ecni_progress_bar {
  height: 10px;
  width: 150px;
  background: var(--theme-gray-sixteen);
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.ecni_progress_bar.-small {
  height: 5px;
}
.ecni_progress_bar .progress_bar_in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 4px;
  background-repeat: repeat-x;
  @apply transition-width;
}
.ecni_progress_bar .progress_bar_in.-main {
  background-color: var(--site-main-one);
}
.ecni_progress_bar .progress_bar_in.-green {
  background-color: var(--app-green-three);
}
.ecni_progress_bar .progress_bar_in.-green-three {
  background-color: var(--app-green-three);
}
.ecni_progress_bar .progress_bar_in.-red {
  background-color: var(--app-red-one);
}
.ecni_progress_bar .progress_bar_in.-purple {
  background-color: var(--app-purple-one);
}
.ecni_progress_bar .progress_bar_in.-blue {
  background-color: var(--app-blue-one);
}

.toast-container.toast-bottom-right {
  right: 85px;
  bottom: 16px;
  max-width: calc(100% - 95px);
}
@media (max-width: 767px) {
  .toast-container.toast-bottom-right {
    right: 16px;
  }
}
.toast-container .ngx-toastr {
  padding: 15px 35px 15px 50px;
  border-radius: 50px;
  box-shadow: none;
  width: auto;
}
@media (max-width: 767px) {
  .toast-container .ngx-toastr {
    border-radius: 20px;
  }
}
.toast-container .ngx-toastr:hover {
  box-shadow: none;
}
.toast-container .ngx-toastr.toast-success {
  background-color: var(--app-green-three);
  background-image: url(/assets/images/svg-icons/icon-toast-success.svg);
}
.toast-container .ngx-toastr.toast-info {
  background-color: var(--site-main-one);
}
.toast-container .ngx-toastr.toast-warning {
  background-color: var(--app-red-one);
  background-image: url(/assets/images/svg-icons/icon-toast-error.svg);
}

.screen_height, .card--splitted .card_right, .card--splitted .card_left {
  height: calc(100vh - 175px);
  overflow: auto;
}
@media (min-width: 768px) and (max-width: 991px) {
  .screen_height, .card--splitted .card_right, .card--splitted .card_left {
    height: calc(100vh - 215px);
  }
}
@media (max-width: 767px) {
  .screen_height, .card--splitted .card_right, .card--splitted .card_left {
    height: calc(100vh - 195px);
  }
}

.card--splitted {
  display: flex;
  align-items: stretch;
  background: var(--theme-white);
  margin: 0;
  overflow: hidden;
  @apply shadow;
  min-height: 58px;
}
@media (max-width: 767px) {
  .card--splitted {
    background: transparent;
    box-shadow: none;
    border: 0;
    align-items: flex-start;
  }
}
@media (max-width: 767px) {
  .card--splitted.-inDiscussion .card_left {
    transform: translateX(-100%);
  }
}
@media (max-width: 767px) {
  .card--splitted.-inDiscussion .card_right {
    transform: translateX(0);
  }
}
.card--splitted .card_left {
  position: relative;
  width: 300px;
  flex-shrink: 0;
  border-right: 1px solid var(--theme-gray-eight);
  background: var(--theme-white);
}
@media (max-width: 1199px) {
  .card--splitted .card_left {
    width: 240px;
  }
}
@media (max-width: 767px) {
  .card--splitted .card_left {
    border-right: 0;
  }
}
.card--splitted .card_right {
  flex-grow: 1;
  display: flex;
}
@media (max-width: 767px) {
  .card--splitted .card_right {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(100vw);
    height: 100vh;
    z-index: calc(theme("zIndex.tabs") + 1);
    background: var(--theme-white);
    padding-top: var(--safe-area-inset-top);
    padding-bottom: var(--safe-area-inset-top);
  }
}
.card--splitted .card_right > div,
.card--splitted .card_right > form {
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .card--splitted .card_left,
.card--splitted .card_right {
    width: 100vw;
    flex-shrink: 0;
    @apply transition-transform;
  }
}

.card--splitted .feedback-preview,
.feedbacks-list-modal .feedback-preview {
  border-bottom: 1px solid var(--theme-gray-eight);
  background: var(--site-theme-main-five);
  padding: 15px 20px 10px 20px;
  position: relative;
  cursor: pointer;
  border-right: 5px solid transparent;
}
@media (min-width: 768px) {
  .card--splitted .feedback-preview,
.feedbacks-list-modal .feedback-preview {
    min-height: 86px;
  }
}
.card--splitted .feedback-preview.-opened,
.feedbacks-list-modal .feedback-preview.-opened {
  background: var(--theme-white);
  border-right-color: var(--site-main-one);
}
.card--splitted .feedback-preview .feedback-preview-top,
.feedbacks-list-modal .feedback-preview .feedback-preview-top {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 37px;
}
.card--splitted .feedback-preview .feedback-preview-top > .nnki-rounded-background-image,
.card--splitted .feedback-preview .feedback-preview-top > svg-icon,
.feedbacks-list-modal .feedback-preview .feedback-preview-top > .nnki-rounded-background-image,
.feedbacks-list-modal .feedback-preview .feedback-preview-top > svg-icon {
  flex-shrink: 0;
  margin-right: 20px;
}
.card--splitted .feedback-preview .feedback-preview-top > .nnki-rounded-background-image,
.feedbacks-list-modal .feedback-preview .feedback-preview-top > .nnki-rounded-background-image {
  width: 37px;
}
.card--splitted .feedback-preview .feedback-preview-top > svg-icon,
.feedbacks-list-modal .feedback-preview .feedback-preview-top > svg-icon {
  color: var(--site-main-one);
}
.card--splitted .feedback-preview .feedback-preview-infos,
.feedbacks-list-modal .feedback-preview .feedback-preview-infos {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.card--splitted .feedback-preview .feedback-preview-infos .author,
.feedbacks-list-modal .feedback-preview .feedback-preview-infos .author {
  @apply line-clamp-2;
  padding-bottom: 1px;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 140%;
}
.card--splitted .feedback-preview .feedback-preview-infos .author.-withNew,
.feedbacks-list-modal .feedback-preview .feedback-preview-infos .author.-withNew {
  padding-right: 20px;
}
.card--splitted .feedback-preview .feedback-preview-infos .hour,
.feedbacks-list-modal .feedback-preview .feedback-preview-infos .hour {
  flex-shrink: 0;
  margin-left: 10px;
  font-size: 1.2rem;
  color: var(--app-gray-twentynine);
  margin-bottom: auto;
}
.card--splitted .feedback-preview .feedback-preview-text,
.feedbacks-list-modal .feedback-preview .feedback-preview-text {
  font-size: 1.2rem;
  @apply truncate;
  padding-bottom: 1px;
}
.card--splitted .feedback-preview .feedback-notif,
.feedbacks-list-modal .feedback-preview .feedback-notif {
  position: absolute;
  top: 35px;
  right: 15px;
  background: var(--app-green-three);
  border-radius: 2px;
  color: var(--app-white);
  font-size: 1rem;
  font-weight: 800;
  padding: 4px 7px;
}
.card--splitted .feedback_preview-content,
.feedbacks-list-modal .feedback_preview-content {
  margin-left: 15px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  min-width: 0;
}
.card--splitted .feedback_preview-content .content_author-hour,
.feedbacks-list-modal .feedback_preview-content .content_author-hour {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 3px;
}
.card--splitted .feedback_preview-content .content_author-hour .author,
.feedbacks-list-modal .feedback_preview-content .content_author-hour .author {
  color: var(--site-main-one);
  font-weight: bold;
  font-size: 1.4rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 1;
}
.card--splitted .feedback_preview-content .content_author-hour .hour,
.feedbacks-list-modal .feedback_preview-content .content_author-hour .hour {
  font-size: 1.3rem;
  font-family: Avenir;
  white-space: nowrap;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .card--splitted .feedback_preview-content .content_author-hour .hour,
.feedbacks-list-modal .feedback_preview-content .content_author-hour .hour {
    margin-right: -20px;
  }
}
.card--splitted .feedback_header,
.feedbacks-list-modal .feedback_header {
  display: flex;
  align-items: center;
  padding: 20px 25px;
  flex-shrink: 0;
  border-bottom: 1px solid var(--theme-gray-eight);
  min-height: 58px;
}
@media (max-width: 767px) {
  .card--splitted .feedback_header,
.feedbacks-list-modal .feedback_header {
    padding: 10px 15px 10px 15px;
    background: var(--theme-white);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    @apply z-1;
  }
}
.card--splitted .feedback_header .back-to-list,
.feedbacks-list-modal .feedback_header .back-to-list {
  color: var(--site-main-one);
  margin-right: 20px;
  cursor: pointer;
}
.card--splitted .feedback_header .users-pictures-list,
.feedbacks-list-modal .feedback_header .users-pictures-list {
  cursor: pointer;
}
.card--splitted .feedback_header .user-message,
.feedbacks-list-modal .feedback_header .user-message {
  display: flex;
  align-items: center;
  overflow: hidden;
}
.card--splitted .feedback_header .user-message .nnki-rounded-background-image,
.feedbacks-list-modal .feedback_header .user-message .nnki-rounded-background-image {
  width: 37px;
  margin-right: 10px;
  flex-shrink: 0;
}
.card--splitted .feedback_header .user-message .username,
.feedbacks-list-modal .feedback_header .user-message .username {
  font-weight: 600;
  @apply truncate;
}
.card--splitted .feedback_header .open-question,
.feedbacks-list-modal .feedback_header .open-question {
  margin-left: auto;
}
.card--splitted .feedback_discussion,
.feedbacks-list-modal .feedback_discussion {
  margin-top: auto;
  overflow-y: auto;
}
@media (max-width: 767px) {
  .card--splitted .feedback_discussion,
.feedbacks-list-modal .feedback_discussion {
    padding-top: 58px;
    padding-bottom: 71px;
  }
}
.card--splitted .feedback_discussion .discussion_answer,
.feedbacks-list-modal .feedback_discussion .discussion_answer {
  max-width: 70%;
  padding: 0 20px 10px 20px;
}
@media (max-width: 767px) {
  .card--splitted .feedback_discussion .discussion_answer,
.feedbacks-list-modal .feedback_discussion .discussion_answer {
    max-width: 90%;
  }
}
.card--splitted .feedback_discussion .discussion_answer:first-child,
.feedbacks-list-modal .feedback_discussion .discussion_answer:first-child {
  padding-top: 20px;
}
.card--splitted .feedback_discussion .discussion_answer:last-child,
.feedbacks-list-modal .feedback_discussion .discussion_answer:last-child {
  padding-bottom: 30px;
}
.card--splitted .feedback_discussion .discussion_answer .answer_block,
.feedbacks-list-modal .feedback_discussion .discussion_answer .answer_block {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.card--splitted .feedback_discussion .discussion_answer .answer_block .nnki-rounded-background-image,
.feedbacks-list-modal .feedback_discussion .discussion_answer .answer_block .nnki-rounded-background-image {
  width: 32px;
  flex-shrink: 0;
  margin-right: 10px;
}
.card--splitted .feedback_discussion .discussion_answer .answer_block .answer_content,
.feedbacks-list-modal .feedback_discussion .discussion_answer .answer_block .answer_content {
  @apply rounded;
  background: var(--theme-gray-eighteen);
  font-size: 1.4rem;
  padding: 10px 15px;
}
.card--splitted .feedback_discussion .discussion_answer .answer_meta,
.feedbacks-list-modal .feedback_discussion .discussion_answer .answer_meta {
  padding-left: 38px;
  font-size: 1.2rem;
  color: var(--app-gray-twentynine);
}
.card--splitted .feedback_discussion .discussion_answer.-fromUser,
.feedbacks-list-modal .feedback_discussion .discussion_answer.-fromUser {
  margin-left: auto;
}
.card--splitted .feedback_discussion .discussion_answer.-fromUser .nnki-rounded-background-image,
.feedbacks-list-modal .feedback_discussion .discussion_answer.-fromUser .nnki-rounded-background-image {
  margin-right: 0;
  margin-left: 10px;
  order: 2;
}
.card--splitted .feedback_discussion .discussion_answer.-fromUser .answer_block .answer_content,
.feedbacks-list-modal .feedback_discussion .discussion_answer.-fromUser .answer_block .answer_content {
  @apply rounded;
  background: var(--site-theme-main-two);
  margin-left: auto;
}
.card--splitted .feedback_discussion .discussion_answer.-fromUser .answer_meta,
.feedbacks-list-modal .feedback_discussion .discussion_answer.-fromUser .answer_meta {
  text-align: right;
  padding-left: 0px;
  padding-right: 42px;
}
.card--splitted .form-nnki,
.feedbacks-list-modal .form-nnki {
  border-top: 1px solid var(--theme-gray-eight);
  padding: 15px 20px 5px 20px;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .card--splitted .form-nnki,
.feedbacks-list-modal .form-nnki {
    background: var(--theme-white);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.card--splitted .form-nnki .row-form-nnki.-wrapButton,
.feedbacks-list-modal .form-nnki .row-form-nnki.-wrapButton {
  padding-right: 50px;
}
@media (max-width: 767px) {
  .card--splitted .form-nnki .row-form-nnki.-wrapButton,
.feedbacks-list-modal .form-nnki .row-form-nnki.-wrapButton {
    padding-right: 0;
  }
}
.card--splitted .splitted-component-secondary,
.feedbacks-list-modal .splitted-component-secondary {
  border-left: 1px solid var(--theme-gray-eight);
  @apply transition-width;
  position: relative;
  max-width: 50%;
}
@media (max-width: 767px) {
  .card--splitted .splitted-component-secondary,
.feedbacks-list-modal .splitted-component-secondary {
    display: none;
  }
}
.card--splitted .splitted-component-secondary .mat-tab-header,
.feedbacks-list-modal .splitted-component-secondary .mat-tab-header {
  padding: 0;
}
.card--splitted .splitted-component-secondary.-contentInside,
.feedbacks-list-modal .splitted-component-secondary.-contentInside {
  width: 300px;
  flex-shrink: 0;
}
@media (max-width: 1199px) {
  .card--splitted .splitted-component-secondary.-contentInside,
.feedbacks-list-modal .splitted-component-secondary.-contentInside {
    width: 240px;
  }
}
.card--splitted .splitted-component-secondary .toggle-panel,
.feedbacks-list-modal .splitted-component-secondary .toggle-panel {
  position: absolute;
  top: 80px;
  left: -13px;
  @apply z-1;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: var(--site-main-one);
  color: var(--app-white);
  display: flex;
  align-items: center;
  justify-content: center;
  @apply cursor-pointer transition-transform;
}
.card--splitted .splitted-component-secondary.-panelClosed,
.feedbacks-list-modal .splitted-component-secondary.-panelClosed {
  width: 0;
}
.card--splitted .splitted-component-secondary.-panelClosed .toggle-panel,
.feedbacks-list-modal .splitted-component-secondary.-panelClosed .toggle-panel {
  display: none;
}
.card--splitted .splitted-component-detail,
.feedbacks-list-modal .splitted-component-detail {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.card--splitted .splitted-component-detail .spinner_100_container,
.feedbacks-list-modal .splitted-component-detail .spinner_100_container {
  position: absolute;
  top: 90px;
  left: 0;
  right: 0;
}

.block-nnki-page-title {
  font-size: 2.6rem;
  font-weight: 800;
  padding-right: 20px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .block-nnki-page-title {
    display: none;
    margin-bottom: 0;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.block-nnki-page-title.-withBackButton {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .block-nnki-page-title.-withBackButton > .title-text {
    display: none;
  }
}
.block-nnki-page-title.-withBackButton .back-button {
  margin-right: 60px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .block-nnki-page-title.-withBackButton .back-button {
    margin-right: 20px;
  }
}
.block-nnki-page-title.-withBackButton .back-logo {
  margin-right: 76px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .block-nnki-page-title.-withBackButton .back-logo {
    margin-right: 36px;
  }
}
@media (max-width: 991px) {
  .block-nnki-page-title.-withBackButton .back-logo {
    display: none;
  }
}
.block-nnki-page-title.-withBackButton .title-search {
  margin-left: auto;
  font-weight: normal;
}
@media (max-width: 991px) {
  .block-nnki-page-title.-withBackButton .title-search {
    display: none;
  }
}
.block-nnki-page-title.-withButtons {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .block-nnki-page-title.-withButtons > .title-text {
    display: none;
  }
}
@media (max-width: 767px) {
  .block-nnki-page-title.-showMobile {
    display: flex;
    margin-bottom: 20px;
  }
  .block-nnki-page-title.-showMobile > .title-text {
    display: block;
  }
}
.block-nnki-page-title.-fixedWidget {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  @apply z-widgets;
  background: var(--theme-white);
  padding: var(--safe-area-inset-top) 20px 0 20px;
  height: calc(var(--safe-area-inset-top) + 69px);
  margin: 0;
}
.block-nnki-page-title.-fixedWidget.-aboveTabs {
  background: transparent;
  left: auto;
  height: calc(var(--safe-area-inset-top) + 63px);
}
@media (max-width: 767px) {
  .block-nnki-page-title.-fixedWidget.-aboveTabs {
    height: calc(var(--safe-area-inset-top) + 63px);
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .block-nnki-page-title.-fixedWidget {
    height: calc(var(--safe-area-inset-top) + 50px);
  }
}
.block-nnki-page-title .title-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 2px 0;
}

.block-nnki-title {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 2.3rem;
  color: var(--theme-black);
  font-weight: 600;
  min-height: 36px;
}
@media (max-width: 767px) {
  .block-nnki-title {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.block-nnki-title .goToContent {
  margin-left: 5px;
  padding: 5px;
}
@media (max-width: 767px) {
  .block-nnki-title .goToContent {
    display: none;
  }
}

.block-nnki-smalltitle {
  color: var(--site-main-one);
  font-size: 1.6rem;
  font-weight: 800;
  margin-bottom: 15px;
}

.grid-container .card,
.swiper-slide .card {
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.grid-container .card .card-content,
.swiper-slide .card .card-content {
  flex-grow: 1;
}

.item-image-grid-container {
  @apply grid gap-12 grid-cols-[repeat(auto-fill,minmax(250px,1fr))];
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(349px, 1fr));
  grid-gap: 30px;
}
.grid-container.-students-grid {
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.grid-container.-video-grid {
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
}
@media (max-width: 400px) {
  .grid-container {
    @apply grid-cols-1;
  }
}

.block-content-insession {
  padding-left: 120px;
  padding-right: 60px;
}
@media (max-width: 1199px) {
  .block-content-insession {
    padding-left: 80px;
    padding-right: 40px;
  }
}
@media (max-width: 991px) {
  .block-content-insession {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 767px) {
  .block-content-insession {
    padding-left: 0;
    padding-right: 0;
  }
}

.header-content {
  background-image: linear-gradient(to top, var(--site-section-4-gradient-1) 50%, var(--site-section-4-gradient-2) 100%);
  padding-top: 15px;
  padding-bottom: 130px;
  color: var(--app-white);
}
@media (max-width: 767px) {
  .header-content {
    padding-bottom: 45px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.header-content .header-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .header-content .header-row {
    align-items: flex-start;
  }
}
.header-content .header-row.-withBlockOnRight {
  padding-right: 267px;
}
@media (max-width: 767px) {
  .header-content .header-row.-withBlockOnRight {
    padding-right: 0;
  }
}
.header-content .header-picto {
  background: var(--theme-gray-three);
  margin-right: 10px;
  border-radius: 50%;
  width: 61px;
  height: 61px;
  flex-shrink: 0;
  border: 3px solid var(--theme-white);
  overflow: hidden;
}
.header-content .header-picto.-random, .header-content .header-picto.-transversal {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 800;
  line-height: 100%;
  color: var(--theme-white);
}
.header-content .header-picto.-random {
  background: var(--app-purple-one);
}
.header-content .header-picto.-transversal {
  background: var(--app-orange-three);
}
.header-content .header-text {
  margin-right: 20px;
  overflow: hidden;
}
@media (max-width: 767px) {
  .header-content .header-text {
    margin-right: 0;
  }
}
.header-content .header-title {
  font-size: 1.4rem;
  font-weight: 600;
  padding-bottom: 1px;
  margin-bottom: 5px;
}
.header-content .header-title > p {
  @apply truncate;
}
@media (max-width: 767px) {
  .header-content .header-title > p {
    white-space: normal;
  }
}
.header-content .header-theme {
  font-size: 1.6rem;
  font-weight: 800;
  padding-bottom: 1px;
}
.header-content .header-theme > p {
  @apply truncate;
}
.header-content .header-theme > p.-noClamp {
  @apply whitespace-normal;
}
@media (max-width: 767px) {
  .header-content .header-theme > p {
    @apply whitespace-normal line-clamp-3;
  }
  .header-content .header-theme > p.-noClamp {
    @apply line-clamp-none;
  }
}
.header-content .header-details {
  margin-top: 10px;
  display: flex;
  align-items: stretch;
}
.header-content .header-details .header-detail {
  color: var(--theme-black);
  font-size: 1.2rem;
  font-weight: bold;
  background: var(--theme-white);
  padding: 0 8px;
  height: 24px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  margin-right: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.header-content .header-details .header-detail:last-child {
  margin-right: 0;
}
.header-content .header-details .header-detail svg-icon {
  margin-right: 4px;
  color: var(--site-main-one);
}
.header-content .header-nav {
  display: flex;
  margin-left: auto;
}
.header-content .header-nav a {
  color: var(--theme-white);
  margin-left: 20px;
}
.header-content .header-nav a:first-child {
  margin-left: 0;
}

.below-header {
  margin-top: -115px;
  padding-bottom: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .below-header {
    margin-top: -30px;
  }
  .below-header.-noHorizontalPaddingMobile {
    padding-left: 0;
    padding-right: 0;
  }
  .below-header.-withInsideTabs {
    margin-top: -50px;
  }
}

.block--categories {
  @apply flex flex-wrap gap-x-4 gap-y-2;
}

.badge-category {
  border-radius: 12px;
  background-color: var(--site-theme-main-five);
  color: var(--theme-gray-one);
  font-size: 1.2rem;
  padding: 7px;
}
.badge-category.-truncated {
  max-width: 150px;
  @apply truncate;
}
.badge-category.-white, .card--nnki-question .badge-category.card-content.-correction {
  @apply shadow;
  background-color: var(--theme-white);
  padding-left: 12px;
  padding-right: 12px;
}
.badge-category.-plus {
  background: linear-gradient(144.11deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
  color: var(--app-white);
}
.badge-category.-active {
  background: var(--site-light-card);
  color: var(--theme-white);
}

.block--training-svg {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}
.block--training-svg > * {
  overflow: hidden;
}
.block--training-svg svg-icon {
  color: var(--site-main-one);
  margin-right: 10px;
}
.block--training-svg .training-label {
  font-size: 1.4rem;
  font-weight: bold;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .block--training-svg .training-label {
    font-size: 1.3rem;
    white-space: nowrap;
  }
}
.block--training-svg .training-value {
  display: flex;
  align-items: center;
  margin-top: 7px;
}
.block--training-svg .value-number {
  font-size: 1.4rem;
  font-weight: bold;
  color: var(--site-main-one);
  white-space: nowrap;
  margin-right: 5px;
}
.block--training-svg .ecni_progress_bar {
  width: auto;
  flex-grow: 1;
  height: 5px;
  max-width: 120px;
}

.block--rate {
  position: absolute;
  top: 20px;
  right: 0;
}
.block--rate .rate-panel {
  background: var(--theme-white);
  box-shadow: 0 4px 17px 0 rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 20px 25px;
  position: relative;
  @apply z-2;
  white-space: nowrap;
  flex-direction: column;
  display: flex;
  align-items: flex-end;
}
.block--rate .mask {
  position: fixed;
  @apply z-1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.block-scrollable-list {
  @apply h-[calc(100vh-380px)] sm:h-[270px] min-h-[270px] overflow-auto border border-theme-gray-fifteen p-4 rounded;
}
.block-scrollable-list .list-grid {
  @apply grid grid-cols-[repeat(auto-fill,minmax(80px,1fr))] gap-4;
}

.block-videos-list {
  @apply grid grid-cols-1 sm:grid-cols-[repeat(auto-fill,minmax(500px,1fr))] gap-8;
}

.block-iframe {
  @apply aspect-video relative;
}
.block-iframe iframe {
  @apply w-full h-full;
}

.block--pricing-cards {
  @apply flex space-x-4;
}
.block--pricing-cards img {
  @apply w-[65px];
}

.block--stripe-container {
  @apply p-4 bg-site-main-five w-full h-[60px] flex flex-col justify-center border border-transparent rounded;
}
.block--stripe-container.StripeElement--invalid {
  @apply border-red-one;
}

.block--friends-list-search .form-field-nnki.mat-form-field-appearance-legacy {
  @apply pt-2 pb-0;
}
.block--friends-list-search .form-field-nnki.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) label {
  @apply text-14 opacity-40;
}
.block--friends-list-search .btn-nnki {
  @apply h-auto w-[47px] shrink-0;
}

.block--modal-challenge .discipline-badge {
  @apply text-20 font-extrabold w-full flex justify-center overflow-hidden;
  transition: transform 0.4s, opacity 0.4s;
  transform: translateY(-200px);
}
.block--modal-challenge .discipline-badge.-inside {
  transform: translateY(0);
}
.block--modal-challenge .discipline-badge.-gone {
  transform: translateY(0);
  @apply opacity-0;
}
.block--modal-challenge .discipline-badge .badge-content {
  @apply rounded-[40px] bg-theme-white py-3 md:py-5 px-20 md:px-28 flex items-center max-w-[calc(100%-40px)] md:max-w-[420px];
}
.block--modal-challenge .discipline-badge .badge-content > img {
  @apply mr-4 md:mr-6 rounded-full overflow-hidden w-[48px] h-[48px] md:w-[54px] md:h-[54px];
}
.block--modal-challenge .discipline-badge .badge-content > span {
  @apply grow basis-0 truncate;
}
.block--modal-challenge .question-number {
  @apply rounded-[22px] border border-theme-white py-4 px-16 mb-12 text-20 font-extrabold uppercase text-theme-white opacity-0;
  transition: opacity 0.4s;
}
.block--modal-challenge .question-number.-inside {
  @apply opacity-100;
}
.block--modal-challenge .top-question-challenge {
  @apply bg-theme-white shadow sm:h-36 flex flex-wrap sm:flex-nowrap items-center justify-between p-8 pt-4 sm:py-0 sm:px-28 fixed sm:static top-safeTop left-0 right-0 z-1;
  transition: transform 0.4s;
  transform: translateY(-200px);
}
.block--modal-challenge .top-question-challenge.-inside {
  transform: translateY(0);
}
.block--modal-challenge .top-question-challenge .discipline-badge {
  @apply w-auto mb-4 sm:mb-0 mr-8 pr-8 sm:pr-0 grow basis-0 block;
}
.block--modal-challenge .top-question-challenge .discipline-badge .badge-content {
  @apply p-0 max-w-full;
}
.block--modal-challenge .top-question-challenge app-widget-time-remaining {
  @apply w-full sm:w-[300px] min-w-0 shrink-0 grow-0;
}
.block--modal-challenge .content-question-challenge {
  background-image: linear-gradient(129deg, var(--site-card-gradient-1) 28%, var(--site-card-gradient-2) 91%);
  padding: 30px 20px 85px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: opacity 0.4s;
  opacity: 0;
}
@media (max-width: 767px) {
  .block--modal-challenge .content-question-challenge {
    padding-top: 138px;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.block--modal-challenge .content-question-challenge.-inside {
  opacity: 1;
}
.block--modal-challenge .content-question-challenge > * {
  max-width: 100%;
}
.block--modal-challenge .content-question-challenge .question-number {
  @apply text-14;
}
.block--modal-challenge .content-question-challenge .question-content {
  width: 800px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  transition: opacity 0.4s;
  opacity: 0;
}
@media (max-width: 767px) {
  .block--modal-challenge .content-question-challenge .question-content {
    flex-wrap: wrap;
  }
}
.block--modal-challenge .content-question-challenge .question-content.-inside {
  opacity: 1;
}
.block--modal-challenge .content-question-challenge .question-content app-question-images {
  margin-left: 0;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .block--modal-challenge .content-question-challenge .question-content app-question-images {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    order: 0;
  }
}
.block--modal-challenge .content-question-challenge .question-content .question-text {
  flex-grow: 1;
  color: var(--theme-white);
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 130%;
  text-align: center;
}
@media (max-width: 767px) {
  .block--modal-challenge .content-question-challenge .question-content .question-text {
    font-size: 1.8rem;
  }
}
.block--modal-challenge .content-question-challenge .question-content .question-text p strong {
  color: inherit;
}
.block--modal-challenge .content-question-challenge .question-answers {
  margin-bottom: 30px;
  transition: opacity 0.4s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.block--modal-challenge .content-question-challenge .question-answers.-inside {
  opacity: 1;
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer {
  background: var(--theme-white);
  color: var(--site-main-one);
  @apply rounded;
  width: 550px;
  max-width: 100%;
  margin: 0 auto 10px auto;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  line-height: 130%;
  padding: 10px 20px;
  border: 3px solid transparent;
  cursor: pointer;
  transition: all 0.4s;
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer.-incorrect {
  opacity: 0.2;
  color: var(--app-green-white);
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer.-correct {
  border-color: var(--app-green-three);
  color: var(--app-green-three);
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer.-checked {
  background: var(--site-main-one);
  color: var(--app-white);
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer.-checked.-incorrect {
  background: var(--app-red-one);
  color: var(--app-white);
}
.block--modal-challenge .content-question-challenge .question-answers .question-answer.-checked.-correct {
  border-color: transparent;
  background: var(--app-green-three);
  color: var(--app-white);
}
.block--modal-challenge .content-question-challenge .question-qroc {
  width: 550px;
  margin-bottom: 30px;
  transition: opacity 0.4s;
  opacity: 0;
}
.block--modal-challenge .content-question-challenge .question-qroc.-inside {
  opacity: 1;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki {
  padding: 0;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki.nnki-no-select, .block--modal-challenge .content-question-challenge .question-qroc .card--nnki-question.-time-lapsed .form-nnki.question-images, .card--nnki-question.-time-lapsed .block--modal-challenge .content-question-challenge .question-qroc .form-nnki.question-images {
  opacity: 1;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki.nnki-no-select .input-nnki, .block--modal-challenge .content-question-challenge .question-qroc .card--nnki-question.-time-lapsed .form-nnki.question-images .input-nnki, .card--nnki-question.-time-lapsed .block--modal-challenge .content-question-challenge .question-qroc .form-nnki.question-images .input-nnki {
  border: 3px solid transparent;
  transition: all 0.4s;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki.nnki-no-select .-incorrect, .block--modal-challenge .content-question-challenge .question-qroc .card--nnki-question.-time-lapsed .form-nnki.question-images .-incorrect, .card--nnki-question.-time-lapsed .block--modal-challenge .content-question-challenge .question-qroc .form-nnki.question-images .-incorrect {
  background: var(--app-red-one);
  color: var(--theme-white);
  opacity: 0.2;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki.nnki-no-select .-correct, .block--modal-challenge .content-question-challenge .question-qroc .card--nnki-question.-time-lapsed .form-nnki.question-images .-correct, .card--nnki-question.-time-lapsed .block--modal-challenge .content-question-challenge .question-qroc .form-nnki.question-images .-correct {
  background: var(--app-green-three);
  color: var(--theme-white);
  font-weight: 800;
}
.block--modal-challenge .content-question-challenge .question-qroc .form-nnki.nnki-no-select .-accepted, .block--modal-challenge .content-question-challenge .question-qroc .card--nnki-question.-time-lapsed .form-nnki.question-images .-accepted, .card--nnki-question.-time-lapsed .block--modal-challenge .content-question-challenge .question-qroc .form-nnki.question-images .-accepted {
  border-color: var(--app-green-three);
  background: var(--theme-white);
  color: var(--app-green-three);
  font-weight: 800;
}
.block--modal-challenge .question-button {
  transition: opacity 0.4s;
  opacity: 0;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .block--modal-challenge .question-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 10px calc(10px + var(--safe-area-inset-bottom)) 10px;
    margin-top: 0;
    background: var(--theme-white);
  }
}
.block--modal-challenge .question-button.-inside {
  opacity: 1;
}
.block--modal-challenge .question-button .btn-nnki {
  width: 300px;
  max-width: 100%;
}
@media (max-width: 767px) {
  .block--modal-challenge .question-button .btn-nnki {
    width: 100%;
  }
}

.block--pricing-offer {
  @apply h-full bg-cover bg-center bg-no-repeat p-8 text-white font-semibold text-center relative sm:rounded flex flex-col justify-between items-center space-y-4;
}
.block--pricing-offer.-decouverte {
  background-image: url(/assets/images/backgrounds/pricings/pricing_decouverte.svg);
}
.block--pricing-offer.-premium {
  background-image: url(/assets/images/backgrounds/pricings/pricing_premium.svg);
}
.block--pricing-offer.-premiumplus {
  background-image: url(/assets/images/backgrounds/pricings/pricing_premiumplus.svg);
}
.block--pricing-offer.-subscription {
  @apply hidden sm:flex;
}
.block--pricing-offer .pricing-offer__title {
  @apply text-30 sm:text-20 md:text-30;
}
.block--pricing-offer .pricing-offer__subtitle {
  @apply font-avenir text-18 font-extrabold;
}
.block--pricing-offer .pricing-offer__separator {
  @apply flex justify-center;
}
.block--pricing-offer .pricing-offer__price {
  @apply text-30;
}
.block--pricing-offer .pricing-offer__monthes {
  @apply text-18 space-y-2;
}
.block--pricing-offer .pricing-offer__infos {
  @apply font-avenir text-14;
}
.block--pricing-offer .pricing-offer__buttons {
  @apply hidden sm:block min-h-[40px];
}
.block--pricing-offer .pricing-offer__buttons .btn-nnki svg-icon {
  @apply ml-4;
}

.classic-grid {
  @apply grid grid-cols-1 gap-4 content-start items-start;
}

.styled-text > * {
  @apply mb-4;
}
.styled-text ul li {
  @apply list-disc ml-8;
}

button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  background: transparent;
}

.btn-nnki {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 1.3rem;
  font-weight: bold;
  line-height: initial;
  white-space: nowrap;
  padding: 0 15px;
  height: 40px;
  cursor: pointer;
}
.btn-nnki[disabled] {
  cursor: auto;
  pointer-events: none;
  opacity: 0.5;
}
.btn-nnki.-disabled {
  cursor: auto;
  opacity: 0.5;
}

.btn-nnki-100 {
  width: 100%;
}

.btn-nnki-primary {
  background: var(--site-main-one);
  color: var(--app-white);
}
.btn-nnki-primary:hover {
  color: var(--app-white);
}

.btn-nnki-purple {
  background: var(--theme-purple-two);
  color: var(--app-white);
}
.btn-nnki-purple:hover {
  color: var(--theme-white);
}

.btn-nnki-white {
  background: var(--theme-white);
  color: var(--site-theme-accent);
  border: 1px solid var(--site-theme-accent);
}

.btn-nnki-light {
  background: var(--site-theme-main-five);
  color: var(--theme-black);
  border: 1px solid var(--site-theme-main-five);
}
.btn-nnki-light:hover {
  color: var(--theme-black);
}
.btn-nnki-light svg-icon {
  color: var(--site-main-one);
}

.btn-nnki-black {
  background: var(--theme-black);
  color: var(--theme-white);
  border: 1px solid var(--theme-black);
}
.btn-nnki-black:hover {
  color: var(--theme-white);
}

.btn-nnki-red {
  background: var(--app-red-two);
  color: var(--app-white);
  border: 1px solid var(--app-red-two);
}
.btn-nnki-red:hover {
  color: var(--app-white);
}

.btn-nnki-red-three {
  @apply bg-red-three text-white border border-red-three;
}
.btn-nnki-red-three:hover {
  color: var(--app-white);
}

.btn-nnki-orange-two {
  @apply bg-orange-two text-white border border-orange-two;
}
.btn-nnki-orange-two:hover {
  color: var(--app-white);
}

.btn-nnki-green {
  background: var(--app-green-three);
  color: var(--app-white);
  border: 1px solid var(--app-green-three);
}
.btn-nnki-green:hover {
  color: var(--app-white);
}

.btn-nnki-gold {
  background: var(--app-gold);
  color: var(--app-black);
  border: 1px solid var(--app-gold);
}

.btn-nnki-gradient {
  background-image: linear-gradient(24deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
  color: var(--app-white);
}
.btn-nnki-gradient:hover {
  color: var(--app-white);
}

.btn-nnki-transparent {
  background: transparent;
  color: var(--site-main-one);
  border: 1px solid transparent;
}
.btn-nnki-transparent:hover {
  color: var(--site-main-one);
}

.btn-nnki-transparent-with-border {
  background: transparent;
  color: var(--site-main-one);
  border: 1px solid var(--site-main-one);
}
.btn-nnki-transparent-with-border:hover {
  color: var(--site-main-one);
}

.btn-nnki-uppercase {
  text-transform: uppercase;
  font-size: 1.2rem;
}

.btn-nnki-heavy {
  font-weight: 800;
}

.btn-nnki-rounded {
  border-radius: 18px;
  height: 40px;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: 600;
  font-size: 1rem;
}

.btn-nnki-card {
  font-size: 1rem;
  height: 36px;
  min-width: 120px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  .btn-nnki-card {
    min-width: 100px;
  }
}

.btn-nnki-small {
  font-size: 1.1rem;
  font-weight: normal;
  height: 30px;
}
.btn-nnki-small.btn-nnki-icon {
  width: 30px;
}

.btn-nnki-icon {
  padding: 0;
  width: 40px;
}

.btn-nnki-icon-left svg-icon {
  margin-right: 15px;
}

.btn-nnki-icon-right svg-icon {
  margin-left: 15px;
}

.btn-nnki-icon-both svg-icon:first-child {
  margin-right: 15px;
}
.btn-nnki-icon-both svg-icon:last-child {
  margin-left: 15px;
}

.btn-nnki-circular {
  width: 32px;
  height: 32px;
  flex-shrink: 0;
  color: var(--site-theme-accent);
  border: 1px solid var(--site-theme-accent);
  border-radius: 50%;
  font-weight: normal;
  padding: 0;
}
.btn-nnki-circular.-no-border {
  border: 0;
}

.btn-nnki-action {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  color: var(--site-main-one);
  background: var(--site-main-six);
  @apply rounded;
}
.btn-nnki-action.-no-border {
  border: 0;
}

.btn-nnki-tab {
  height: 40px;
  border-radius: 50px;
  @apply shadow;
  background-color: var(--theme-white);
  font-size: 1.3rem;
  padding: 0 20px;
}
.btn-nnki-tab svg-icon {
  margin-right: 10px;
  color: var(--site-main-one);
}
@media (max-width: 767px) {
  .btn-nnki-tab {
    background: var(--site-main-one);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 0;
  }
  .btn-nnki-tab > svg-icon {
    margin: 0;
    width: 33px;
    height: auto;
    color: var(--theme-white);
  }
  .btn-nnki-tab > span {
    display: none;
  }
}
.btn-nnki-tab.-withoutText {
  box-shadow: none;
  background: transparent;
  margin-right: 0 !important;
}
.btn-nnki-tab.-withoutText svg-icon {
  margin-right: 0;
}

.btn-nnki-dummy {
  background: transparent;
  pointer-events: none;
}

.mat-ripple:not(:empty) {
  transform: none;
}

.dummy-animated {
  background: -moz-linear-gradient(left, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background: -webkit-linear-gradient(left, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background: linear-gradient(to right, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background-size: 200% 200%;
  -webkit-animation: dummyGradient 1.5s linear infinite;
  -moz-animation: dummyGradient 1.5s linear infinite;
  animation: dummyGradient 1.5s linear infinite;
}

.dummy-rectangle {
  @apply h-8 w-full bg-theme-gray-three rounded;
  background: -moz-linear-gradient(left, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background: -webkit-linear-gradient(left, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background: linear-gradient(to right, var(--theme-gray-three) 0%, var(--theme-gray-three) 30%, var(--theme-gray-eighteen) 40%, var(--theme-gray-eighteen) 60%, var(--theme-gray-three) 70%, var(--theme-gray-three) 100%);
  background-size: 200% 200%;
  -webkit-animation: dummyGradient 1.5s linear infinite;
  -moz-animation: dummyGradient 1.5s linear infinite;
  animation: dummyGradient 1.5s linear infinite;
}

.dummy-background {
  @apply bg-theme-gray-three;
}

@-webkit-keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
@-moz-keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
@keyframes dummyGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: -200% 50%;
  }
}
.filters-panel {
  @apply fixed sm:absolute sm:w-[400px] top-0 bottom-0 right-0 left-0 sm:left-auto sm:bottom-auto sm:max-h-[calc(100vh-90px-)] overflow-auto bg-theme-white pt-12 pb-28 sm:py-12 sm:px-8 text-theme-black sm:rounded sm:shadow z-panels;
}
@screen sm {
  .filters-panel {
    max-height: calc(100vh - 90px - var(--safe-area-inset-bottom) - var(--safe-area-inset-top)) !important;
  }
}
.filters-panel:before {
  content: "";
  @apply block sm:hidden h-safeTop;
}
.filters-panel:after {
  content: "";
  @apply block sm:hidden h-safeBottom;
}
.filters-panel .spinner_100_container {
  @apply my-20;
}

.mask-filters {
  @apply fixed top-0 bottom-0 right-0 left-0;
  z-index: calc(theme("zIndex.panels") - 1);
}

.filters-close {
  @apply absolute right-0 top-4 p-8 cursor-pointer;
}
.filters-close:before {
  content: "";
  @apply block sm:hidden h-safeTop;
}

.filters-title {
  @apply px-8 sm:px-0 text-20 font-bold mb-10;
}

.filters-reset {
  @apply cursor-pointer text-right text-site-main-one text-13 font-bold mb-6;
}
.filters-reset.-hidden {
  @apply pointer-events-none opacity-0;
}

.filter-blocks {
  @apply space-y-6;
}

.filter-block {
  @apply bg-site-theme-main-five rounded-card py-8 px-12;
}
.filter-block .filter-title {
  @apply text-16 font-bold text-site-main-one mb-8;
}
.filter-block .mat-form-field-appearance-legacy {
  @apply bg-theme-white !important;
}

.filters-submit {
  @apply fixed left-0 right-0 bottom-0 p-6 bg-theme-white sm:hidden shadow;
}
.filters-submit:after {
  content: "";
  @apply block h-safeBottom;
}

.form-v2-nnki label,
.form-nnki label,
.row-form-nnki label {
  @apply font-normal font-avenir text-14;
}
.form-v2-nnki mat-form-field,
.form-nnki mat-form-field,
.row-form-nnki mat-form-field {
  @apply bg-site-theme-main-five py-3 px-4 rounded w-full;
}
.form-v2-nnki mat-form-field .mat-form-field-wrapper,
.form-nnki mat-form-field .mat-form-field-wrapper,
.row-form-nnki mat-form-field .mat-form-field-wrapper {
  @apply pb-0;
}
.form-v2-nnki mat-form-field .mat-form-field-infix,
.form-nnki mat-form-field .mat-form-field-infix,
.row-form-nnki mat-form-field .mat-form-field-infix {
  @apply w-auto border-t-[8px];
}
.form-v2-nnki mat-form-field .mat-input-element,
.form-nnki mat-form-field .mat-input-element,
.row-form-nnki mat-form-field .mat-input-element {
  @apply text-site-main-one text-16 font-extrabold font-default;
}
.form-v2-nnki mat-form-field .mat-form-field-label-wrapper,
.form-nnki mat-form-field .mat-form-field-label-wrapper,
.row-form-nnki mat-form-field .mat-form-field-label-wrapper {
  @apply top-[-17px];
}
.form-v2-nnki mat-form-field .mat-form-field-label,
.form-nnki mat-form-field .mat-form-field-label,
.row-form-nnki mat-form-field .mat-form-field-label {
  @apply font-default text-16 font-extrabold;
}
.form-v2-nnki mat-form-field .mat-form-field-suffix,
.form-nnki mat-form-field .mat-form-field-suffix,
.row-form-nnki mat-form-field .mat-form-field-suffix {
  @apply text-site-main-one;
}
.form-v2-nnki mat-form-field .mat-form-field-underline,
.form-nnki mat-form-field .mat-form-field-underline,
.row-form-nnki mat-form-field .mat-form-field-underline {
  @apply hidden;
}
.form-v2-nnki mat-form-field .mat-form-field-subscript-wrapper,
.form-nnki mat-form-field .mat-form-field-subscript-wrapper,
.row-form-nnki mat-form-field .mat-form-field-subscript-wrapper {
  @apply pointer-events-none z-1 bottom-[-30px] top-auto overflow-visible;
}
.form-v2-nnki mat-form-field.mat-form-field-should-float .mat-form-field-label,
.form-nnki mat-form-field.mat-form-field-should-float .mat-form-field-label,
.row-form-nnki mat-form-field.mat-form-field-should-float .mat-form-field-label {
  @apply top-[24px] text-14 font-normal;
}
.form-v2-nnki mat-form-field.mat-form-field-invalid,
.form-nnki mat-form-field.mat-form-field-invalid,
.row-form-nnki mat-form-field.mat-form-field-invalid {
  @apply border border-red-one;
}
.form-v2-nnki mat-form-field.mat-form-field-invalid .mat-input-element,
.form-v2-nnki mat-form-field.mat-form-field-invalid .mat-form-field-suffix,
.form-nnki mat-form-field.mat-form-field-invalid .mat-input-element,
.form-nnki mat-form-field.mat-form-field-invalid .mat-form-field-suffix,
.row-form-nnki mat-form-field.mat-form-field-invalid .mat-input-element,
.row-form-nnki mat-form-field.mat-form-field-invalid .mat-form-field-suffix {
  @apply text-red-one;
}
.form-v2-nnki mat-form-field.mat-form-field-disabled,
.form-nnki mat-form-field.mat-form-field-disabled,
.row-form-nnki mat-form-field.mat-form-field-disabled {
  @apply opacity-50;
}
.form-v2-nnki mat-checkbox .mat-checkbox-layout,
.form-nnki mat-checkbox .mat-checkbox-layout,
.row-form-nnki mat-checkbox .mat-checkbox-layout {
  @apply items-start whitespace-normal font-default;
}
.form-v2-nnki mat-checkbox .mat-checkbox-inner-container,
.form-nnki mat-checkbox .mat-checkbox-inner-container,
.row-form-nnki mat-checkbox .mat-checkbox-inner-container {
  @apply mr-6 mt-2 mb-0;
}
.form-v2-nnki mat-checkbox + mat-error,
.form-nnki mat-checkbox + mat-error,
.row-form-nnki mat-checkbox + mat-error {
  @apply hidden;
}
.form-v2-nnki mat-select .mat-select-value-text,
.form-nnki mat-select .mat-select-value-text,
.row-form-nnki mat-select .mat-select-value-text {
  @apply text-site-main-one text-16 font-extrabold;
}
.form-v2-nnki.ng-submitted mat-checkbox + mat-error,
.form-nnki.ng-submitted mat-checkbox + mat-error,
.row-form-nnki.ng-submitted mat-checkbox + mat-error {
  @apply block;
}

.error-tooltip, .form-field-nnki-container .form-field-error, .success-tooltip {
  @apply z-1 relative ml-auto rounded bg-red-one text-white text-14 px-6 py-2 max-w-[80%] pointer-events-none !important;
}
.error-tooltip:before, .form-field-nnki-container .form-field-error:before, .success-tooltip:before {
  content: "";
  border-style: solid;
  border-width: 0 6px 6px 6px;
  border-color: transparent transparent var(--app-red-one) transparent;
  @apply absolute top-[-6px] left-8 w-0 h-0 z-2;
}

.success-tooltip {
  @apply bg-green-three !important;
}
.success-tooltip:before {
  border-color: transparent transparent var(--app-green-three) transparent;
}

.form-nnki-public .form-field-nnki.mat-form-field-appearance-legacy {
  box-shadow: 0 10px 37px 0 #e5f0ff;
  border: solid 1px var(--theme-gray-fifteen);
  background-color: var(--theme-gray-twentynine);
  padding-bottom: 4px;
}
.form-nnki-public .form-field-nnki.mat-form-field-appearance-legacy label {
  font-size: 1.4rem;
  font-weight: normal;
}
.form-nnki-public .form-field-nnki.mat-form-field-appearance-legacy:not(.mat-form-field-invalid) label {
  color: var(--theme-gray-two);
}
.form-nnki-public .form-field-nnki.mat-form-field-appearance-legacy.mat-form-field-invalid {
  border-color: var(--app-red-one);
}
.form-nnki-public .form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-suffix {
  color: var(--site-main-one);
}
.form-nnki-public .btn-nnki-100 {
  height: 45px;
}
.form-nnki-public .btn-nnki-100 svg-icon {
  margin-left: 45px;
}

.row-form-nnki {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .row-form-nnki {
    flex-wrap: wrap;
  }
  .row-form-nnki.-nowrap {
    flex-wrap: nowrap;
  }
}
.row-form-nnki.-alignItemsCenter {
  align-items: center;
}
.row-form-nnki.-center_align {
  justify-content: center;
}
.row-form-nnki.-right_align {
  justify-content: flex-end;
}
.row-form-nnki.-wrapButton {
  align-items: center;
}
@media (max-width: 767px) {
  .row-form-nnki.-wrapButton {
    flex-wrap: nowrap;
  }
}
.row-form-nnki.-wrapButton .form-field-nnki-container {
  margin: 0;
  height: 40px;
}
.row-form-nnki.-wrapButton textarea {
  width: 100%;
  height: 40px;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 1.6rem;
  background: var(--theme-gray-eighteen);
  color: var(--theme-black);
  border: 0;
  resize: none;
}
.row-form-nnki.-wrapButton .btn-nnki {
  margin-left: 10px;
  flex-shrink: 0;
}
.row-form-nnki .alert {
  width: 100%;
}

.width50 {
  width: calc(50% - 5px);
}
@media (max-width: 767px) {
  .width50 {
    width: 100%;
    margin-bottom: 10px;
  }
  .width50:last-child {
    margin-bottom: 0;
  }
}

.width100 {
  width: 100%;
}

.form-message-nnki {
  padding: 0 5px;
  font-size: 1.2rem;
}
.form-message-nnki.-error {
  color: var(--app-orange-one);
}
.form-message-nnki.-success {
  color: var(--app-green-three);
}
.form-message-nnki.-info {
  color: var(--theme-gray-five);
}

.input-nnki,
.input-nnki.mat-input-element {
  padding: 0 35px;
  height: 60px;
  @apply rounded;
  @apply shadow;
  border: solid 1px var(--theme-gray-fifteen);
  background-color: var(--theme-white);
  color: var(--theme-black);
  font-size: 1.6rem;
}
.input-nnki.-small,
.input-nnki.mat-input-element.-small {
  height: 40px;
  padding: 0 15px;
}
@media (max-width: 767px) {
  .input-nnki,
.input-nnki.mat-input-element {
    padding: 0 15px;
  }
}
.input-nnki[type=text], .input-nnki[type=email],
.input-nnki.mat-input-element[type=text],
.input-nnki.mat-input-element[type=email] {
  -webkit-user-select: auto;
}

.textarea-nnki {
  padding: 20px 35px;
  height: 120px;
  @apply rounded;
  @apply shadow;
  border: solid 1px var(--theme-gray-fifteen);
  background-color: var(--theme-white);
  resize: none;
}
@media (max-width: 767px) {
  .textarea-nnki {
    padding: 15px;
  }
}

.mat-radio-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.mat-radio-group.-allWrapped > * {
  width: 100%;
}
.mat-radio-group.-inline .mat-radio-button {
  margin-right: 30px;
}
.mat-radio-group.-inline .mat-radio-button:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .mat-radio-group.-inline {
    display: block;
  }
  .mat-radio-group.-inline > * {
    display: block;
  }
  .mat-radio-group.-inline .mat-radio-button {
    margin-right: 0px;
  }
}
.mat-radio-group.-submitted.ng-invalid .mat-radio-outer-circle {
  border-color: var(--app-red-one);
}
.mat-radio-group.-submitted.ng-invalid .mat-radio-label-content {
  color: var(--app-red-one);
}
.mat-radio-group label {
  font-weight: normal;
}

mat-radio-button.mat-radio-button.mat-radio-checked label {
  font-weight: bold;
}
mat-radio-button.mat-radio-button label {
  font-weight: normal;
}
mat-radio-button.mat-radio-button .mat-radio-label {
  white-space: normal;
  align-items: flex-start;
}
mat-radio-button.mat-radio-button .mat-radio-container {
  top: -1px;
}
mat-radio-button.mat-radio-button .mat-radio-checked .mat-radio-inner-circle {
  transform: scale(0.6);
}
mat-radio-button.mat-radio-button .mat-radio-outer-circle {
  border-width: 1px;
  border-color: var(--theme-gray-eleven);
  transform: scale(0.6);
}

.form-field-nnki.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.form-field-select {
  height: 58px;
}
.form-field-nnki.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.form-field-select .mat-form-field-wrapper {
  padding-bottom: 0;
}
.form-field-nnki.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.form-field-select .mat-form-field-underline {
  display: none;
}
.form-field-nnki.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.form-field-select .mat-select-arrow-wrapper {
  padding-bottom: 10px;
  top: -5px;
  position: relative;
}
.form-field-nnki.mat-form-field-type-mat-select.mat-form-field-appearance-legacy.form-field-select .mat-select.mat-select-empty + .mat-form-field-label-wrapper mat-label {
  color: var(--app-gray-twenty);
  font-size: 1.4rem;
  font-weight: 400;
}
.form-field-nnki.mat-form-field-type-mat-select .mat-select {
  font-family: Muli;
}
.form-field-nnki.mat-form-field-type-mat-select .mat-select .mat-select-value-text {
  color: var(--site-main-one);
  font-weight: 800;
  font-size: 1.4rem;
}

.form-field-nnki-container {
  position: relative;
}
.form-field-nnki-container .form-field-error {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
}

.form-field-nnki.mat-form-field-appearance-legacy {
  padding: 10px 10px;
  background-color: var(--site-theme-main-five);
  @apply rounded;
  line-height: 130%;
  width: 100%;
}
.form-field-nnki.mat-form-field-appearance-legacy.mat-form-field-invalid {
  border: 1px solid var(--app-red-one) !important;
}
.form-field-nnki.mat-form-field-appearance-legacy.mat-form-field-invalid .mat-input-element {
  color: var(--app-red-one);
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-label-wrapper {
  top: -1.5em;
  padding-top: 1.5em;
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-label {
  font-family: Muli;
}
.form-field-nnki.mat-form-field-appearance-legacy:not(.mat-form-field-invalid) .mat-form-field-label {
  color: var(--theme-black);
}
.form-field-nnki.mat-form-field-appearance-legacy:not(.mat-form-field-should-float) label {
  font-size: 1.6rem;
  font-weight: 800;
}
.form-field-nnki.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-subscript-wrapper {
  display: none;
}
.form-field-nnki.mat-form-field-appearance-legacy.mat-form-field-should-float .mat-form-field-label,
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-appearance-legacy.mat-form-field-can-float {
  transform: translateY(-0.5em) scale(0.75) perspective(100px) translateZ(0.001px);
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-input-element {
  color: var(--site-main-one);
  font-size: 1.6rem;
  font-weight: 800;
  font-family: Muli;
}
.form-field-nnki.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  font-size: 1rem;
  top: auto;
  bottom: -4px;
}
.form-field-nnki .mat-form-field-infix {
  width: auto;
}

.form-nnki-candidate {
  display: flex;
  flex-direction: column;
  padding: 20px 35px;
}
@media (max-width: 767px) {
  .form-nnki-candidate {
    padding: 0;
  }
}
.form-nnki-candidate.-candidate.nnki-no-select, .card--nnki-question.-time-lapsed .form-nnki-candidate.-candidate.question-images {
  opacity: 0.6;
}
.form-nnki-candidate .mat-checkbox-label,
.form-nnki-candidate .mat-radio-label-content {
  user-select: none;
}
.form-nnki-candidate.-correction .mat-checkbox-label,
.form-nnki-candidate.-correction .mat-radio-label-content {
  user-select: text !important;
}
.form-nnki-candidate label {
  font-weight: normal !important;
}
.form-nnki-candidate .daltonien-icon {
  display: none;
}
.form-nnki-candidate .row-match.-answerWin .input-nnki {
  color: var(--app-green-three);
  border-color: var(--app-green-three);
}
.form-nnki-candidate .row-match.-answerFail .input-nnki {
  color: var(--app-red-one);
  border-color: var(--app-red-one);
}
.form-nnki-candidate .row-answer .mat-radio-button .mat-radio-label-content {
  color: var(--theme-black);
}
.form-nnki-candidate .row-answer .mat-checkbox .mat-checkbox-label {
  color: var(--theme-black);
}
.form-nnki-candidate .row-answer.-answerWin .mat-radio-button .mat-radio-outer-circle {
  border-color: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerWin .mat-radio-button .mat-radio-inner-circle {
  background: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerWin .mat-radio-button .mat-radio-label-content {
  color: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerWin .mat-checkbox .mat-checkbox-background {
  background: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerWin .mat-checkbox .mat-checkbox-label {
  color: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerShould .mat-radio-button .mat-radio-outer-circle {
  border-color: var(--app-green-three);
  border-width: 4px;
}
.form-nnki-candidate .row-answer.-answerShould .mat-radio-button.mat-radio-checked .mat-radio-inner-circle {
  background: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerShould .mat-radio-button .mat-radio-label-content {
  color: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerShould .mat-checkbox .mat-checkbox-frame {
  border-color: var(--app-green-three);
  border-width: 3px;
}
.form-nnki-candidate .row-answer.-answerShould .mat-checkbox .mat-checkbox-label {
  color: var(--app-green-three);
}
.form-nnki-candidate .row-answer.-answerShouldNot .mat-radio-button .mat-radio-outer-circle {
  border-color: var(--app-red-one);
}
.form-nnki-candidate .row-answer.-answerShouldNot .mat-radio-button .mat-radio-inner-circle {
  background: var(--app-red-one);
}
.form-nnki-candidate .row-answer.-answerShouldNot .mat-radio-button .mat-radio-label-content {
  color: var(--app-red-one);
}
.form-nnki-candidate .row-answer.-answerShouldNot .mat-checkbox .mat-checkbox-background {
  background: var(--app-red-one);
}
.form-nnki-candidate .row-answer.-answerShouldNot .mat-checkbox .mat-checkbox-label {
  color: var(--app-red-one);
}
@media (max-width: 767px) {
  .form-nnki-candidate.-daltonien {
    padding-left: 20px;
  }
}
.form-nnki-candidate.-daltonien .row-match,
.form-nnki-candidate.-daltonien .row-answer {
  position: relative;
}
.form-nnki-candidate.-daltonien .row-match .daltonien-icon,
.form-nnki-candidate.-daltonien .row-answer .daltonien-icon {
  position: absolute;
  left: -25px;
}
.form-nnki-candidate.-daltonien .row-match.-answerWin .daltonien-icon.-check, .form-nnki-candidate.-daltonien .row-match.-answerShould .daltonien-icon.-check,
.form-nnki-candidate.-daltonien .row-answer.-answerWin .daltonien-icon.-check,
.form-nnki-candidate.-daltonien .row-answer.-answerShould .daltonien-icon.-check {
  display: block;
  color: var(--app-green-three);
  top: 5px;
}
.form-nnki-candidate.-daltonien .row-match.-answerFail .daltonien-icon.-times, .form-nnki-candidate.-daltonien .row-match.-answerShouldNot .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-answer.-answerFail .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-answer.-answerShouldNot .daltonien-icon.-times {
  display: block;
  color: var(--app-red-one);
  top: 3px;
}
.form-nnki-candidate.-daltonien .row-match.-answerWin .daltonien-icon.-check, .form-nnki-candidate.-daltonien .row-match.-answerShould .daltonien-icon.-check {
  top: 5px;
}
.form-nnki-candidate.-daltonien .row-match.-answerFail .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-match.-answerFail .daltonien-icon.-check, .form-nnki-candidate.-daltonien .row-match.-answerShouldNot .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-match.-answerShouldNot .daltonien-icon.-check, .form-nnki-candidate.-daltonien .row-match.-answerWin .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-match.-answerWin .daltonien-icon.-check, .form-nnki-candidate.-daltonien .row-match.-answerShould .daltonien-icon.-times,
.form-nnki-candidate.-daltonien .row-match.-answerShould .daltonien-icon.-check {
  top: 22px;
}

.mat-datepicker-toggle svg-icon {
  color: var(--theme-gray-twentyseven);
}

.mat-slide-toggle {
  width: 100%;
}
.mat-slide-toggle.mat-slide-toggle-label-before .mat-slide-toggle-bar {
  margin-left: auto;
}

.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard {
  border: 0;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle:focus,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle:focus {
  outline: 0;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard {
  border: 1px solid var(--theme-gray-fifteen);
  font-family: Muli;
  font-weight: bold;
  border-left-width: 0;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard:first-child,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 1px;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard:last-child,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background: var(--theme-white);
  color: var(--site-main-one);
  border-color: var(--site-main-one);
  border-left-width: 1px;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  font-size: 1.4rem;
  line-height: 38px;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-longText .mat-button-toggle.mat-button-toggle-appearance-standard,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-longText .mat-button-toggle.mat-button-toggle-appearance-standard {
  white-space: normal;
}
@media (max-width: 767px) {
  .light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap {
    flex-wrap: wrap;
  }
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard {
  display: flex;
  align-items: center;
  max-width: 190px;
  white-space: normal;
}
@media (max-width: 767px) {
  .light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard {
    max-width: none;
    width: 100%;
    margin-bottom: 10px;
    border-left-width: 1px;
    border-radius: 4px;
  }
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-button,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-button {
  padding: 10px 0;
}
.light-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-button .mat-button-toggle-label-content,
.dark-theme-mode .mat-button-toggle-group.mat-button-toggle-group-appearance-standard.-textWrap .mat-button-toggle.mat-button-toggle-appearance-standard .mat-button-toggle-button .mat-button-toggle-label-content {
  line-height: 150%;
}

.form-nnki-light .form-field-nnki.mat-form-field-appearance-legacy {
  @apply bg-theme-white border border-theme-gray-fifteen;
}
.form-nnki-light .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding-bottom: 4px;
}

.free-gradient {
  @apply text-white;
  background: linear-gradient(119.3deg, var(--app-free-gradient-1) 2.27%, var(--app-free-gradient-2) 86.31%);
}

.premiumplus-gradient {
  @apply text-white;
  background: linear-gradient(155.69deg, var(--app-premiumplus-gradient-1) 1.59%, var(--app-premiumplus-gradient-2) 71.85%);
}

.elite-gradient {
  @apply text-gold;
  background: linear-gradient(155.69deg, var(--app-elite-gradient-1) 1.59%, var(--app-elite-gradient-2) 71.85%);
}

.btn-gradient {
  background-image: linear-gradient(24deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
}

.modal_content {
  @apply relative shadow text-left w-full max-w-full sm:pt-20 sm:w-[465px];
}
.modal_content.-large {
  @apply sm:w-[740px];
}
.modal_content.-xlarge {
  @apply sm:w-[1100px];
}

.mat-modal-closer {
  @apply cursor-pointer fixed sm:absolute top-safeTop sm:top-0 right-0 p-4 z-2 text-white;
}
.mat-modal-closer.-xs-black {
  @apply text-theme-black sm:text-white;
}

.modal-inside {
  @apply sm:rounded overflow-hidden min-h-screen sm:min-h-0 flex sm:block flex-col justify-start bg-theme-white sm:bg-transparent w-screen sm:w-auto pb-safeBottom sm:pb-0;
}
.modal-inside > * {
  @apply w-full sm:w-auto;
}
.modal-inside:before {
  content: "";
  @apply block h-safeTop sm:hidden;
}
.modal-inside.-image {
  @apply bg-transparent justify-center;
}
.modal-inside.-video {
  @apply bg-theme-white sm:p-10 w-screen max-w-[1000px] sm:w-[calc(100vw-40px)];
}
.modal-inside.-candidate-challenge {
  @apply bg-theme-white transition-backgroundColor;
}
.modal-inside.-candidate-challenge > * {
  @apply flex flex-col min-h-screen sm:min-h-0;
}
.modal-inside p {
  @apply mb-4;
}
.modal-inside p strong {
  @apply text-site-main-one;
}
.modal-inside ul {
  @apply list-disc list-inside text-left text-15 space-y-4;
}
.modal-inside ul li a {
  @apply text-site-main-one;
}
.modal-inside ul li a:hover {
  color: inherit;
}

.modal-header {
  @apply rounded-t p-8 sm:px-16 bg-cover bg-no-repeat;
  background-image: url(/assets/images/backgrounds/circles/circles_blue.svg);
}
.modal-header .modal-header-title {
  @apply text-white text-30 font-semibold flex items-center;
}
.modal-header .modal-header-title > svg-icon {
  @apply mr-4 sm:mr-10;
}

.modal-subtitle {
  @apply text-theme-black font-extrabold text-16 mb-8 px-8 sm:px-0;
}

.modal-grid-container {
  @apply grid-cols-[repeat(auto-fill,minmax(130px,1fr))] gap-x-4 gap-y-12;
}

.modal-circles {
  @apply h-[269px] flex items-center justify-center relative;
}
.modal-circles.-noImage {
  @apply h-[114px];
}
.modal-circles .background {
  @apply absolute w-[150%] top-0 bottom-0 rounded-b-full -left-1/4;
  background: linear-gradient(144.11deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
}
.modal-circles .background.-purple {
  background: linear-gradient(135deg, var(--site-section-2-gradient-1) 0%, var(--site-section-2-gradient-2) 100%);
}
.modal-circles .background.-red {
  background: linear-gradient(126.67deg, var(--app-red-three) 0%, var(--app-red-four) 100%);
  opacity: 0.5;
}
.modal-circles .background.-green {
  background: linear-gradient(127.18deg, var(--site-section-gradient-1) 0%, var(--site-section-gradient-2) 100%);
}
.modal-circles .background.-light-green {
  background: linear-gradient(127.18deg, var(--site-section-gradient-1) 0%, var(--site-section-gradient-2) 100%);
  @apply opacity-30;
}
.modal-circles .circle-1 {
  border-radius: 50%;
  background: transparent;
  opacity: 48%;
  border: 15px solid var(--app-white);
  width: 232px;
  height: 232px;
  position: absolute;
  left: -150px;
  top: -90px;
  bottom: auto;
  right: auto;
}
.modal-circles .circle-2 {
  border-radius: 50%;
  background: transparent;
  opacity: 48%;
  border: 15px solid var(--app-white);
  width: 124px;
  height: 124px;
  position: absolute;
  left: auto;
  top: -15px;
  bottom: auto;
  right: -50px;
}
.modal-circles .circle-3 {
  border-radius: 50%;
  background: transparent;
  opacity: 48%;
  border: 15px solid var(--app-white);
  width: 158px;
  height: 158px;
  position: absolute;
  left: auto;
  top: auto;
  bottom: -100px;
  right: -10px;
}
.modal-circles img {
  @apply relative max-h-[190px];
}

.modal-text {
  @apply relative -mt-12 flex flex-col items-center p-8;
}

.modal-picto {
  @apply mb-4 border-[3px] border-theme-white shadow rounded-full overflow-hidden h-[64px] w-[64px];
}
.modal-picto.-picto-svg {
  background: linear-gradient(144.11deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
  @apply text-white flex items-center justify-center;
}
.modal-picto.-picto-svg.-purple {
  background: linear-gradient(135deg, var(--site-section-2-gradient-1) 0%, var(--site-section-2-gradient-2) 100%);
}
.modal-picto.-picto-svg.-red {
  background: linear-gradient(126.67deg, var(--app-red-three) 0%, var(--app-red-four) 100%);
}
.modal-picto.-picto-svg.-green {
  background: linear-gradient(127.18deg, var(--site-section-gradient-1) 0%, var(--site-section-gradient-2) 100%);
}

.modal-title {
  @apply text-site-main-one text-20 font-semibold mb-6 text-center;
}
.modal-title.-red {
  @apply text-red-two;
}

.modal-theme {
  @apply text-16 font-extrabold mb-4 text-center;
}

.modal-buttons {
  @apply fixed sm:static bottom-0 left-0 right-0 shadow sm:shadow-none z-2 px-4 sm:px-0 py-4 sm:py-8 w-full mt-6 bg-theme-white gap-4 flex;
}
.modal-buttons:after {
  content: "";
  @apply block sm:hidden h-safeBottom;
}
.modal-buttons > * {
  @apply mx-auto w-[300px] max-w-full;
}

.mat-tab-group {
  font-family: Muli;
}

.tabs-bar,
.mat-tab-group .mat-tab-header {
  padding: 0 20px;
  border-bottom: 1px solid var(--theme-gray-seven);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .tabs-bar,
.mat-tab-group .mat-tab-header {
    padding: 0;
  }
}
.tabs-bar .mat-tab-nav-bar,
.mat-tab-group .mat-tab-header .mat-tab-nav-bar {
  border-bottom: 0;
  flex-basis: 0;
}
.tabs-bar .mat-tab-list,
.mat-tab-group .mat-tab-header .mat-tab-list {
  max-width: 100%;
}
.tabs-bar .mat-tab-list .mat-tab-link > span,
.mat-tab-group .mat-tab-header .mat-tab-list .mat-tab-link > span {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tabs-bar.-no-border,
.mat-tab-group.-no-border .mat-tab-header {
  border-bottom: 0;
}

.tabs-bar.-fixed,
.mat-tab-group.-fixed .mat-tab-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--theme-gray-twentyone);
  @apply z-tabs;
}

@media (max-width: 767px) {
  .tabs-bar.-fixed {
    overflow: hidden;
  }
  .tabs-bar.-fixed .mat-tab-header {
    flex-shrink: 1;
  }
  .tabs-bar.-fixed .mat-tab-header .mat-tab-list {
    overflow: hidden;
  }
  .tabs-bar.-fixed .mat-tab-header .mat-tab-list .mat-tab-link > span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.tabs-group-nnki,
.tabs-nnki {
  align-self: flex-end;
  flex-grow: 1;
}
@media (min-width: 991px) {
  .tabs-group-nnki.tabs-hidden-on-desktop,
.tabs-nnki.tabs-hidden-on-desktop {
    visibility: hidden !important;
  }
}
@media (min-width: 991px) {
  .tabs-group-nnki.tabs-hidden-on-desktop .mat-ink-bar,
.tabs-nnki.tabs-hidden-on-desktop .mat-ink-bar {
    visibility: hidden !important;
  }
}
.tabs-group-nnki .mat-tab-link,
.tabs-group-nnki .mat-tab-label,
.tabs-nnki .mat-tab-link,
.tabs-nnki .mat-tab-label {
  font-family: Muli;
  height: 60px;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  min-width: 160px;
  opacity: 1;
  text-transform: uppercase;
  color: var(--theme-gray-five);
  font-size: 1.3rem;
  font-weight: 800;
  overflow: visible;
}
@media (max-width: 1199px) {
  .tabs-group-nnki .mat-tab-link,
.tabs-group-nnki .mat-tab-label,
.tabs-nnki .mat-tab-link,
.tabs-nnki .mat-tab-label {
    min-width: 130px;
  }
}
@media (max-width: 991px) {
  .tabs-group-nnki .mat-tab-link,
.tabs-group-nnki .mat-tab-label,
.tabs-nnki .mat-tab-link,
.tabs-nnki .mat-tab-label {
    min-width: 0;
  }
}
@media (max-width: 767px) {
  .tabs-group-nnki .mat-tab-link,
.tabs-group-nnki .mat-tab-label,
.tabs-nnki .mat-tab-link,
.tabs-nnki .mat-tab-label {
    height: 50px;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1.1rem;
  }
}
@media (max-width: 359px) {
  .tabs-group-nnki .mat-tab-link,
.tabs-group-nnki .mat-tab-label,
.tabs-nnki .mat-tab-link,
.tabs-nnki .mat-tab-label {
    font-size: 1rem;
  }
}
.tabs-group-nnki .mat-tab-link:first-child,
.tabs-group-nnki .mat-tab-label:first-child,
.tabs-nnki .mat-tab-link:first-child,
.tabs-nnki .mat-tab-label:first-child {
  margin-left: 0;
}
.tabs-group-nnki .mat-tab-link:last-child,
.tabs-group-nnki .mat-tab-label:last-child,
.tabs-nnki .mat-tab-link:last-child,
.tabs-nnki .mat-tab-label:last-child {
  margin-right: 0;
}
.tabs-group-nnki .mat-tab-link.mat-tab-label-active,
.tabs-group-nnki .mat-tab-link :hover,
.tabs-group-nnki .mat-tab-label.mat-tab-label-active,
.tabs-group-nnki .mat-tab-label :hover,
.tabs-nnki .mat-tab-link.mat-tab-label-active,
.tabs-nnki .mat-tab-link :hover,
.tabs-nnki .mat-tab-label.mat-tab-label-active,
.tabs-nnki .mat-tab-label :hover {
  color: var(--site-theme-accent);
}
.tabs-group-nnki .mat-tab-link .tab-with-image,
.tabs-group-nnki .mat-tab-label .tab-with-image,
.tabs-nnki .mat-tab-link .tab-with-image,
.tabs-nnki .mat-tab-label .tab-with-image {
  display: flex;
  align-items: center;
}
.tabs-group-nnki .mat-tab-link .tab-with-image .logo_recomed,
.tabs-group-nnki .mat-tab-label .tab-with-image .logo_recomed,
.tabs-nnki .mat-tab-link .tab-with-image .logo_recomed,
.tabs-nnki .mat-tab-label .tab-with-image .logo_recomed {
  height: 30px;
  position: relative;
  top: -1px;
}
@media (max-width: 767px) {
  .tabs-group-nnki .mat-tab-link .tab-with-image .logo_recomed,
.tabs-group-nnki .mat-tab-label .tab-with-image .logo_recomed,
.tabs-nnki .mat-tab-link .tab-with-image .logo_recomed,
.tabs-nnki .mat-tab-label .tab-with-image .logo_recomed {
    height: 19px;
  }
}
.tabs-group-nnki .mat-ink-bar,
.tabs-nnki .mat-ink-bar {
  background: var(--site-theme-accent) !important;
}

.container-route {
  overflow: hidden;
  display: flex;
}
@media (max-width: 767px) {
  .container-route.-withMobileButton {
    padding-bottom: 110px;
  }
}
@media (max-width: 767px) {
  .container-route.-withMobileButton .below-header {
    padding-bottom: 0;
  }
}

.container-group {
  min-height: 100vh;
}

.content-component.-inSession .tabs-bar.-fixed,
.content-component.-inSession .mat-tab-group.-fixed .mat-tab-header {
  padding-left: 120px;
  padding-right: 60px;
  padding-top: var(--safe-area-inset-top);
}
@media (max-width: 1199px) {
  .content-component.-inSession .tabs-bar.-fixed,
.content-component.-inSession .mat-tab-group.-fixed .mat-tab-header {
    padding-left: 80px;
    padding-right: 40px;
  }
}
@media (max-width: 991px) {
  .content-component.-inSession .tabs-bar.-fixed,
.content-component.-inSession .mat-tab-group.-fixed .mat-tab-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 767px) {
  .content-component.-inSession .tabs-bar.-fixed,
.content-component.-inSession .mat-tab-group.-fixed .mat-tab-header {
    padding-left: 0;
    padding-right: 0;
  }
}
.content-component.-inSession .tabs-group-nnki .mat-tab-link,
.content-component.-inSession .tabs-group-nnki .mat-tab-label,
.content-component.-inSession .tabs-nnki .mat-tab-link,
.content-component.-inSession .tabs-nnki .mat-tab-label {
  height: 63px;
}
@media (max-width: 767px) {
  .content-component.-inSession .tabs-buttons {
    bottom: 20px;
  }
}
.content-component.-inSession .container-group.-tabsFixed,
.content-component.-inSession .container-route.-tabsFixed {
  padding-top: 63px;
}

.content-tab {
  display: block;
  flex-shrink: 0;
  width: 100%;
}

.tabs-back,
.mat-tab-group.-withBack .mat-tab-header .mat-tab-labels > :first-child {
  margin-right: 60px;
  min-width: 0;
  padding: 0;
  flex-shrink: 0;
}
@media (max-width: 1199px) {
  .tabs-back,
.mat-tab-group.-withBack .mat-tab-header .mat-tab-labels > :first-child {
    margin-right: 20px;
  }
}
@media (max-width: 767px) {
  .tabs-back,
.mat-tab-group.-withBack .mat-tab-header .mat-tab-labels > :first-child {
    margin-right: 10px;
    padding-left: 10px;
  }
}

.tabs-buttons {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left: 20px;
  font-size: 1.6rem;
}
.tabs-buttons > .btn-nnki {
  margin-right: 20px;
}
.tabs-buttons > .btn-nnki:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  .tabs-buttons {
    position: fixed;
    @apply z-tabs;
    bottom: calc(80px + var(--safe-area-inset-bottom));
    right: 20px;
  }
}

.mat-tab-header-pagination {
  display: none !important;
}

.tabs-inside-nnki.tabs-inside-bar,
.tabs-inside-nnki .mat-tab-header {
  padding: 0 50px;
  margin-bottom: 15px;
  border: 0;
}
@media (max-width: 767px) {
  .tabs-inside-nnki.tabs-inside-bar,
.tabs-inside-nnki .mat-tab-header {
    padding: 0 20px;
  }
}
.tabs-inside-nnki .mat-tab-label,
.tabs-inside-nnki .mat-tab-link {
  min-width: 0;
}
.tabs-inside-nnki .mat-tab-label-content,
.tabs-inside-nnki .mat-tab-link {
  color: var(--theme-white);
  font-size: 1.3rem;
  font-weight: bold;
}
.tabs-inside-nnki .mat-tab-label-content svg-icon,
.tabs-inside-nnki .mat-tab-link svg-icon {
  margin-right: 10px;
}
.tabs-inside-nnki .mat-tab-label-content.mat-tab-label-active,
.tabs-inside-nnki .mat-tab-link.mat-tab-label-active {
  opacity: 1;
}
.tabs-inside-nnki .mat-ink-bar {
  background: var(--theme-white) !important;
}
.tabs-inside-nnki.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
  display: flex !important;
  box-shadow: none;
}
.tabs-inside-nnki.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination .mat-tab-header-pagination-chevron {
  border-color: var(--theme-white);
}

.nnki-background-image, .nnki-square-background-image, .nnki-rounded-background-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.nnki-square-background-image:before, .nnki-rounded-background-image:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.nnki-rounded-background-image {
  border-radius: 50%;
  width: 60px;
}

.user-image-sunray {
  position: relative;
}
.user-image-sunray .sunray {
  background-image: url(/assets/images/backgrounds/sunray.png);
  background-size: 100%;
  width: 220px;
  height: 220px;
  position: absolute;
  top: -18px;
  left: -18px;
}
.user-image-sunray .nnki-rounded-background-image {
  width: 184px;
  position: relative;
}
.user-image-sunray .user-position {
  position: absolute;
  right: -10px;
  top: 20px;
  border-radius: 14px;
  background: var(--site-main-one);
  color: var(--app-white);
  font-size: 1.8rem;
  font-weight: 800;
  padding: 8px 12px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 100%;
}
.user-image-sunray .user-position svg-icon {
  margin-right: 5px;
}

.users-pictures-list {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 10px;
}
.users-pictures-list .nnki-rounded-background-image {
  width: 37px;
  flex-shrink: 0;
  margin-left: -16px;
  border: 1px solid var(--theme-white);
}
.users-pictures-list .nnki-rounded-background-image:first-child {
  margin-left: 0;
}
.users-pictures-list .user-plus {
  margin-left: -16px;
  width: 37px;
  height: 37px;
  flex-shrink: 0;
  background: linear-gradient(144.11deg, var(--site-card-gradient-1) 0%, var(--site-card-gradient-2) 100%);
  color: var(--theme-white);
  font-size: 1.4rem;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.users-pictures-list.-compressed .nnki-rounded-background-image,
.users-pictures-list.-compressed .user-plus {
  margin-left: -23px;
}
.users-pictures-list.-compressed .nnki-rounded-background-image:first-child,
.users-pictures-list.-compressed .user-plus:first-child {
  margin-left: 0;
}

svg-icon {
  @apply flex items-center justify-center;
}
svg-icon svg {
  height: auto;
}

.spinner_element_container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_100_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner_button_container {
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.widgets {
  display: flex;
  align-items: center;
  justify-content: start;
  @apply space-x-8;
  flex-grow: 1;
}

app-widget-time-remaining,
.widget-time-remaining {
  display: flex;
  align-items: center;
  max-width: 600px;
  flex-grow: 1;
}
@media (max-width: 767px) {
  app-widget-time-remaining,
.widget-time-remaining {
    flex-wrap: wrap;
  }
}
app-widget-time-remaining > *,
.widget-time-remaining > * {
  margin-left: 10px;
  margin-right: 10px;
}
app-widget-time-remaining > *:first-child,
.widget-time-remaining > *:first-child {
  margin-left: 0;
}
app-widget-time-remaining > *:last-child,
.widget-time-remaining > *:last-child {
  margin-right: 0;
}
@media (max-width: 767px) {
  app-widget-time-remaining > *,
.widget-time-remaining > * {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
app-widget-time-remaining.-disabled,
.widget-time-remaining.-disabled {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
app-widget-time-remaining.-disabled .text,
app-widget-time-remaining.-disabled .ecni_progress_bar,
.widget-time-remaining.-disabled .text,
.widget-time-remaining.-disabled .ecni_progress_bar {
  opacity: 0.5;
}
app-widget-time-remaining .text,
.widget-time-remaining .text {
  font-size: 1.5rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  app-widget-time-remaining .text,
.widget-time-remaining .text {
    font-size: 1.1rem;
    text-align: center;
  }
}
app-widget-time-remaining .text.-finished,
.widget-time-remaining .text.-finished {
  color: var(--app-orange-one);
}
app-widget-time-remaining .ecni_progress_bar,
.widget-time-remaining .ecni_progress_bar {
  height: 5px;
  width: auto;
  flex-grow: 1;
}
@media (max-width: 767px) {
  app-widget-time-remaining .ecni_progress_bar,
.widget-time-remaining .ecni_progress_bar {
    margin-top: 5px;
    width: 100%;
  }
}

.widget-buttons {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.widget-buttons:first-child {
  margin-left: 0;
}
.widget-buttons .widget-button {
  cursor: pointer;
  margin: 0 10px;
  position: relative;
  color: var(--theme-black);
}
.widget-buttons .widget-button.-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.widget-buttons .widget-button.-greyed > svg-icon {
  opacity: 0.5;
}
@media (max-width: 767px) {
  .widget-buttons.-onBottomMobile {
    position: fixed;
    @apply z-tabs;
    bottom: calc(20px + var(--safe-area-inset-bottom));
    right: 20px;
  }
  .widget-buttons.-onBottomMobile .widget-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--site-main-one);
    border-radius: 50%;
    width: 70px;
    height: 70px;
    padding: 0;
    @apply shadow;
    margin: 0;
    color: var(--theme-white);
  }
  .widget-buttons.-onBottomMobile .widget-button > svg-icon {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}

.widget-progressions {
  display: flex;
  align-items: stretch;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .widget-progressions {
    margin-left: 0;
    display: none;
  }
}
.widget-progressions.-mobile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .widget-progressions.-mobile {
    display: none;
  }
}
.widget-progressions.-mobile app-widget-progression {
  @apply rounded;
  @apply shadow;
  padding: 15px 13px;
  margin-bottom: 10px;
  flex-grow: 1;
}
.widget-progressions.-mobile app-widget-progression:nth-child(1) {
  background: var(--site-main-one);
}
.widget-progressions.-mobile app-widget-progression:nth-child(2) {
  background: var(--app-green-one);
}
.widget-progressions.-mobile app-widget-progression:first-child {
  margin-left: 10px;
}
.widget-progressions.-mobile app-widget-progression:last-child {
  margin-right: 10px;
  margin-bottom: 0;
}
.widget-progressions.-mobile .text-container {
  color: var(--theme-white);
}
.widget-progressions app-widget-progression {
  margin: 0 10px;
}
.widget-progressions app-widget-progression:first-child {
  margin-left: 0;
}
.widget-progressions app-widget-progression:last-child {
  margin-right: 0;
}
.widget-progressions .widget-progression {
  display: flex;
  align-items: center;
}
.widget-progressions .widget-progression .text-container {
  white-space: nowrap;
}
@media (max-width: 767px) {
  .widget-progressions .widget-progression .text-container {
    line-height: 120%;
  }
}
.widget-progressions .widget-progression .text-container .label {
  font-size: 1.3rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .widget-progressions .widget-progression .text-container .label {
    font-size: 1rem;
  }
}
.widget-progressions .widget-progression .text-container .label svg-icon {
  margin-left: 5px;
}
.widget-progressions .widget-progression .text-container .value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.7rem;
  font-weight: 800;
}
@media (max-width: 767px) {
  .widget-progressions .widget-progression .text-container .value {
    font-size: 1.4rem;
  }
}
.widget-progressions .widget-progression .text-container .value small {
  font-size: 1.2rem;
  font-weight: 600;
}
.widget-progressions .widget-progression .text-container .value > span {
  margin-right: 15px;
}
.widget-progressions .widget-progression .text-container .value > span:last-child {
  margin-right: 0;
}

.card--log_session {
  background: var(--theme-white);
  margin-bottom: 0;
  overflow: hidden;
  @apply rounded;
  @apply shadow;
}

.log-table_container {
  width: 100%;
  overflow: auto;
}

.log-table_header {
  display: flex;
  align-items: center;
  height: 60px;
  background: var(--theme-white);
}
@media (max-width: 991px) {
  .log-table_header {
    display: none;
  }
}
.log-table_header > div {
  padding: 0 20px;
  color: var(--site-main-one);
  font-size: 1.3rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.log-table_header.-with-images > div {
  display: flex;
  align-items: center;
}
.log-table_header.-with-images > div svg-icon:not(.icon-arrow-bottom) {
  margin-right: 10px;
  color: var(--theme-black);
}
.log-table_header.-with-images > div span {
  line-height: 100%;
}
.log-table_header.-with-images > div .icon-arrow-bottom {
  margin-left: 10px;
  color: var(--site-main-one);
}
.log-table_header.-with-images > div .icon-arrow-bottom.reversed {
  transform: rotate(180deg);
}

.mat-accordion.accordion-log .mat-expansion-panel {
  border-radius: 0 !important;
  border: 0;
  box-shadow: none;
}
.mat-accordion.accordion-log .mat-expansion-panel.-self {
  background: var(--app-yellow-two);
}
.mat-accordion.accordion-log.-ranking .mat-expansion-panel:nth-child(2n+1) {
  background: var(--site-theme-main-three);
}
.mat-accordion.accordion-log .mat-expansion-panel-spacing {
  margin-top: 0;
  margin-bottom: 0;
}
.mat-accordion.accordion-log .mat-expansion-panel-header {
  font-weight: bold;
  font-size: 1.3rem;
  padding: 0;
  height: 60px !important;
  background: transparent !important;
}
@media (max-width: 991px) {
  .mat-accordion.accordion-log .mat-expansion-panel-header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.mat-accordion.accordion-log .mat-expansion-panel-header[aria-disabled=true] {
  color: inherit;
  cursor: default;
}
.mat-accordion.accordion-log .mat-expansion-panel-header .mat-content {
  align-items: center;
  height: 60px;
}
.mat-accordion.accordion-log .mat-expansion-panel-header .mat-content > div,
.mat-accordion.accordion-log .mat-expansion-panel-header .mat-content > a {
  padding: 0 20px;
}
@media (max-width: 767px) {
  .mat-accordion.accordion-log .mat-expansion-panel-header .mat-content > div,
.mat-accordion.accordion-log .mat-expansion-panel-header .mat-content > a {
    padding: 0 5px;
  }
}
.mat-accordion.accordion-log .accordion-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1.1rem;
  font-weight: bold;
}
.mat-accordion.accordion-log .accordion-row.-lefted {
  justify-content: flex-start;
}
.mat-accordion.accordion-log .accordion-row.-lefted .data-cell {
  flex-grow: 1;
  min-width: 50%;
  margin: 5px 0;
  padding: 0 20px;
}
@media (max-width: 767px) {
  .mat-accordion.accordion-log .accordion-row.-lefted .data-cell {
    padding: 0 5px;
  }
}
.mat-accordion.accordion-log .accordion-row .data-cell {
  display: flex;
  margin-left: 15px;
  margin-right: 15px;
}
.mat-accordion.accordion-log .accordion-row .data-cell:first-child {
  margin-left: 0;
}
.mat-accordion.accordion-log .accordion-row .data-cell:last-child {
  margin-right: 0;
}
.mat-accordion.accordion-log .accordion-row .data-cell svg-icon {
  color: var(--theme-black);
  justify-content: flex-start;
}
.mat-accordion.accordion-log .accordion-row .data-cell > span {
  margin-left: 5px;
  margin-right: 5px;
}
.mat-accordion.accordion-log .accordion-row .data-cell > span:first-child {
  margin-left: 0;
}
.mat-accordion.accordion-log .accordion-row .data-cell > span:last-child {
  margin-right: 0;
}
.mat-accordion.accordion-log .accordion-row .name {
  color: var(--theme-gray-five);
}
.mat-accordion.accordion-log .accordion-row .value {
  color: var(--theme-black);
}
.mat-accordion.accordion-log .accordion-row .btn-nnki {
  margin-top: 10px;
  width: 300px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.mat-accordion.accordion-log small {
  color: var(--theme-gray-five);
  font-weight: bold;
  font-size: 1.2rem;
}

.session_content {
  display: flex;
  align-items: center;
}
.session_content .session_picto {
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--theme-white);
  margin-right: 10px;
}
.session_content .session_picto.picto_item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--app-yellow-one);
  font-size: 1.3rem;
  font-weight: 800;
  line-height: 100%;
}
.session_content .session_picto.picto_item.-random {
  background: var(--app-purple-one);
}
.session_content .session_picto.picto_item.-folder {
  background: var(--site-main-one);
}
.session_content .session_picto.picto_item.-folder img {
  width: 20px;
}
.session_content .session_name {
  @apply line-clamp-2;
  padding-bottom: 1px;
}

.cell_reussite .ecni_progress_bar {
  width: 100%;
}

.block-with-slider {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: -20px;
  margin-right: -20px;
  margin-top: -20px;
  margin-bottom: 20px;
  position: relative;
}
.block-with-slider .swiper-container {
  padding-top: 20px;
  padding-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;
}
.block-with-slider .card {
  margin-bottom: 0;
}

.swiper-nav {
  margin-left: auto;
  display: flex;
}
.swiper-nav svg-icon {
  color: var(--theme-black);
}
.swiper-nav.-white svg-icon, .card--nnki-question .swiper-nav.card-content.-correction svg-icon {
  color: var(--theme-white);
}

.swiper-button-prev,
.swiper-button-next {
  position: static;
  width: auto;
  height: auto;
  margin-left: 10px;
  margin-top: 0;
  padding: 5px;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  content: "";
  background: none;
}

.swiper-pag {
  display: flex;
  justify-content: center;
  margin-top: -30px;
  position: relative;
  @apply z-1;
}
.swiper-pag .swiper-pagination-bullet {
  margin: 0 3px;
}
@media (min-width: 768px) {
  .swiper-pag .swiper-pagination-bullet {
    display: none;
  }
}

.swiper-wrapper {
  align-items: stretch;
  height: auto;
}

.swiper-slide {
  width: 470px;
  max-width: 100%;
  height: auto;
}
.swiper-slide.-margin {
  width: 100px;
}
@media (max-width: 767px) {
  .swiper-slide.-margin {
    display: none;
  }
}
.swiper-slide.-exam {
  width: 470px;
}
.swiper-slide.-training {
  width: 450px;
}
.swiper-slide.-video {
  width: 360px;
}
.swiper-slide.-image {
  width: 300px;
}
.swiper-slide.-discipline {
  width: 300px;
}
.swiper-slide.-disciplineChallenge {
  width: 380px;
}
@media (max-width: 1199px) {
  .swiper-slide.-disciplineChallenge {
    width: 340px;
  }
}
@media (max-width: 767px) {
  .swiper-slide.-disciplineChallenge {
    width: 300px;
  }
}
.swiper-slide.-disciplineChallenge app-discipline-rank {
  display: block;
  margin-bottom: 35px;
}
.swiper-slide.-major {
  width: 100%;
}

.swiper-gradient {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 200px;
  background-image: linear-gradient(90deg, var(--transparent) 0%, var(--theme-white) 100%);
  @apply z-1;
  pointer-events: none;
}
@media (max-width: 767px) {
  .swiper-gradient {
    display: none;
  }
}

[appCollapsibleElement] {
  @apply h-0 overflow-hidden transition-height;
}
[appCollapsibleElement].-opened {
  @apply h-auto;
}

[appGradientHidden] {
  @apply overflow-hidden transition-height relative cursor-pointer;
}
[appGradientHidden].-displayed {
  @apply cursor-auto h-auto;
}
[appGradientHidden].-displayed .gradient_mask {
  @apply opacity-0 pointer-events-none;
}
[appGradientHidden] .gradient_mask {
  @apply absolute top-0 bottom-0 right-0 left-0 z-1 transition-opacity;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 50%, var(--theme-white) 100%);
}
[appGradientHidden].-gray .gradient_mask {
  background: linear-gradient(to bottom, var(--transparent) 0%, var(--site-theme-main-five) 100%);
}

.google-visualization-tooltip-item-list > :first-child {
  display: none;
}

.grecaptcha-badge {
  display: none !important;
}

ngx-extended-pdf-viewer #mainContainer {
  min-width: 0 !important;
}

editor {
  width: 100%;
}

.tox-tinymce-aux {
  @apply z-editor !important;
}

.logo_site_text {
  margin-left: 10px;
  font-family: "Muli";
  font-weight: 700;
  font-size: 2rem;
  color: var(--site-main-one);
}
.logo_site_text.-prepmir {
  font-family: "Muli";
  font-weight: 800;
  font-size: 1.8rem;
  color: var(--theme-black);
}
.logo_site_text.-prepmir strong {
  font-family: Montserrat;
  font-size: 2rem;
  color: var(--site-main-one);
  font-weight: bold;
}

.mat-stepper-vertical,
.mat-stepper-horizontal {
  font-family: Muli;
}
@media (max-width: 767px) {
  .mat-stepper-vertical .mat-horizontal-component-container,
.mat-stepper-horizontal .mat-horizontal-component-container {
    padding: 0 10px 10px 10px;
  }
}
@media (min-width: 768px) {
  .mat-stepper-vertical .mat-step-icon,
.mat-stepper-horizontal .mat-step-icon {
    height: 30px;
    width: 30px;
  }
}
.mat-stepper-vertical .mat-step-label,
.mat-stepper-horizontal .mat-step-label {
  min-width: 0;
}

.zap_canvas_block {
  display: flex;
}

.zap_canvas_container {
  position: relative;
}

.zap_canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.zap_image {
  max-width: 100%;
  position: relative;
}

.landing-subtitle, .landing-subtitle-dash, .landing-subtitle-centered {
  @apply font-landing font-semibold leading-none text-center mx-auto;
}
.landing-subtitle strong, .landing-subtitle-dash strong, .landing-subtitle-centered strong {
  @apply font-extrabold;
}

.landing-subtitle-centered {
  @apply leading-none text-center mx-auto;
}

.landing-subtitle-dash {
  @apply leading-tight text-40 text-center relative mb-20;
}
@screen md {
  .landing-subtitle-dash {
    @apply text-left;
  }
}
.landing-subtitle-dash:before {
  content: "";
  @apply w-4 h-24 rounded bg-site-main-one absolute -left-14 top-0;
}

.landing-gradient {
  @apply absolute left-0 right-0 bottom-0 h-40;
}

.testimony-block {
  @apply flex flex-col items-center w-full;
}
@screen md {
  .testimony-block {
    @apply items-start w-[420px];
  }
}
.testimony-block .testimony-title {
  @apply font-bold text-20 mb-14 text-center;
  grid-area: testimony-title;
}
.testimony-block .testimony-title ::ng-deep strong {
  @apply font-extrabold;
}
@screen md {
  .testimony-block .testimony-title {
    @apply text-left;
  }
}
.testimony-block .testimony-separator {
  @apply border-t border-dashed border-theme-gray-five w-full mb-12 md:mr-auto;
  grid-area: testimony-separator;
}
@screen md {
  .testimony-block .testimony-separator {
    @apply w-[388px];
  }
}
.testimony-block .testimony-text {
  @apply italic text-15 mb-12;
  grid-area: testimony-text;
}
.testimony-block .testimony-author {
  @apply flex items-center mr-auto;
  grid-area: testimony-author;
}
.testimony-block .testimony-author .testimony-author-icon {
  @apply mr-12 flex-shrink-0;
}
.testimony-block .testimony-author .testimony-author-content {
  @apply grow font-landing font-semibold text-20;
}
.testimony-block .testimony-author .testimony-author-content .testimony-author-position {
  @apply opacity-70;
}

.section-ancrage {
  @apply pb-40 md:pb-80;
}
.section-ancrage.-onLanding {
  background-image: linear-gradient(to top, var(--site-theme-main-four), var(--theme-white));
}
.section-ancrage .container--landing {
  @apply grid gap-x-8 md:gap-x-24 justify-items-center items-center;
  grid-template-areas: "subtitle" "testimony-title" "image" "testimony-separator" "testimony-text" "testimony-author";
  grid-template-columns: 100%;
}
@screen md {
  .section-ancrage .container--landing {
    grid-template-areas: "subtitle subtitle" "image testimony-title" "image testimony-separator" "image testimony-text" "image testimony-author";
    grid-template-columns: minmax(0, 1fr) 445px;
  }
}
.section-ancrage .landing-subtitle-centered {
  grid-area: subtitle;
}
@screen md {
  .section-ancrage .landing-subtitle-centered {
    width: 625px;
  }
}
.section-ancrage .ancrage-image {
  grid-area: image;
  width: 555px;
}
.section-ancrage .testimony-block {
  @apply contents;
}
.section-ancrage .testimony-block .testimony-separator {
  @apply mt-12 md:mt-0;
}
.section-ancrage .landing-gradient {
  background: linear-gradient(-177deg, var(--transparent) 0%, var(--transparent) 50%, var(--theme-white) 50%, var(--theme-white) 100%);
}

.section-screenshot {
  @apply pt-12 pb-32 md:py-32;
}
.section-screenshot .container--landing {
  @apply grid gap-x-8 md:gap-x-24 justify-items-center items-center;
  grid-template-areas: "subtitle" "testimony-title" "image" "testimony-separator" "testimony-text" "testimony-author";
  grid-template-columns: 100%;
}
@screen md {
  .section-screenshot .container--landing {
    grid-template-areas: "subtitle image" "testimony-title image" "testimony-separator image" "testimony-text image" "testimony-author image";
    grid-template-columns: 445px minmax(0, 1fr);
  }
}
.section-screenshot .container--landing .landing-subtitle-dash {
  grid-area: subtitle;
}
.section-screenshot .container--landing .screenshot-image {
  grid-area: image;
  @apply max-w-full flex justify-start;
}
@screen md {
  .section-screenshot .container--landing .screenshot-image {
    width: 636px;
  }
}
.section-screenshot .container--landing .screenshot-image .screenshot-inside {
  @apply shadow rounded max-w-none;
}
.section-screenshot .container--landing .testimony-block {
  @apply contents;
}
.section-screenshot .container--landing .testimony-block .testimony-separator {
  @apply mt-12 md:mt-0;
}
.section-screenshot.-inversed {
  @apply bg-site-theme-main-four;
}
@screen md {
  .section-screenshot.-inversed .container--landing {
    grid-template-areas: "image subtitle" "image testimony-title" "image testimony-separator" "image testimony-text" "image testimony-author";
    grid-template-columns: minmax(0, 1fr) 445px;
  }
}
.section-screenshot.-inversed .container--landing .screenshot-image {
  @apply md:justify-end;
}
.section-screenshot .landing-gradient {
  background: linear-gradient(-3deg, var(--site-theme-main-four) 0%, var(--site-theme-main-four) 50%, var(--transparent) 50%, var(--transparent) 100%);
}

.writer-list-header {
  @apply grid grid-cols-1 content-start sm:grid-cols-[1fr_auto] gap-4 mb-8;
}

.writer-filters {
  @apply block mb-8;
}
.writer-filters .mat-expansion-panel:not(.mat-expanded) {
  @apply border-0;
}
.writer-filters .mat-expansion-panel-body {
  @apply p-8;
}

.writer-table-container {
  @apply w-full overflow-x-auto;
}
.writer-table-container .mat-table {
  @apply min-w-full border border-site-theme-main-two;
}
.writer-table-container .mat-table .mat-header-cell {
  @apply bg-site-theme-main-five text-site-main-one font-bold;
}
.writer-table-container .mat-table .mat-header-cell,
.writer-table-container .mat-table .mat-cell {
  @apply px-4 whitespace-nowrap max-w-[200px] truncate;
}
.writer-table-container .mat-table .mat-cell > a,
.writer-table-container .mat-table .clickable-content {
  @apply cursor-pointer text-site-main-one hover:underline;
}
.writer-table-container .mat-table .mat-cell app-button {
  @apply w-min block;
}
.writer-table-container .mat-table .multiple-lines-content {
  @apply line-clamp-2 whitespace-normal cursor-pointer min-w-[180px];
}

.loading-mask {
  @apply absolute top-0 bottom-0 left-0 right-0 bg-theme-white opacity-50 flex items-center justify-center z-above;
}

.section--public {
  @apply min-h-screen overflow-hidden relative pt-publicNavbarHeight;
}
@media (max-width: 991px) {
  .section--public {
    @apply pt-publicNavbarSmallHeight;
  }
}
.section--public.background--wave:before {
  @apply left-[380px] md:left-[calc(50vw-512px+380px)] right-auto;
}

.background--wave:before {
  content: "";
  @apply hidden sm:block absolute top-0;
  height: 836px;
  width: 1350px;
  transform: scaleX(-1) rotate(-45deg);
  border-radius: 242px;
  background: linear-gradient(341.57deg, var(--site-public-gradient-1) 0%, var(--site-public-gradient-2) 100%);
}

.background--public:after {
  content: "";
  @apply block absolute pointer-events-none z-0;
  top: -60px;
  left: 200px;
  height: calc(100vh + 120px);
  width: 324px;
  background-image: url(/assets/images/illustrations/login-new.svg);
  background-position: top 250px right;
  background-repeat: no-repeat;
  background-size: 324px;
}
@screen sm {
  .background--public:after {
    left: 380px;
    top: -120px;
    width: 500px;
    background-size: 500px;
  }
}
@screen md {
  .background--public:after {
    left: calc(50vw - 512px + 380px);
  }
}

.container--user {
  @apply w-[1100px] max-w-full px-16 mx-auto relative z-1 py-12;
}

.card--user {
  @apply sm:w-[450px] max-w-[450px] flex flex-col items-stretch;
}
.card--user .card--user__title {
  @apply font-landing text-41 sm:text-65 font-bold mb-16 sm:mb-12 sm:whitespace-nowrap sm:w-max text-site-main-one;
}
.card--user .card--user__title:first-line,
.card--user .card--user__title > div:first-line {
  @apply text-theme-black;
}