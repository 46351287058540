.admin-list {
  @apply max-w-full border border-site-theme-main-two overflow-hidden grid gap-4 items-center;

  .admin-list-element {
    @apply p-4 border-b border-site-theme-main-two grid grid-cols-subgrid items-center;

    &:last-child {
      @apply border-none;
    }

    > * {
      @apply truncate;
    }
  }
}

.admin-expandable-table {
  tr.detail-row {
    height: 0;
  }

  tr.expanding-row:not(.expanded-row):hover {
    background: whitesmoke;
  }

  tr.expanding-row:not(.expanded-row):active {
    background: #efefef;
  }

  .expanding-row td {
    border-bottom-width: 0;
  }

  .expanded-detail {
    overflow: hidden;

    app-writer-list {
      @apply block my-4;

      .mat-cell {
        @apply bg-site-theme-main-five;
      }
    }
  }
}
