.card-v2 {
  @apply shadow rounded-card w-full h-full flex flex-col;
}

.card--settings {
  @apply py-8 px-4 sm:px-8;

  .card__header {
    @apply text-19 font-bold mb-8 sm:mb-12;
  }

  // .row-form-nnki {
  //   @apply min-h-[40px] sm:min-h-[70px] items-center flex-nowrap py-4 px-8;

  //   &:nth-child(2n + 1) {
  //     @apply bg-theme-white;
  //   }

  //   &:nth-child(2n + 2) {
  //     @apply bg-site-theme-main-three;
  //   }

  //   .mat-slide-toggle {
  //     height: auto;
  //   }

  //   .mat-checkbox-label,
  //   .mat-slide-toggle-content {
  //     @apply font-avenir font-semibold whitespace-normal;
  //   }
  // }

  // .card-buttons {
  //   @apply mt-8 sm:mt-20;

  //   .btn-nnki {
  //     @apply sm:w-[370px] mx-auto;
  //   }
  // }

  // &.text-forms {
  //   .row-form-nnki {
  //     @apply min-h-0 bg-transparent p-0 flex-wrap;
  //   }

  //   .form-field-nnki.mat-form-field-appearance-legacy {
  //     @apply bg-theme-white sm:bg-site-theme-main-five;
  //   }
  // }
}

.card {
  @apply shadow;
  @apply rounded-card;
  margin-bottom: 20px;
  width: 100%;
}

.card-picto {
  border-radius: 50%;
  background: var(--theme-gray-three);
  width: 58px;
  height: 58px;
  margin-right: 16px;
  flex-shrink: 0;

  @media (max-width: $screen-xs-max) {
    margin-right: 13px;
  }

  > app-image-content img,
  > img,
  > .background-picto,
  > .dummy-rectangle {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  .background-picto {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--app-white);

    &.-color-black {
      background-image: linear-gradient(-90deg, var(--app-gray-one) 0%, var(--app-black) 100%);
    }

    &.-color-purple {
      background-image: linear-gradient(
        -90deg,
        var(--site-section-2-gradient-1) 0%,
        var(--site-section-2-gradient-2) 100%
      );
    }

    &.-color-green {
      background-image: linear-gradient(
        -90deg,
        var(--site-section-gradient-2) 0%,
        var(--site-section-gradient-1) 100%
      );
    }

    &.-color-yellow {
      background-color: var(--app-yellow-one);
    }

    &.-color-blue {
      background-image: linear-gradient(
        241.62deg,
        var(--site-card-gradient-2) 0%,
        var(--site-card-gradient-1) 100%
      );
    }
  }
}

.card-footer {
  background: var(--theme-white);
  border-top: 1px solid var(--theme-gray-eleven);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @apply rounded-b-card;
}

.card-circular-buttons {
  display: flex;
  align-items: center;

  .btn-nnki-circular,
  app-loader-dots {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.card-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  height: 42px;
}

.card--progress {
  padding: 20px 30px 30px 30px;
  flex-grow: 1;
  background: var(--theme-white);

  display: flex;
  flex-direction: column;

  margin-bottom: 0;

  @media (max-width: $screen-xs-max) {
    padding: 15px;
  }

  .card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    font-size: 2rem;
    font-weight: 800;

    @media (max-width: $screen-md-max) {
      font-size: 1.8rem;
    }

    svg-icon {
      width: 40px;
      margin-right: 15px;
      color: var(--theme-gray-sixteen);

      @media (max-width: $screen-md-max) {
        margin-right: 10px;

        svg {
          width: 25px;
        }
      }
    }

    .nb_questions {
      margin-left: auto;
      padding-left: 10px;
      font-size: 1.6rem;
      font-weight: 600;
      white-space: nowrap;

      @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        font-size: 1.4rem;
      }

      @media (max-width: $screen-xs-max) {
        font-size: 1.4rem;
      }

      strong {
        font-size: 2rem;
        font-weight: 800;

        @media (min-width: $screen-md) and (max-width: $screen-md-max) {
          font-size: 1.8rem;
        }

        @media (max-width: $screen-xs-max) {
          font-size: 1.6rem;
        }
      }
    }
  }

  .card-content {
    margin-top: 20px;
    flex-grow: 1;

    &.-grey {
      background: var(--site-theme-main-five);
      padding: 20px;

      @media (max-width: $screen-md-max) {
        padding: 20px 10px;
      }
    }

    &.-multiGraph {
      display: flex;
      align-items: center;

      @media (max-width: $screen-xs-max) {
        flex-wrap: wrap;
      }

      .graph_legend {
        width: 30%;
        flex-shrink: 0;
        padding-left: 60px;

        @media (max-width: $screen-sm-max) {
          padding-left: 30px;
        }

        @media (max-width: $screen-xs-max) {
          width: 100%;
          order: 2;

          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;

          padding: 0;
          margin-top: 20px;
        }

        .legend {
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          @media (max-width: $screen-xs-max) {
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
          }

          .color {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 25px;
            flex-shrink: 0;

            &.-blue {
              background: var(--app-blue-seven);
            }

            &.-green {
              background: var(--app-green-three);
            }

            &.-orange {
              background: var(--app-orange-two);
            }
          }

          .color_label {
            font-weight: 800;
            font-size: 1.6rem;
          }
        }
      }

      .graph-inner {
        width: 100%;
        min-width: 0;
      }
    }
  }

  .with-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    &.-small-margin {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .progress-label {
      font-size: 1.6rem;
      font-weight: 600;
      width: 120px;
      flex-shrink: 0;
      text-align: right;

      @media (max-width: $screen-xs-max) {
        width: 105px;
        font-size: 1.4rem;
      }

      strong {
        font-weight: 800;
      }

      svg-icon {
        color: var(--site-main-one);
        margin-left: 5px;
      }
    }

    .progress-value {
      font-size: 1.6rem;
      font-weight: 800;
      flex-shrink: 0;
      width: 70px;

      &.-small {
        font-size: 1.4rem;
        font-weight: 600;

        strong {
          font-size: 1.6rem;
          font-weight: bold;
        }
      }
    }

    .ecni_progress_bar {
      flex-grow: 1;
      margin: 0 20px;

      @media (max-width: $screen-md-max) {
        width: 35%;
        margin: 0 10px;
      }
    }
  }

  .graph_questions {
    .questions_stats {
      flex-grow: 1;

      display: grid;
      grid-template-columns: fit-content(200px) 30px auto;
      grid-column-gap: 20px;
      grid-row-gap: 15px;
      align-items: center;
      font-size: 1.4rem;
      font-weight: bold;

      .stat_label {
        @media (max-width: $screen-xs-max) {
          font-size: 1.2rem;
        }
      }

      .stat_color {
        width: 30px;
        height: 6px;
        border-radius: 3px;

        &.-green {
          background: var(--app-green-three);
        }
        &.-yellow {
          background: var(--app-orange-two);
        }
        &.-red {
          background: var(--app-red-one);
        }
      }

      .stat_value {
        color: var(--site-main-one);
      }
    }
  }

  .card-footer {
    border: 0;
    padding: 0;
    margin-top: 20px;
    background: transparent;
  }
}

.card--picto-corner {
  .card-header {
    display: flex;
    align-items: flex-start;
    height: 113px;
    position: relative;
    @apply z-1;
    cursor: pointer;
    background-color: var(--site-theme-main-five);
    padding: 20px;
    color: var(--theme-black);
    @apply rounded-t-card;

    @media (max-width: $screen-xs-max) {
      padding: 15px 10px;
      height: 103px;
    }

    &.ecos {
      height: 130px;
      @media (max-width: $screen-xs-max) {
        height: 130px;
      }
    }
  }

  .card-header-content {
    flex-grow: 1;
    position: relative;
    flex-basis: 0;
    overflow: hidden;
  }

  .card-title {
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--site-main-one);
    margin-bottom: 5px;

    &.-with-status {
      padding-right: 120px;
    }

    p {
      @apply truncate;
    }
  }

  .card-theme {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--theme-black);

    @media (max-width: $screen-xs-max) {
      font-size: 1.5rem;
    }

    p {
      @apply line-clamp-2;
    }
  }

  .card-ecos-theme {
    font-size: 1.4rem;
    color: var(--theme-black);
    font-weight: 300;
    margin-top: 5px;

    p {
      @apply line-clamp-2;
    }
  }

  .card-number {
    display: flex;
    align-items: center;

    font-size: 1.4rem;
    font-weight: bold;

    margin-top: 5px;

    > svg-icon {
      color: var(--site-main-one);
      margin-right: 5px;
    }
  }

  .card-status {
    position: absolute;
    right: 20px;
    top: 16px;
    background: var(--theme-white);
    padding: 5px 10px;
    border-radius: 2px;

    display: flex;
    align-items: center;
    @apply leading-none;

    @media (max-width: $screen-xs-max) {
      top: 12px;
      right: 10px;
    }

    .status-txt {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 800;
      color: var(--theme-black);
    }

    .status-badge {
      margin-left: 5px;
      flex-shrink: 0;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      &.badge-orange {
        background: var(--app-orange-three);
      }
      &.badge-red {
        background: var(--app-red-one);
      }
    }
  }

  .card-infos {
    position: absolute;
    bottom: -12px;
    left: 10px;

    display: flex;

    .card-info {
      display: flex;
      align-items: center;
      background: var(--theme-white);
      padding: 5px 8px;
      border-radius: 3px;

      margin-right: 7px;

      @apply whitespace-nowrap truncate;

      &:last-child {
        margin-right: 0;
      }

      .info-icon {
        margin-right: 4px;
        color: var(--site-main-one);
      }

      .info-txt {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--theme-black);
      }
    }
  }

  .card-content {
    background: var(--theme-white);
    padding: 30px 20px 20px 20px;
    flex-grow: 1;

    @media (max-width: $screen-xs-max) {
      padding: 10px 20px;
    }

    &.-inSwiper {
      @media (min-width: $screen-sm) {
        height: 94px;
      }
    }

    &.-training {
      @apply rounded-b-card;

      @media (max-width: $screen-xs-max) {
        padding-top: 20px;
      }
    }
  }

  .card-details {
    display: flex;
    align-items: center;
    overflow: hidden;

    .card-detail {
      margin-right: 20px;

      display: flex;
      align-items: center;
      @apply truncate;

      > svg-icon {
        color: var(--site-main-one);
        margin-right: 4px;
      }

      .detail-txt {
        color: var(--theme-black);
        font-size: 1.2rem;
        font-weight: bold;
        @apply truncate;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .training-card-xs-label {
    margin-bottom: 10px;
  }

  .training-frame {
    display: flex;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      flex-grow: 1;
    }
  }

  .card-training-duration {
    svg-icon {
      margin-right: 10px;
      color: var(--site-main-one);
    }
  }

  .card-footer {
    height: 50px;
    padding-top: 0;
    padding-bottom: 0;
    @media (max-width: $screen-xs-max) {
      border-top: 0;
    }
  }
}

.card--nnki-question {
  border: 0;

  > *:first-child {
    @apply rounded-t-card;
  }

  > *:last-child {
    @apply rounded-b-card;
  }

  &.-footerFixed {
    @media (max-width: $screen-xs-max) {
      margin-bottom: 80px;
    }

    .card-footer {
      @media (max-width: $screen-xs-max) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        @apply z-buttonsFixed;
        padding: 10px 10px calc(var(--safe-area-inset-bottom) + 10px) 10px;
      }

      .card-buttons {
        justify-content: space-between;

        @media (max-width: $screen-xs-max) {
          flex-wrap: nowrap;
          .btn-nnki,
          .spinner_button_container {
            flex-grow: 1;
            margin-bottom: 0;
          }
        }

        @media (max-width: 400px) {
          svg-icon {
            display: none;
          }
        }
      }
    }
  }

  &.-time-lapsed {
    .question-images {
      @extend .nnki-no-select;
      opacity: 0.5;
    }

    textarea {
      opacity: 0.5;
    }
  }

  .card-header {
    padding-left: 20px;
    padding-right: 20px;
    background: var(--theme-white);
    border-bottom: 1px solid var(--app-blue-six);

    @apply rounded-t-card;

    .card-header-row {
      min-height: 56px;
      @apply flex items-center justify-items-start;
    }

    .question-title {
      text-transform: capitalize;
      font-size: 1.5rem;
      font-weight: 800;
    }

    .question-type {
      color: var(--app-gray-six);
    }

    .question-users {
      display: flex;

      svg-icon {
        color: var(--site-main-one);
        margin-right: 5px;
      }

      .question-users-element {
        white-space: nowrap;
        font-size: 1.4rem;
        font-weight: 800;
        margin-right: 10px;

        display: flex;
        align-items: center;

        background-color: var(--site-theme-main-five);
        padding: 7px 10px;
        border-radius: 3px;

        &.-big {
          font-size: 1.6rem;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .card-folder-correction {
    display: flex;
    align-items: flex-start;
    background: var(--theme-white);
    padding: 20px;

    @media (max-width: $screen-xs-max) {
      flex-wrap: wrap;
      padding: 10px 0;
    }

    .icon-folder {
      margin-right: 20px;
      color: var(--site-main-one);
      flex-shrink: 0;

      @media (max-width: $screen-xs-max) {
        padding-left: 10px;
      }
    }

    .folder-details {
      overflow: hidden;
      margin-right: 20px;

      @media (max-width: $screen-xs-max) {
        width: calc(100% - 54px);
        margin-right: 0;
        overflow: visible;
      }

      .last-update {
        color: var(--site-main-one);
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .folder-title {
        font-size: 1.6rem;
        font-weight: 800;
        margin-bottom: 10px;
        @apply truncate;
        padding-bottom: 1px;

        @media (max-width: $screen-xs-max) {
          white-space: normal;
          padding-right: 10px;
        }
      }

      .folder-infos {
        background: var(--site-theme-main-five);
        display: flex;
        align-items: center;
        border-radius: 2px;
        padding: 5px 10px;

        @media (max-width: $screen-xs-max) {
          margin-left: -54px;
          margin-top: 10px;
          margin-bottom: 10px;
          width: calc(100% + 54px);
        }

        .folder-info {
          display: flex;
          align-items: center;

          svg-icon {
            margin-right: 10px;
            color: var(--site-main-one);
          }

          .info-txt {
            font-size: 1.6rem;
            font-weight: 800;

            &.-small {
              font-size: 1.4rem;
            }
          }
        }

        .header-info-separator {
          background: var(--theme-black);
          opacity: 0.26;
          height: 17px;
          width: 1px;
          margin: 0 15px;
        }
      }
    }

    .folder-grade {
      flex-shrink: 0;
      margin-left: auto;
      background: var(--site-theme-main-five);
      padding: 10px 20px;
      border-radius: 3px;

      @media (max-width: $screen-xs-max) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .rate-title {
        color: var(--site-main-one);
        font-size: 1.2rem;
        font-weight: 600;
        margin-bottom: 15px;

        @media (max-width: $screen-xs-max) {
          margin-bottom: 0;
        }
      }
    }
  }

  .card-content {
    background-color: var(--site-theme-main-five);
    padding: 20px;
    position: relative;

    &.-white {
      background: var(--theme-white);
    }

    &.-correction {
      @extend .-white;

      .content-question .question-description {
        font-weight: 500;
      }
    }

    .card-content-inside {
      display: flex;
      align-items: flex-start;

      @media (max-width: $screen-xs-max) {
        flex-wrap: wrap;
      }
    }

    .card-content-title {
      font-size: 1.6rem;
      font-weight: 800;
      margin-bottom: 20px;
    }

    .card-content-line {
      @apply truncate;
      display: block;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      strong {
        color: var(--site-main-one);
      }
    }
  }

  .content-question {
    flex-grow: 1;

    @media (max-width: $screen-xs-max) {
      order: 2;
    }

    .question-description {
      font-family: Avenir;
      font-size: 1.5rem;
      font-weight: 600;
      word-break: break-word;
      @apply first-letter:uppercase;

      p {
        margin-bottom: 10px;
      }

      a {
        color: var(--site-main-one);
        text-decoration: underline;
        pointer-events: all;
        word-break: break-all;
      }

      ul {
        margin-left: 30px;

        li {
          margin: 5px 0;
          position: relative;

          &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: var(--app-green-three);

            position: absolute;
            top: 5px;
            left: -12px;
          }
        }
      }

      table {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid var(--theme-gray-three);
        border-right: 0;
        border-left: 0;
        > thead,
        > tbody,
        > tfoot {
          > tr {
            > th,
            > td {
              border: 1px solid var(--theme-gray-three);
              min-width: 40px;
              &:first-child {
                border-left: 0;
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
        }
        > thead > tr {
          > th,
          > td {
            border-bottom-width: 2px;
          }
        }
        > tbody > tr:nth-of-type(even) {
          background-color: var(--site-theme-main-three);
        }
      }
    }

    iframe {
      width: 100%;
    }
  }

  .card-buttons {
    justify-content: space-between;

    .btn-nnki,
    .spinner_button_container {
      margin: 0 20px 0 0;
      flex-grow: 1;
      max-width: 300px;

      @media (max-width: $screen-xs-max) {
        max-width: none;
        margin-right: 10px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.card--info {
  @apply shadow rounded-card;
  background-color: var(--theme-white);
  overflow: hidden;
  margin-bottom: 20px;

  @media (max-width: $screen-xs-max) {
    margin-bottom: 0;
    box-shadow: none;
  }

  .card-header {
    padding: 0 20px;
    height: 54px;
    background-color: var(--site-theme-main-five);

    display: flex;
    align-items: center;

    color: var(--site-main-one);

    > svg-icon {
      margin-right: 10px;
    }

    .card-title {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  .card-content {
    padding: 20px;

    .card-row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 1.4rem;
      white-space: nowrap;

      .label {
        font-weight: bold;
        margin-right: 15px;
      }

      svg-icon {
        margin-right: 10px;
        color: var(--site-main-one);
      }

      .value {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }

    .scores {
      display: flex;
      flex-wrap: wrap;

      .score {
        width: 50%;
        text-align: center;
        margin-bottom: 20px;

        @media (max-width: $screen-xs-max) {
          width: 25%;
        }

        strong {
          line-height: 110%;
        }
      }
    }

    .score {
      font-weight: 800;
      font-size: 1.4rem;

      strong {
        font-size: 2.6rem;
      }

      small {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
  }
}

.card--login {
  @apply sm:w-[450px] max-w-[450px] flex flex-col items-stretch;

  .card--login__title {
    @apply font-landing text-41 sm:text-65 font-bold mb-16 sm:mb-12 sm:whitespace-nowrap sm:w-max text-site-main-one;

    &:first-line,
    > div:first-line {
      @apply text-theme-black;
    }
  }

  .card--login__footer {
    @apply mt-20 text-13 font-bold;
  }

  .card--login__link {
    @apply font-bold text-13 cursor-pointer text-site-main-one;
  }

  .card--login__buttons {
    @apply max-w-[210px] sm:max-w-none;
  }

  .card--login__stepper {
    @apply w-full mt-4 sm:mt-8 flex items-center text-theme-black;

    .ecni_progress_bar {
      @apply w-auto grow;
    }

    .card--login__stepper-label {
      @apply whitespace-nowrap ml-6 text-20 font-extrabold;
    }
  }
}

.question-description {
  font-family: Avenir;
  font-size: 1.5rem;
  font-weight: 600;

  p {
    margin-bottom: 10px;
  }

  a {
    color: var(--site-main-one);
    text-decoration: underline;
    pointer-events: all;
    word-break: break-all;
  }

  ul {
    margin-left: 30px;

    li {
      margin: 5px 0;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: var(--app-green-three);

        position: absolute;
        top: 5px;
        left: -12px;
      }
    }
  }

  table {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid var(--theme-gray-three);
    border-right: 0;
    border-left: 0;
    > thead,
    > tbody,
    > tfoot {
      > tr {
        > th,
        > td {
          border: 1px solid var(--theme-gray-three);
          min-width: 40px;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
        }
      }
    }
    > thead > tr {
      > th,
      > td {
        border-bottom-width: 2px;
      }
    }
    > tbody > tr:nth-of-type(even) {
      background-color: var(--site-theme-main-three);
    }
  }
}
