.modal_content {
  @apply relative shadow text-left w-full max-w-full sm:pt-20 sm:w-[465px];

  &.-large {
    @apply sm:w-[740px];
  }

  &.-xlarge {
    @apply sm:w-[1100px];
  }
}

.mat-modal-closer {
  @apply cursor-pointer fixed sm:absolute top-safeTop sm:top-0 right-0 p-4 z-2 text-white;

  &.-xs-black {
    @apply text-theme-black sm:text-white;
  }
}

.modal-inside {
  @apply sm:rounded overflow-hidden min-h-screen sm:min-h-0 flex sm:block flex-col justify-start bg-theme-white sm:bg-transparent w-screen sm:w-auto pb-safeBottom sm:pb-0;

  > * {
    @apply w-full sm:w-auto;
  }

  &:before {
    content: '';
    @apply block h-safeTop sm:hidden;
  }

  &.-image {
    @apply bg-transparent justify-center;
  }

  &.-video {
    @apply bg-theme-white sm:p-10 w-screen max-w-[1000px] sm:w-[calc(100vw-40px)];
  }

  &.-candidate-challenge {
    @apply bg-theme-white transition-backgroundColor;

    > * {
      @apply flex flex-col min-h-screen sm:min-h-0;
    }
  }

  p {
    @apply mb-4;
    strong {
      @apply text-site-main-one;
    }
  }

  ul {
    @apply list-disc list-inside text-left text-15 space-y-4;

    li {
      a {
        @apply text-site-main-one;

        &:hover {
          color: inherit;
        }
      }
    }
  }
}

.modal-header {
  @apply rounded-t p-8 sm:px-16 bg-cover bg-no-repeat;
  background-image: url(/assets/images/backgrounds/circles/circles_blue.svg);

  .modal-header-title {
    @apply text-white text-30 font-semibold flex items-center;

    > svg-icon {
      @apply mr-4 sm:mr-10;
    }
  }
}

.modal-subtitle {
  @apply text-theme-black font-extrabold text-16 mb-8 px-8 sm:px-0;
}

.modal-grid-container {
  @apply grid-cols-[repeat(auto-fill,minmax(130px,1fr))] gap-x-4 gap-y-12;
}

.modal-circles {
  @apply h-[269px] flex items-center justify-center relative;

  &.-noImage {
    @apply h-[114px];
  }

  .background {
    @apply absolute w-[150%] top-0 bottom-0 rounded-b-full -left-1/4;

    background: linear-gradient(
      144.11deg,
      var(--site-card-gradient-1) 0%,
      var(--site-card-gradient-2) 100%
    );

    &.-purple {
      background: linear-gradient(
        135deg,
        var(--site-section-2-gradient-1) 0%,
        var(--site-section-2-gradient-2) 100%
      );
    }

    &.-red {
      background: linear-gradient(126.67deg, var(--app-red-three) 0%, var(--app-red-four) 100%);
      opacity: 0.5;
    }

    &.-green {
      background: linear-gradient(
        127.18deg,
        var(--site-section-gradient-1) 0%,
        var(--site-section-gradient-2) 100%
      );
    }

    &.-light-green {
      background: linear-gradient(
        127.18deg,
        var(--site-section-gradient-1) 0%,
        var(--site-section-gradient-2) 100%
      );
      @apply opacity-30;
    }
  }

  .circle-1 {
    @include circleDeco(-90px, auto, auto, -150px, 232px, 15px, 48%);
  }

  .circle-2 {
    @include circleDeco(-15px, -50px, auto, auto, 124px, 15px, 48%);
  }

  .circle-3 {
    @include circleDeco(auto, -10px, -100px, auto, 158px, 15px, 48%);
  }

  img {
    @apply relative max-h-[190px];
  }
}

.modal-text {
  @apply relative -mt-12 flex flex-col items-center p-8;
}

.modal-picto {
  @apply mb-4 border-[3px] border-theme-white shadow rounded-full overflow-hidden h-[64px] w-[64px];

  &.-picto-svg {
    background: linear-gradient(
      144.11deg,
      var(--site-card-gradient-1) 0%,
      var(--site-card-gradient-2) 100%
    );
    @apply text-white flex items-center justify-center;

    &.-purple {
      background: linear-gradient(
        135deg,
        var(--site-section-2-gradient-1) 0%,
        var(--site-section-2-gradient-2) 100%
      );
    }

    &.-red {
      background: linear-gradient(126.67deg, var(--app-red-three) 0%, var(--app-red-four) 100%);
    }

    &.-green {
      background: linear-gradient(
        127.18deg,
        var(--site-section-gradient-1) 0%,
        var(--site-section-gradient-2) 100%
      );
    }
  }
}

.modal-title {
  @apply text-site-main-one text-20 font-semibold mb-6 text-center;

  &.-red {
    @apply text-red-two;
  }
}

.modal-theme {
  @apply text-16 font-extrabold mb-4 text-center;
}

.modal-buttons {
  @apply fixed sm:static bottom-0 left-0 right-0 shadow sm:shadow-none z-2 px-4 sm:px-0 py-4 sm:py-8 w-full mt-6 bg-theme-white gap-4 flex;

  &:after {
    content: '';
    @apply block sm:hidden h-safeBottom;
  }

  > * {
    @apply mx-auto w-[300px] max-w-full;
  }
}
