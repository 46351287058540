.mat-tab-group {
  font-family: Muli;
}

.tabs-bar,
.mat-tab-group .mat-tab-header {
  padding: 0 20px;
  border-bottom: 1px solid var(--theme-gray-seven);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: $screen-xs-max) {
    padding: 0;
  }

  .mat-tab-nav-bar {
    border-bottom: 0;
    flex-basis: 0;
  }

  .mat-tab-list {
    max-width: 100%;

    .mat-tab-link {
      > span {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.tabs-bar.-no-border,
.mat-tab-group.-no-border .mat-tab-header {
  border-bottom: 0;
}

.tabs-bar.-fixed,
.mat-tab-group.-fixed .mat-tab-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: var(--theme-gray-twentyone);
  @apply z-tabs;
}

.tabs-bar.-fixed {
  @media (max-width: $screen-xs-max) {
    overflow: hidden;

    .mat-tab-header {
      flex-shrink: 1;

      .mat-tab-list {
        overflow: hidden;

        .mat-tab-link > span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.tabs-group-nnki,
.tabs-nnki {
  align-self: flex-end;
  flex-grow: 1;

  // MOBILE DEVICE DEPENDANT
  &.tabs-hidden-on-desktop {
    @media (min-width: $screen-sm-max) {
      visibility: hidden !important;
    }
    .mat-ink-bar {
      @media (min-width: $screen-sm-max) {
        visibility: hidden !important;
      }
    }
  }

  .mat-tab-link,
  .mat-tab-label {
    font-family: Muli;
    height: 60px;
    padding-top: 5px;

    padding-left: 15px;
    padding-right: 15px;
    min-width: 160px;

    opacity: 1;
    text-transform: uppercase;
    color: var(--theme-gray-five);
    font-size: 1.3rem;
    font-weight: 800;

    overflow: visible;

    @media (max-width: $screen-md-max) {
      min-width: 130px;
    }

    @media (max-width: $screen-sm-max) {
      min-width: 0;
    }

    @media (max-width: $screen-xs-max) {
      height: 50px;
      padding-left: 8px;
      padding-right: 8px;
      font-size: 1.1rem;
    }

    @media (max-width: 359px) {
      font-size: 1rem;
    }

    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }

    &.mat-tab-label-active,
    :hover {
      color: var(--site-theme-accent);
    }

    .tab-with-image {
      display: flex;
      align-items: center;

      .logo_recomed {
        height: 30px;
        position: relative;
        top: -1px;

        @media (max-width: $screen-xs-max) {
          height: 19px;
        }
      }
    }
  }

  .mat-ink-bar {
    background: var(--site-theme-accent) !important;
  }
}

.container-route {
  overflow: hidden;
  display: flex;

  &.-withMobileButton {
    @media (max-width: $screen-xs-max) {
      padding-bottom: 110px;
    }

    .below-header {
      @media (max-width: $screen-xs-max) {
        padding-bottom: 0;
      }
    }
  }
}

.container-group {
  min-height: 100vh;
}

.content-component.-inSession {
  .tabs-bar.-fixed,
  .mat-tab-group.-fixed .mat-tab-header {
    padding-left: 120px;
    padding-right: 60px;

    padding-top: var(--safe-area-inset-top);

    @media (max-width: $screen-md-max) {
      padding-left: 80px;
      padding-right: 40px;
    }

    @media (max-width: $screen-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
    }

    @media (max-width: $screen-xs-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .tabs-group-nnki,
  .tabs-nnki {
    .mat-tab-link,
    .mat-tab-label {
      height: 63px;
    }
  }

  .tabs-buttons {
    @media (max-width: $screen-xs-max) {
      bottom: 20px;
    }
  }

  .container-group.-tabsFixed,
  .container-route.-tabsFixed {
    padding-top: 63px;
  }
}

.content-tab {
  display: block;
  flex-shrink: 0;
  width: 100%;
}

.tabs-back,
.mat-tab-group.-withBack .mat-tab-header .mat-tab-labels > :first-child {
  margin-right: 60px;
  min-width: 0;
  padding: 0;
  flex-shrink: 0;

  @media (max-width: $screen-md-max) {
    margin-right: 20px;
  }

  @media (max-width: $screen-xs-max) {
    margin-right: 10px;
    padding-left: 10px;
  }
}

.tabs-buttons {
  position: relative;

  margin-left: auto;
  display: flex;
  align-items: center;

  margin-top: 10px;
  margin-bottom: 10px;

  padding-left: 20px;

  font-size: 1.6rem;

  > .btn-nnki {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  @media (max-width: $screen-xs-max) {
    position: fixed;
    @apply z-tabs;
    bottom: calc(80px + var(--safe-area-inset-bottom));
    right: 20px;
  }
}

.mat-tab-header-pagination {
  display: none !important;
}

.tabs-inside-nnki {
  &.tabs-inside-bar,
  .mat-tab-header {
    padding: 0 50px;
    margin-bottom: 15px;
    border: 0;

    @media (max-width: $screen-xs-max) {
      padding: 0 20px;
    }
  }

  .mat-tab-label,
  .mat-tab-link {
    min-width: 0;
  }

  .mat-tab-label-content,
  .mat-tab-link {
    color: var(--theme-white);
    font-size: 1.3rem;
    font-weight: bold;

    svg-icon {
      margin-right: 10px;
    }

    &.mat-tab-label-active {
      opacity: 1;
    }
  }

  .mat-ink-bar {
    background: var(--theme-white) !important;
  }

  &.mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination {
    display: flex !important;
    box-shadow: none;

    .mat-tab-header-pagination-chevron {
      border-color: var(--theme-white);
    }
  }
}
