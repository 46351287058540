.card--log_session {
  background: var(--theme-white);
  margin-bottom: 0;
  overflow: hidden;
  @apply rounded;

  @apply shadow;
}

.log-table_container {
  width: 100%;
  overflow: auto;
}

.log-table_header {
  display: flex;
  align-items: center;
  height: 60px;

  background: var(--theme-white);

  @media (max-width: $screen-sm-max) {
    display: none;
  }

  > div {
    padding: 0 20px;

    color: var(--site-main-one);
    font-size: 1.3rem;
    font-weight: bold;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.-with-images {
    > div {
      display: flex;
      align-items: center;

      svg-icon:not(.icon-arrow-bottom) {
        margin-right: 10px;
        color: var(--theme-black);
      }

      span {
        line-height: 100%;
      }

      .icon-arrow-bottom {
        margin-left: 10px;
        color: var(--site-main-one);

        &.reversed {
          transform: rotate(180deg);
        }
      }
    }
  }
}

.mat-accordion.accordion-log {
  .mat-expansion-panel {
    border-radius: 0 !important;
    border: 0;
    box-shadow: none;

    &.-self {
      background: var(--app-yellow-two);
    }
  }

  &.-ranking {
    .mat-expansion-panel {
      &:nth-child(2n + 1) {
        background: var(--site-theme-main-three);
      }
    }
  }

  .mat-expansion-panel-spacing {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mat-expansion-panel-header {
    font-weight: bold;
    font-size: 1.3rem;

    padding: 0;
    height: 60px !important;

    background: transparent !important;

    @media (max-width: $screen-sm-max) {
      padding-left: 20px;
      padding-right: 20px;
    }

    &[aria-disabled='true'] {
      color: inherit;
      cursor: default;
    }

    .mat-content {
      align-items: center;
      height: 60px;
      > div,
      > a {
        padding: 0 20px;

        @media (max-width: $screen-xs-max) {
          padding: 0 5px;
        }
      }
    }
  }

  .accordion-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    font-size: 1.1rem;
    font-weight: bold;

    &.-lefted {
      justify-content: flex-start;
      .data-cell {
        flex-grow: 1;
        min-width: 50%;
        margin: 5px 0;
        padding: 0 20px;

        @media (max-width: $screen-xs-max) {
          padding: 0 5px;
        }
      }
    }

    .data-cell {
      display: flex;
      margin-left: 15px;
      margin-right: 15px;

      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }

      svg-icon {
        color: var(--theme-black);
        justify-content: flex-start;
      }

      > span {
        margin-left: 5px;
        margin-right: 5px;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .name {
      color: var(--theme-gray-five);
    }

    .value {
      color: var(--theme-black);
    }

    .btn-nnki {
      margin-top: 10px;
      width: 300px;
      max-width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  small {
    color: var(--theme-gray-five);
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.session_content {
  display: flex;
  align-items: center;

  .session_picto {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--theme-white);

    margin-right: 10px;

    &.picto_item {
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--app-yellow-one);
      font-size: 1.3rem;
      font-weight: 800;
      line-height: 100%;

      &.-random {
        background: var(--app-purple-one);
      }

      &.-folder {
        background: var(--site-main-one);
        img {
          width: 20px;
        }
      }
    }
  }

  .session_name {
    @apply line-clamp-2;
    padding-bottom: 1px;
  }
}

.cell_reussite {
  .ecni_progress_bar {
    width: 100%;
  }
}
