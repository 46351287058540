// nnkitodo [v2later css] faire plutot des components si possible

.writer-list-header {
  @apply grid grid-cols-1 content-start sm:grid-cols-[1fr_auto] gap-4 mb-8;
}

.writer-filters {
  @apply block mb-8;

  .mat-expansion-panel:not(.mat-expanded) {
    @apply border-0;
  }

  .mat-expansion-panel-body {
    @apply p-8;
  }
}

.writer-table-container {
  @apply w-full overflow-x-auto;

  .mat-table {
    @apply min-w-full border border-site-theme-main-two;

    .mat-header-cell {
      @apply bg-site-theme-main-five text-site-main-one font-bold;
    }

    .mat-header-cell,
    .mat-cell {
      @apply px-4 whitespace-nowrap max-w-[200px] truncate;
    }

    .mat-cell > a,
    .clickable-content {
      @apply cursor-pointer text-site-main-one hover:underline;
    }

    .mat-cell {
      app-button {
        @apply w-min block;
      }
    }

    .multiple-lines-content {
      @apply line-clamp-2 whitespace-normal cursor-pointer min-w-[180px];
    }
  }
}

.loading-mask {
  @apply absolute top-0 bottom-0 left-0 right-0 bg-theme-white opacity-50 flex items-center justify-center z-above;
}
